import { amgApi } from "@/service/axios";
class settlements {
  async searchOfferByStatus(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-offers-by-status",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchOfferByStatus");
    }
  }
  async getAdvisors(id) {
    try {
      return await amgApi.get(`/commons/get-users-by-module/${id}`);
    } catch (error) {
      console.log("Error settlementsmade: getAdvisorName");
    }
  }
  async getCreditorsByName(text) {
    try {
      return await amgApi.post(
        `/sales-made/debt-solution/search-creditors?q=${text}`
      );
    } catch (error) {
      console.log("Error PriorityOffersService: getCreditorsByName");
    }
  }
}
export default new settlements()