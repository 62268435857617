<template>
  <div>
    <GridTableToBeApproved v-if="getTab == 1" />
    <GridTableToPay v-if="getTab == 2 || getTab == 3 || getTab == 5" />
  </div>
</template>

<script>
import GridTableToBeApproved from "@/views/debt-solution/views/settlements/components/GridTableToBeApproved.vue";
import GridTableToPay from "@/views/debt-solution/views/settlements/components/GridTableToPay.vue";

export default {
  components: {
    GridTableToBeApproved,
    GridTableToPay,
  },
  data() {
    return {};
  },
  computed: {
    getTab() {
      return this.$route.meta.tab;
    },
  },
};
</script>

<style>
</style>