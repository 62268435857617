var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"CLIENTS READY TO SETTLE","modal-class":"modal-primary","title-tag":"h3","size":"caro","scrollable":"","hide-footer":""},on:{"hidden":_vm.close},model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refSettlementOffersTable'].refresh()},"reset-all-filters":_vm.resetAllFilters},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('span',{staticClass:"ml-3 text-info"},[_vm._v("* Accounts ready to settle")])]},proxy:true},{key:"table",fn:function(){return [_c('b-table',{ref:"refSettlementOffersTable",staticClass:"position-relative",attrs:{"no-border-collapse":"","fields":_vm.visibleFields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","responsive":"sm","items":_vm.getSettlementData,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [_c('router-link',{class:_vm.textLink,attrs:{"target":"_blank","to":{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              }}},[_c('span',{attrs:{"id":'client' + data.item.client_account_id}},[_vm._v(" "+_vm._s(data.item.client_name)+" "),_c('br')])]),_c('span',[_vm._v(_vm._s(data.item.client_account))]),_c('b-tooltip',{attrs:{"target":'client' + data.item.client_account_id,"triggers":"hover","placement":"left"}},[_c('span',[_vm._v(" SSN: "+_vm._s(data.item.lead_ssn)+" "),_c('br'),_vm._v(" DOB: "+_vm._s(data.item.lead_dob)+" "),_c('br'),_vm._v(" ADDRESS: "+_vm._s(data.item.lead_address)+" ")])])]}},{key:"cell(available_balance)",fn:function(data){return [_c('span',{class:data.item.available_balance > 0
                  ? 'text-primary'
                  : 'text-danger'},[_vm._v("$ "+_vm._s(_vm._f("currency")(data.item.available_balance)))])]}},{key:"cell(total_debit)",fn:function(data){return [_c('span',[_vm._v("$ "+_vm._s(_vm._f("currency")(data.item.total_debit)))])]}},{key:"cell(balance_sheet)",fn:function(data){return [_c('span',{class:_vm.balanceSheet(data.item.percentage_debt_client)},[_vm._v(" "+_vm._s(data.item.percentage_debt_client ? parseFloat(data.item.percentage_debt_client).toFixed(2) : "0")+"%")])]}},{key:"cell(accounts)",fn:function(data){return [_c('div',{staticClass:"d-flex cursor-pointer",on:{"click":function($event){return _vm.openModalCreateSettlementDetails(data.item)}}},[_c('feather-icon',{attrs:{"icon":"ChevronsRightIcon","size":"20"}}),_c('h4',[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(data.item.accounts)+"* ")])],1)],1)]}},{key:"cell(payments)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center cursor-pointer",on:{"click":function($event){return _vm.openModalBalance(data.item)}}},[_c('tabler-icon',{staticClass:"text-success",attrs:{"icon":"CurrencyDollarIcon","size":"20"}})],1)]}},{key:"cell(files)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center cursor-pointer",on:{"click":function($event){return _vm.openModalFiles(data.item)}}},[_c('tabler-icon',{staticClass:"text-warning",attrs:{"icon":"FolderIcon","size":"20"}})],1)]}},{key:"custom-foot",fn:function(){return [_c('b-tr',[_c('b-td',[_c('span',{staticClass:"font-small-4"},[_vm._v("Total:")])]),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.totalAvailable,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.totalDebts,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.totalSheets,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1)],1)]},proxy:true}])})]},proxy:true}])}),_c('rejected-offers-modal',{attrs:{"info":_vm.settlementModalInfo,"show":_vm.showRejectedOffersModal},on:{"closing":function($event){_vm.showRejectedOffersModal = false}}}),(_vm.trackingPendingMadeDsListCreditModalOn)?_c('tracking-pending-made-ds-list-credit-modal',{attrs:{"ds-list-credit-id":_vm.dsListCreditForTracking},on:{"close":function($event){_vm.trackingPendingMadeDsListCreditModalOn = false}}}):_vm._e(),(_vm.addCreditorModalController)?_c('add-creditor-ds-modal',{attrs:{"id-creditor-to-pass":_vm.idCreditorToPass,"state-modal":_vm.stateModal},on:{"close":function($event){_vm.addCreditorModalController = false}}}):_vm._e(),(_vm.modalCreateSettlementDetailsOn)?_c('create-settlement-grid-modal-details',{attrs:{"client-information":_vm.clientInformation},on:{"close":_vm.closeModalCreateSettlementDetailsOn}}):_vm._e(),(_vm.modalBalanceOpened)?_c('modal-balance',{attrs:{"payment-information":_vm.paymentInformation},on:{"updateTotalPayment":_vm.handleTotalPaymentUpdate,"close":_vm.closeModalBalance}}):_vm._e(),(_vm.modalFilesOpened)?_c('modal-files',{attrs:{"data-file":_vm.currentClientData,"component-mode":2},on:{"close":_vm.closeModalFiles}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }