export default [
  {
    key: "client_name",
    label: "Client",
    visible: true,
  },
  {
    key: "creditor_name",
    label: "ACCOUNT",
    visible: true,
  },
  {
    key: "collection",
    label: "Actual Creditor",
    visible: true,
  },
  {
    key: "advisor_name",
    label: "Negotiator",
    visible: true,
    class: "text-center",
  },
  {
    key: "balance",
    label: "Balance",
    visible: true,
    class: "text-center",
  },
  {
    key: "client_balance",
    label: "AVAILABLE BALANCE",
    visible: true,
  },
  {
    key: "offer_amount",
    label: "Offer Amount",
    visible: true,
    class: "text-center",
  },
  {
    key: "offer_percent",
    label: "% OFFER",
    visible: true,
  },
  {
    key: "profit",
    label: "SETTLEMENT FEE",
    visible: true,
  },
  {
    key: "payment_date",
    label: "Payment Date",
    class: "text-center",
    visible: true,
    sortable: true,
  },
  {
    key: "created_at",
    label: "Offer Created",
    class: "text-center",
    visible: true,
  },
  {
    key: "result",
    label: "OFFER RESULT",
    class: "text-center",
    visible: true,
  },
  {
    key: "process",
    label: "PROCESS",
    class: "text-center",
    visible: true,
  },
  {
    key: "status",
    label: "STATUS",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "created_by",
    class: "CREATED BY",
    visible: true,
  },
  {
    key: "tracking",
    visible: true,
  },
  {
    key: "actions",
    label: "actions",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
