<template>
  <b-modal
    scrollable
    id="modal-xl"
    size="xmd"
    :title="currentComponentTitle"
    v-model="openModal"
    title-tag="h3"
    @hidden="hideModal(false)"
    no-close-on-backdrop
  >
    <div
      class="d-flex justify-content-between align-items-center"
      style="margin-left: 4rem; margin-right: 4rem; margin-top: 2rem"
    >
      <div style="width: 100%">
        <b-button
          variant="primary"
          @click="changeTextCollapse"
          v-b-toggle.collapse-2
          class="m-1"
          >{{ toggleCollapseText }}</b-button
        >
      </div>
      <div>
        <b-row style="flex-wrap: nowrap">
          <b-col xl="12" style="width:100px">
            <b-form-group
              label="SL File"
              label-size="sm"
              label-class="font-weight-bold pt-0"
                              class="mb-0 d-flex flex-nowrap text-center"
                style="align-items:center"
            >
              <a v-if="dataCheck.pdf" :href="dataCheck.pdf" target="_blank">
                <feather-icon
                  v-b-tooltip.hover.bottom
                  title="SL File"
                  icon="FileIcon"
                  size="22"
                  class="align-middle text-primary cursor-pointer scale"
                  style="margin-left: 25px"
                />
              </a>
              <feather-icon
                v-else
                v-b-tooltip.hover.bottom
                title="Not SL File"
                icon="FileIcon"
                size="22"
                class="align-middle text-body"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-collapse id="collapse-2">
      <more-data :clientCreditorCheck="dataCheck" />
    </b-collapse>
    <component
      :is="currentComponent"
      :checkData="checkData"
      :settlementData="settlementData"
      :clientCreditorCheck="clientCreditorCheck"
      :checkGeneratedStatus="checkGeneratedStatus"
      :offerData="offerData"
      @paymentOrderGeneratedWithCheck="virtualPaymentGeneratedWithCheck"
      @physicalPaymentGeneratedWithCheck="physicalPaymentGeneratedWithCheck"
      @closeModal="hideModal"
      @reload="indicators"
    />
    <div class="d-flex justify-content-center mb-1" v-if="isLoading">
      <span> <b-spinner small class="mt-2 mr-1"></b-spinner>Loading... </span>
    </div>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>

<script>
import checkPreview from "@/views/debt-solution/views/settlements-made/components/subcomponents/GenerateChecks.vue";
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import OfferPaymentResponse from "@/views/debt-solution/views/settlements-made/components/subcomponents/OfferPaymentResponse.vue";
import MoreData from "@/views/debt-solution/views/settlements-made/components/subcomponents/MoreData.vue";
import CashierCheckMoneyOrderPayment from "@/views/debt-solution/views/settlements-made/components/subcomponents/CashierCheckMoneyOrderPayment.vue";

export default {
  props: {
    activeModal: {
      type: Boolean,
      default: false,
    },
    offerData: {
      type: Object,
    },
    dataCheck: {
      type: Object,
    },
    settlementData: {
      type: Object
    }
  },
  components: {
    checkPreview,
    OfferPaymentResponse,
    MoreData,
    CashierCheckMoneyOrderPayment,
  },
  data() {
    return {
      checkData: {},
      checkGeneratedStatus: false,
      currentProcessState: 0,
      isLoading: true,
      openModal: false,
      clientCreditorCheck: {
        client_name: null,
        creditor_name: null,
      },
      refresh: true,
      toggleCollapseText: null,
    };
  },
  async created() {
    this.openModal = this.activeModal;
    await this.addPreloader();
    await this.getBankAccountAvailableBalance();
    this.isLoading = false;
    await this.removePreloader();
    this.toggleCollapseText = "Show Data";
  },
  methods: {
    async getBankAccountAvailableBalance() {
      try {
        // Check if exists an account with enough money to pay the current order
        const data =
          await SettlementsMadeService.getBankAccountAvailableBalance(
            this.offerData
          );
        const existsAccountWithBalance = data.exists_account_with_balance === 1;
        const payment_method = this.offerData.payment_method;
        const bankAccountCheck = [
          "E-CHECK (CHECKING ACCOUNT)",
          // "DEBIT/CREDIT CARD",
          "CHECK (OVERNIGHT)",
        ];
        const otherType = ["CASHIER CHECK", "MONEY ORDER"];
        switch (true) {
          case existsAccountWithBalance && otherType.includes(payment_method):
            this.checkData = {
              ...data,
              payment_order_amount: this.offerData.payment_amount,
            };
            this.currentProcessState = 4;
            break;
          case existsAccountWithBalance &&
            bankAccountCheck.includes(payment_method):
            this.checkData = {
              ...data,
              payment_order_amount: this.offerData.payment_amount,
            };
            this.currentProcessState = 1;
            break;
          case !existsAccountWithBalance && otherType.includes(payment_method):
            await SettlementsMadeService.generateOfferPaymentOrder(
              this.offerData
            );
            this.currentProcessState = 2;
            this.showWarningSwal(
              "Cashier Check/Money Order not generated",
              "The Cashier Check/Money Order could not be generated due to insufficient funds."
            );
            this.hideModal(true);
            break;
          case !existsAccountWithBalance &&
            bankAccountCheck.includes(payment_method):
            await SettlementsMadeService.generateOfferPaymentOrder(
              this.offerData
            );
            this.currentProcessState = 2;
            this.checkGeneratedStatus = false;
            this.clientCreditorCheck = {
              client_name: this.offerData.client_name,
              creditor_name: this.offerData.creditor_name,
            };
            this.showWarningSwal(
              "Check not generated",
              "The check could not be generated due to insufficient funds."
            );
            this.hideModal(true);
            this.$emit("reload");
            break;
          default:
            break;
        }
      } catch (err) {
        console.error(err);
      }
    },
    virtualPaymentGeneratedWithCheck({ checkWasCreated, check_number }) {
      this.currentProcessState = checkWasCreated ? 3 : 2;
      this.checkGeneratedStatus = checkWasCreated;
      this.clientCreditorCheck = {
        client_name: this.offerData.client_name,
        creditor_name: this.offerData.creditor_name,
        check_number: check_number,
      };
      this.hideModal(true);
      this.$emit("reload");
    },
    physicalPaymentGeneratedWithCheck() {
      this.hideModal(true);
      this.$emit("reload");
    },
    hideModal(refresh) {
      this.openModal = false;
      this.$emit("close", refresh);
    },
    changeTextCollapse() {
      if (this.toggleCollapseText == "Show Data") {
        this.toggleCollapseText = "Hide Data";
      } else {
        this.toggleCollapseText = "Show Data";
      }
    },
    indicators() {
      this.$emit("reload");
    },
  },
  computed: {
    currentComponent() {
      const currentProcessState = this.currentProcessState;
      switch (currentProcessState) {
        case 1:
          return "checkPreview";
        case 2:
          return "OfferPaymentResponse";
        case 3:
          return "OfferPaymentResponse";
        case 4:
          return "CashierCheckMoneyOrderPayment";
        default:
      }
    },
    currentComponentTitle() {
      const currentProcessState = this.currentProcessState;
      switch (currentProcessState) {
        case 0:
          return "CHECKING AVAILABLE BALANCE IN ACCOUNTS";
        case 1:
          return (
            "GENERATE CHECK TO" +
            " " +
            this.dataCheck.client_name.toUpperCase() +
            " " +
            "FROM CREDITOR" +
            " " +
            this.dataCheck.creditor_name.toUpperCase()
          );
        case 2:
          return "UNAVAILABLE BALANCE IN BANK ACCOUNT";
        case 3:
          return "PAYMENT ORDER CONFIRMATION";
        case 4:
          return "VIEW CHECK/MONEY ORDER/CASHIER CHECK";
        default:
      }
    },
  },
};
</script>
