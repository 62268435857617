export default {
    methods: {
        async forceFileDownloadPdfWithDownload(response, fileName) {
            const url = window.URL.createObjectURL(
                new Blob([response], {
                    type: "application/pdf",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.download=fileName
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
        },
    }
}