<template>
  <b-modal
    v-model="isShow"
    ref="modal-view-documents-deposit"
    title-tag="h3"
    title="CHECK MANUAL"
    size="xmd"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
    scrollable
  >
  <div class="modal-vue-file-deposit">
    <b-row> 
      <b-col sm="6">
        <h5>Check Number</h5>
        <p
          class="rounded text-primary border-primary font-medium-1 text-center py-1s"
        >
          {{ operationNumber }}
        </p>
      </b-col>
      <b-col sm="6">
        <h5>Operation Date</h5>
        <p
          class="rounded text-primary border-primary font-medium-1 text-center py-1s"
        >
          {{operationDate | myGlobal }}
        </p>
      </b-col>
    </b-row>
    <b-row
      class="mt-2 ci-container d-flex justify-content-center"
      v-if="files.length > 0"
    >
      <b-col
        sm="6"
        md="4"
        lg="3"
        class="mb-2"
        v-for="(item, index) in files"
        :key="index"
      >
        <b-card
          body-class="p-0 mx-1 my-1 d-flex flex-column justify-content-between"
          class="m-0 h-100"
        >
          <div v-show="item.status === 1" class="img-preview">
            <v-pdf
              v-if="item.extension == 'pdf'"
              :src="item.route"
              @progress="item.status = $event"
            />
            <img
              v-else
              :src="returnImage(item.extension, item.route)"
              class="py-2 amgIcon"
              :alt="item.extension"
            />
          </div>

          <b-skeleton-img v-if="item.status != 1"></b-skeleton-img>
          <div>
            <div class="document-title text-center pt-1 text-truncate">
              {{ item.name }}
            </div>
            <div class="d-flex justify-content-around">
              <div
                class="container-icon text-primary"
                @click="openPreview(item.route, item.name)"
                v-if="item.extension == 'pdf'"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="icon-badge text-primary"
                ></feather-icon>
              </div>
              <div class="container-icon">
                <b-link
                  size="sm"
                  :href="item.route"
                  target="_blank"
                  :disabled="!item.route"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="icon-badge"
                  ></feather-icon>
                </b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div v-else class="text-center mb-2 no-files-timeline">
      No matching files found
    </div>
    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-url="currentPdf"
      :pdf-name="pdfName"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </div> 
  </b-modal>
</template>
<script>
import VPdf from "vue-pdf"; 
import SettlementServices from "@/views/debt-solution/views/settlements/services/settlement.service.js";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
export default {
  components: {
    VPdf,
    PreviewPdf,
  },
  props: {
    dsOfferPaymentOrdersId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShow:false,
      files: [],
      currentPdf: null,
      pdfName: null,
      showPreviewPdf: false,
      operationNumber:null,
      operationDate:null,
    };
  },

  async created() { 
    this.isShow = true;
    this.getCheckManual();
  },
  methods: {
    async getCheckManual() { 
      const {data} = await SettlementServices.getCheckManual({
        id: this.dsOfferPaymentOrdersId,
      }); 
      this.files = data.data;
      this.operationNumber=data.data[0].operation_number;
      this.operationDate=data.data[0].operation_date;
    },
    openPreview(url, name) {
      this.currentPdf = url;
      this.pdfName = name;
      this.showPreviewPdf = true;
    },
    returnImage(item, url) {
      switch (item) {
        case "jpg":
        case "png":
        case "gif":
        case "jpeg":
        case "webp":
          return url;
        case "xls":
        case "xlsx":
          return "/assets/images/drag-drop/ext_files/excel.png";
        case "html":
          return "html";
        case "pdf":
          return "/assets/images/drag-drop/ext_files/pdf.png";
        case "docx":
        case "doc":
          return "/assets/images/drag-drop/ext_files/doc2.webp";
        default:
          return "/assets/images/drag-drop/doc2.png";
      }
    },
    close(){
        this.$emit("close");
    }
  },
};
</script>
<style lang="scss">
.modal-vue-file-deposit {
  .img-preview,
  canvas {
    height: 100%;
    width: 100%;
    max-height: 260px;
    padding: 2px 8px;
  }
  .amgIcon {
    display: inline-block;
    fill: #ff9f43;
    transform-origin: 0px 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
    .document-title {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .container-icon {
      margin-top: 5px;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      .icon-badge {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: rgba(219, 217, 217, 0.7);
      }
    }
  }
}
.dark-layout .modal-vue-file-deposit .card {
  background-color: #1d1d20 !important;
  .container-icon {
    background-color: #1d1d20 !important;
    &:hover {
      background-color: #2e2e33 !important;
    }
  }
}

.py-1s {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>