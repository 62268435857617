<template>
  <b-modal
    v-model="onControl"
    title="CLIENTS READY TO SETTLE"
    modal-class="modal-primary"
    title-tag="h3"
    size="caro"
    scrollable
    hide-footer
    @hidden="close"
  >
    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refSettlementOffersTable'].refresh()"
        @reset-all-filters="resetAllFilters"
      >
        <template #buttons>
          <span class="ml-3 text-info">* Accounts ready to settle</span>
        </template>
        <template #table>
          <b-table
            ref="refSettlementOffersTable"
            no-border-collapse
            class="position-relative"
            :fields="visibleFields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="getSettlementData"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client_name)="data">
              <router-link
                target="_blank"
                :class="textLink"
                :to="{
                  name: 'debt-solution-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                ><span :id="'client' + data.item.client_account_id">
                  {{ data.item.client_name }}
                  <br /> </span
              ></router-link>
              <span>{{ data.item.client_account }}</span>
              <b-tooltip
                :target="'client' + data.item.client_account_id"
                triggers="hover"
                placement="left"
              >
                <span>
                  SSN: {{ data.item.lead_ssn }}
                  <br />
                  DOB: {{ data.item.lead_dob }}
                  <br />
                  ADDRESS: {{ data.item.lead_address }}
                </span>
              </b-tooltip>
            </template>

            <template #cell(available_balance)="data">
              <span
                :class="
                  data.item.available_balance > 0
                    ? 'text-primary'
                    : 'text-danger'
                "
                >$ {{ data.item.available_balance | currency }}</span
              >
            </template>
            <template #cell(total_debit)="data">
              <span>$ {{ data.item.total_debit | currency }}</span>
            </template>
            <template #cell(balance_sheet)="data">
              <span :class="balanceSheet(data.item.percentage_debt_client)">
                {{
                  data.item.percentage_debt_client
                    ? parseFloat(data.item.percentage_debt_client).toFixed(2)
                    : "0"
                }}%</span
              >
            </template>
            <template #cell(accounts)="data">
              <div
                class="d-flex cursor-pointer"
                @click="openModalCreateSettlementDetails(data.item)"
              >
                <feather-icon icon="ChevronsRightIcon" size="20" />
                <h4>
                  <b-badge variant="info"> {{ data.item.accounts }}* </b-badge>
                </h4>
              </div>
            </template>
            <template #cell(payments)="data">
              <div
                class="d-flex justify-content-center cursor-pointer"
                @click="openModalBalance(data.item)"
              >
                <tabler-icon
                  icon="CurrencyDollarIcon"
                  class="text-success"
                  size="20"
                />
              </div>
            </template>
            <template #cell(files)="data">
              <div
                class="d-flex justify-content-center cursor-pointer"
                @click="openModalFiles(data.item)"
              >
                <tabler-icon icon="FolderIcon" class="text-warning" size="20" />
              </div>
            </template>
            <template #custom-foot>
              <b-tr>
                <b-td>
                  <span class="font-small-4">Total:</span>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="totalAvailable"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="totalDebts"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="totalSheets"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
              </b-tr>
            </template>
          </b-table>
        </template>
      </filter-slot>

      <rejected-offers-modal
        :info="settlementModalInfo"
        :show="showRejectedOffersModal"
        @closing="showRejectedOffersModal = false"
      />
      <tracking-pending-made-ds-list-credit-modal
        v-if="trackingPendingMadeDsListCreditModalOn"
        :ds-list-credit-id="dsListCreditForTracking"
        @close="trackingPendingMadeDsListCreditModalOn = false"
      />
      <add-creditor-ds-modal
        v-if="addCreditorModalController"
        :id-creditor-to-pass="idCreditorToPass"
        :state-modal="stateModal"
        @close="addCreditorModalController = false"
      />
      <create-settlement-grid-modal-details
        v-if="modalCreateSettlementDetailsOn"
        :client-information="clientInformation"
        @close="closeModalCreateSettlementDetailsOn"
      />
      <modal-balance
        v-if="modalBalanceOpened"
        :payment-information="paymentInformation"
        @updateTotalPayment="handleTotalPaymentUpdate"
        @close="closeModalBalance"
      />
      <modal-files
        v-if="modalFilesOpened"
        :data-file="currentClientData"
        :component-mode="2"
        @close="closeModalFiles"
      />
    </div>
  </b-modal>
</template>

<script>
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import TrackingPendingMadeDsListCreditModal from "@/views/debt-solution/views/priority-offers/components/modal/TrackingPendingMadeDsListCreditModal";
import RejectedOffersModal from "@/views/debt-solution/views/settlement-offer/RejectedOffersModal.vue";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import { mapGetters, mapMutations } from "vuex";
import CreateSettlementService from "@/views/debt-solution/views/settlements/services/create-settlement.service";
import CreateSettlementsGridFilters from "@/views/debt-solution/views/settlements/components/create-settlements-grid.filters.js";
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";

import CreateSettlementGridModalDetails from "@/views/debt-solution/views/settlements/components/CreateSettlementGridModalDetails.vue";
import DashboardService from "@/views/business/views/clients/dashboard/services/dashboard.service.js";
import ModalBalance from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalBalance.vue";
import ModalFiles from "@/views/commons/components/clients/dashboard/options/files-module/Files.vue";

export default {
  name: "ContentCommissions",
  components: {
    SettlementOfferModal,
    RejectedOffersModal,
    TrackingPendingMadeDsListCreditModal,
    AddCreditorDsModal,
    CreateSettlementGridModalDetails,
    ModalBalance,
    ModalFiles,
  },
  data() {
    return {
      totalAvailable: 0,
      totalDebts: 0,
      totalSheets: 0,
      currentClientData: {},
      modalCreateSettlementDetailsOn: false,
      modalBalanceOpened: false,
      modalFilesOpened: false,
      onControl: true,
      fields: [
        {
          key: "client_name",
          sortable: false,
          label: "Client",
          visible: true,
        },
        {
          key: "available_balance",
          label: "Client Balance",
          sortable: false,
          visible: true,
        },

        {
          key: "total_debit",
          sortable: false,
          label: "Total Debts",
          visible: true,
        },
        {
          key: "balance_sheet",
          label: "Porcentage Balance",
          visible: true,
        },
        {
          key: "accounts",
          sortable: false,
          label: "Accounts",
          visible: true,
        },

        {
          key: "payments",
          sortable: false,
          label: "Payments",
          visible: true,
          class: "text-center",
        },
        {
          key: "files",
          sortable: false,
          label: "Files",
          visible: true,
          class: "text-center",
        },
        {
          key: "advisor_name",
          sortable: false,
          label: "Advisor",
          visible: true,
        },
      ],
      filters: CreateSettlementsGridFilters,
      itemsPriorityOffers: [],
      editCommissionContentOn: false,
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      paymentInformation: {},
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      commissionToEdit: {},
      typesTrackingModalOn: false,
      trackedCommissionId: null,

      showSettlementModal: false,
      settlementModalInfo: {
        mode: "",
        client: "",
        creditor: "",
        creditorId: null,
        balance: null,
        percentage: null,
        id: null,
        accountStatusDsNum: null,
      },
      showRejectedOffersModal: false,
      trackingPendingMadeDsListCreditModalOn: false,
      dsListCreditForTracking: "",
      addCreditorModalController: false,
      stateModal: 0,
      idCreditorToPass: null,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_CREATE_SETTLEMENT_GRID_MODAL:
        "DebtSolutionSettlementOfferStore/G_UPDATE_CREATE_SETTLEMENT_GRID_MODAL",
    }),
  },
  async created() {
    const data = await PriorityOffersService.getUsersByModule(
      this.$route.matched[0].meta.module
    );
    if (data.status === 200) {
      this.filters[0].options = data.data;
    }
  },

  methods: {
    closeModalCreateSettlementDetailsOn(update = false) {
      if (update) {
        this.$refs.refSettlementOffersTable.refresh();
      }
      this.modalCreateSettlementDetailsOn = false;
    },
    openModalCreateSettlementDetails(client) {
      this.clientInformation = client;
      this.modalCreateSettlementDetailsOn = true;
    },
    close() {
      this.$emit("close");
    },
    async getSettlementData(ctx) {
      const data = await CreateSettlementService.getSettlements({
        text: this.filterPrincipal.model,
        perPage: this.paginate.perPage,
        nPage: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : "created_at",
        order: ctx.sortDesc ? "desc" : "asc",
        advisor: this.filters[0].model,
        type: 1, // type: 1 pending, 2 made
        balanceFrom: this.filters[1].model,
        balanceTo: this.filters[2].model,
        debtsFrom: this.filters[3].model,
        debtsTo: this.filters[4].model,
        remFrom: this.filters[5].model,
        remTo: this.filters[6].model,
      });
      this.itemsPriorityOffers = data.data.data;
      this.startPage = data.data.from || 0;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.totalRows = data.data.total || 0;
      this.toPage = data.data.to || 0;
      if (this.itemsPriorityOffers.length > 0) {
        this.totalAvailable = data.data.data[0].total_available;
        this.totalDebts = data.data.data[0].total_debts;
        this.totalSheets = data.data.data[0].total_sheets;
      } else {
        this.totalAvailable = 0;
        this.totalDebts = 0;
        this.totalSheets = 0;
      }

      return this.itemsPriorityOffers || [];
    },

    openTrackingPendingMadeDsListCreditModal(id) {
      this.trackingPendingMadeDsListCreditModalOn = true;
      this.dsListCreditForTracking = id;
    },
    openSettlementModal(mode, item, isMigrating = false) {
      this.settlementModalInfo = {};
      this.settlementModalInfo.mode = mode;
      this.settlementModalInfo.migrate = isMigrating;
      this.settlementModalInfo.client = item.client_name;
      this.settlementModalInfo.creditorName = item.creditor_name;
      this.settlementModalInfo.creditorId = item.id;
      this.settlementModalInfo.balance = item.actual_balance;
      this.settlementModalInfo.percentage = item.min_percent;
      this.settlementModalInfo.id = item.offer_id;
      this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
      this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
      this.settlementModalInfo.creditorContact =
        item.principal_creditor_contact_number;
      this.settlementModalInfo.profitPercent = item.profit_percent;
      //  this.settlementModalInfo.account = item.account;
      this.settlementModalInfo.client_account_id = item.client_account_id;
      this.settlementModalInfo.creditor_id = item.creditor_id;
      this.settlementModalInfo.account = item.creditor_account;
      this.settlementModalInfo.accountStatusDsNum = item.account_status_ds_num;
      this.settlementModalInfo.clientAccountId = item.client_account_id;
      this.showSettlementModal = true;
    },
    openRejectedOffersModal(item) {
      this.settlementModalInfo.mode = "watch";
      this.settlementModalInfo.client = item.client_name;
      this.settlementModalInfo.creditorName = item.creditor_name;
      this.settlementModalInfo.creditorId = item.id;
      this.settlementModalInfo.balance = item.actual_balance;
      this.settlementModalInfo.percentage = item.min_percent;
      this.settlementModalInfo.id = item.offer_id;
      this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
      this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
      this.settlementModalInfo.creditorContact =
        item.principal_creditor_contact_number;
      this.settlementModalInfo.profitPercent = item.profit_percent;
      this.settlementModalInfo.client_account_id = item.client_account_id;
      this.showRejectedOffersModal = true;
    },
    closeSettlementModal() {
      this.showSettlementModal = false;
      // this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
      //   accountId: this.$route.params.idClient,
      //   userId: this.currentUser.user_id,
      // });
    },

    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.refSettlementOffersTable.refresh();
    },
    async openModalBalance({ client_account_id }) {
      this.addPreloader();
      try {
        const {
          id,
          client_name,
          account,
          client_type_id,
          total_payments,
          balance,
        } = await DashboardService.getClientData(client_account_id);
        this.modalBalanceOpened = true;
        this.paymentInformation = {
          client_account_id: id,
          client_name,
          account,
          client_type: client_type_id == 2 ? "DIGITAL" : "REGULAR",
          total_payment: total_payments,
          balance,
        };
      } catch (err) {
        console.error(err);
      }
      this.removePreloader();
    },
    closeModalBalance() {
      this.modalBalanceOpened = false;
    },
    openModalFiles({ client_account_id, client_account: account, program_id }) {
      this.currentClientData = { client_account_id, account, program_id };
      this.modalFilesOpened = true;
    },
    closeModalFiles() {
      this.modalFilesOpened = false;
      this.currentClientData = {};
    },
    handleTotalPaymentUpdate(total_payment) {
      this.paymentInformation.total_payment = total_payment;
    },
    balanceSheet(percentage_debt_client) {
      return percentage_debt_client >= 0 ? "text-primary" : "text-danger";
    },
    ...mapMutations({
      M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL:
        "DebtSolutionSettlementOfferStore/M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL",
    }),
  },
  watch: {
    G_UPDATE_CREATE_SETTLEMENT_GRID_MODAL(newValue) {
      if (newValue) {
        this.$refs.refSettlementOffersTable.refresh();
        this.M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL(false);
      }
    },
  },
};
</script>

<style scoped>
.rejected-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  background: #fc424a1f;
  color: #fc424a;
  border: 1px solid #fc424a;
  border-radius: 50%;
  font-size: 1rem;
}
</style>
