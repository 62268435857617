<template>
  <b-modal
    v-model="modalUp"
    size="xmd"
    scrollable
    title="Payment Order Rejected"
    title-tag="h3"
    @hide="hideModal()"
  >
    <b-table
      ref="tracking-table"
      small
      :items="getNotPaidreasons"
      :fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(created_by)="data">
        <div class="text-center">{{ data.item.created_by }}</div></template
      >
      <template #cell(commentary)="data"
        ><div class="text-center">{{ data.item.commentary }}</div></template
      >
      <template #cell(created_at)="data"
        ><div class="text-center">{{ data.item.created_at | myGlobalWithHour }}</div></template
      >
    </b-table>
    <template #modal-footer>
      <div></div>
    </template>
  </b-modal>
</template>

<script>
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import Fields from "@/views/debt-solution/views/settlements-made/data/fields.rejectedreasons.js";
export default {
  async created() {
    this.modalUp = this.activeModal;
    this.addPreloader();
    await this.getNotPaidreasons();
    this.removePreloader();
  },
  props: {
    activeModal: {
      type: Boolean,
      default: false,
    },
    offerPaymentOrderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fields: Fields,
      modalUp: false,
    };
  },
  methods: {
    async getNotPaidreasons() {
      try {
        const data = await SettlementsMadeService.getNotPaidreasons({
          offer_payment_order_id: this.offerPaymentOrderId,
        });
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    hideModal() {
      this.modalUp = false;
      this.$emit("close");
    },
  },
};
</script>
