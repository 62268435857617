export default [
    {
      key: "creditor_name",
      sortable: false,
      label: "Original Creditor",
      visible: true,
    },
    {
      key: "balance",
      sortable: false,
      thClass: "text-center",
      tdClass: "text-center",
      label: "Initial Balance",
      formatter: (val) => `$ ${val}`,
      visible: true,
    },
    {
      key: "creditor_actual_balance",
      sortable: false,
      thClass: "text-center",
      tdClass: "text-center",
      visible: true,
    },
    {
      key: "offer_amount",
      sortable: false,
      label: "Last Offer Amount",
      visible: true,
    },
    {
      key: "id_credits_account",
      label: "Files",
      visible: true,
    },

    {
      key: "months_behind",
      label: "Months Behind",
      thClass: "text-center",
      tdClass: "text-center",
      visible: true,
    },
    {
      key: "company_percent",
      sortable: false,
      label: "Creditor Negotiation Average",
      visible: true,
    },
    {
      key: "advisor_name",
      sortable: false,
      label: "Advisor",
      visible: true,
    },

    {
      key: "last_offer_date",
      sortable: false,
      label: "Last Offer Date",
      thClass: "text-center",
      tdClass: "text-center",
      visible: true,
    },
    {
      key: "last_offer_status",
      sortable: false,
      label: "Last Offer Status",
      visible: true,
    },
    {
      key: "actions",
      sortable: false,
      label: "Actions",
      visible: true,
    }
  ]