export default [
  {
    key: "client_name",
    label: "Client",
    visible: true,
  },
  {
    key: "user_name",
    label: "User",
    visible: true,
  },
  
  {
    key: "status",
    label: "Status",
    tdClass:"pt-2",
    visible: true,
  },
  {
    key: "motive",
    label: "Motive",
    visible: true,
  },
];
