<template>
  <b-card no-body class="mb-2">
    <filter-slot
      :filter="filters"
      :filter-principal="filterMain"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reset-all-filters="clearFilters()"
      @reload="refreshTable()"
    >
      <template #table>
        <b-table
          ref="approvalList"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          small
          no-provider-filtering
          responsive="sm"
          :items="tableProvider"
          sort-direction="desc"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          sticky-header="50vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client)="data">
            <router-link
              v-if="
                isSupervisor ||
                isCeo ||
                currentUser.user_id === data.item.advisor_id
              "
              target="_blank"
              :class="textLink"
              :to="{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              }"
              ><span :id="'client' + data.item.client_account_id">
                {{ data.item.client }}
                <br /> </span
            ></router-link>
            <span v-else :id="'client' + data.item.client_account_id"
              >{{ data.item.client }}<br
            /></span>
            <span>{{ data.item.client_account }}</span>
          </template>
          <template #cell(creditor)="data">
            {{ data.item.creditor }} <br />
            <span
              v-if="
                data.item.name_parent != null &&
                data.item.creditor != data.item.name_parent
              "
            >
              ({{ data.item.name_parent }})<br
            /></span>
            #{{ data.item.creditor_account }}
          </template>
          <template #cell(actual_balance)="data">
            <span>$ {{ data.value | currencyZero }}</span>
          </template>
          <template #cell(profit)="data">
            <span class="text-nowrap">
              $
              {{
                parseFloat(
                  (data.item.balance - data.item.offer_amount) * 0.25
                ).toFixed(2) | currency
              }}
            </span>
          </template>
          <template #cell(av_balance)="data">
            <span :class="data.value > 0 ? 'text-primary' : 'text-danger'">
              $ {{ data.value | currencyZero }}</span
            >
          </template>
          <template #cell(offer)="data">
            <span class="text-nowrap">
              %
              {{
                parseFloat(
                  (100 * data.item.offer_amount) / data.item.balance
                ).toFixed(2)
              }}
            </span>
          </template>
          <template #cell(result)="data">
            <b-badge :variant="colorizeResult(data.value)" pill>{{
              data.value
            }}</b-badge>
          </template>
          <template #cell(process)="data">
            <b-badge :variant="colorizeProcess(data.item.process_num)" pill>{{
              data.value
            }}</b-badge>
          </template>
          <template #cell(status)="data">
            <!--            <b-badge-->
            <!--              :variant="colorizeStatus(data.value)"-->
            <!--              pill-->
            <!--            >{{-->
            <!--              data.value-->
            <!--            }}</b-badge>-->
            <feather-icon
              v-if="data.item.status_num === 1"
              v-b-tooltip.hover.top="'PENDING'"
              icon="ClockIcon"
              size="20"
              class="cursor-pointer text-info"
            />
            <feather-icon
              v-if="data.item.status_num === 2"
              v-b-tooltip.hover.top="'REJECTED'"
              icon="XCircleIcon"
              size="20"
              class="cursor-pointer text-danger"
            />
            <feather-icon
              v-if="data.item.status_num === 3"
              v-b-tooltip.hover.top="'APPROVED'"
              icon="CheckCircleIcon"
              size="20"
              class="cursor-pointer text-success"
            />
          </template>
          <template #cell(entry_date)="data">
            <span> {{ data.item.entry_date | myGlobalWithHour }}</span>
          </template>
          <template #cell(balance)="data">
            <span class="text-nowrap">$ {{ data.value | currency }}</span>
          </template>
          <template #cell(offer_amount)="data">
            $ {{ data.value | currency }}
          </template>
          <template #cell(offer_created_at)="data">
            {{ data.value | myGlobal }}
          </template>

          <template #cell(created_by)="data">
            {{ data.value }} <br />
            <small v-if="!isPending">{{
              data.item.offer_date | myGlobal
            }}</small>
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              size="22"
              icon="AlignLeftIcon"
              class="text-primary cursor-pointer"
              @click="openTrackingModal(data.item.id)"
            />
          </template>
          <template #cell(payments)="data">
            <div
              class="d-flex justify-content-center cursor-pointer"
              @click="openModalBalance(data.item)"
            >
              <tabler-icon
                icon="CurrencyDollarIcon"
                size="20"
                class="text-success"
              />
            </div>
          </template>
          <template #cell(files)="data">
            <div
              class="d-flex justify-content-center cursor-pointer"
              @click="openModalFiles(data.item)"
            >
              <tabler-icon
                icon="FolderIcon"
                size="20"
                :class="'text-warning'"
              />
            </div>
          </template>
          <template #cell(creditor_files)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="FolderIcon"
                :class="data.item.cc_files > 0 ? 'text-warning' : ''"
                size="18"
                class="cursor-pointer"
                @click="openModalFilesCreditor(data.item)"
              />
            </div>
          </template>

          <template #cell(modal)="data">
            <feather-icon
              size="22"
              class="cursor-pointer"
              :icon="data.item.result == 'PENDING' ? 'EditIcon' : 'EyeIcon'"
              :class="
                data.item.result == 'PENDING' ? 'text-success' : 'text-warning'
              "
              @click="openOfferModal(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <settlement-tracking-modal
      :show="showSettlementTracking"
      :offer="trackingOfferId"
      @closing="showSettlementTracking = false"
    />
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="event_id"
      @reload="refreshTable"
      @closing="closeSettlementModal"
    />
    <modal-balance
      v-if="modalBalanceOpened"
      :payment-information="paymentInformation"
      @updateTotalPayment="handleTotalPaymentUpdate"
      @close="closeModalBalance"
    />
    <modal-files
      v-if="modalFilesOpened"
      :data-file="currentClientData"
      :component-mode="2"
      @close="closeModalFiles"
    />
    <upload-files-modal
      v-if="uploadFilesModalController"
      :idcreditor="accountToSend.creditor_id"
      :idcreditoraccount="accountToSend.id_credits_account"
      :caid="client_account_id_upload"
      @close="uploadFilesModalController = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import SettlementTrackingModal from "@/views/debt-solution/views/settlement-offer/SettlementTrackingModal.vue";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
import filterSettlement from "@/views/debt-solution/views/accounts-actions/filters/settlement";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import DashboardService from "@/views/business/views/clients/dashboard/services/dashboard.service.js";

import ModalBalance from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalBalance.vue";
import ModalFiles from "@/views/commons/components/clients/dashboard/options/files-module/Files.vue";
import UploadFilesModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/UploadFilesModal.vue";

export default {
  components: {
    SettlementTrackingModal,
    SettlementOfferModal,
    ModalBalance,
    ModalFiles,
    UploadFilesModal,
  },
  props: {
    isPending: false,
  },
  data() {
    return {
      filters: filterSettlement,
      totalRows: 0,
      filterMain: {
        type: "input",
        inputType: "text",
        placeholder: "Search Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 1,
      toPage: 2,
      fields: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "creditor",
          label: "Account",
        },
        {
          key: "creditor_actual_balance",
          label: "Creditor Actual Balance",
        },
        {
          key: "balance",
          label: "Balance",
        },
        {
          key: "actual_balance",
          label: "ACTUAL BALANCE",
        },
        {
          key: "months_behind",
          label: "Months Behind",
        },
        {
          key: "account_status_ds",
          label: "Account Status",
        },
        {
          key: "desciption_accountphase",
          label: "Account Phase",
        },
        {
          key: "av_balance",
          label: "Available Balance",
        },
        {
          key: "offer_amount",
          label: "Offer Amount",
        },
        {
          key: "offer",
          label: "% Offer",
        },
        {
          key: "profit",
          label: "Settlement Fee",
        },
        {
          key: "offer_created_at",
          label: "Offer Created",
        },
        {
          key: "payments",
          sortable: false,
          label: "Clients Payments",
          visible: true,
          class: "text-center",
        },
        {
          key: "files",
          sortable: false,
          label: "Client Files",
          visible: true,
          class: "text-center",
        },
        {
          key: "creditor_files",
          label: "Creditor Files",
          visible: true,
        },
        {
          key: "result",
          label: "Offer Result",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "process",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "status",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_by",
          label: "Created By",
        },
        {
          key: "tracking",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "modal",
          label: "Actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      trackingOfferId: null,
      showSettlementTracking: false,
      showSettlementOffer: false,
      offerModalInfo: {},
      continueInfo: {
        modal: false,
        mode: "",
        message: "",
        offerId: null,
      },
      modalBalanceOpened: false,
      modalFilesOpened: false,
      paymentInformation: {},
      currentClientData: {},
      uploadFilesModalController: false,
      accountToSend: {},
      client_account_id_upload: "",
      event_id: null,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    openModalFilesCreditor(item) {
      this.client_account_id_upload = item.client_account_id;
      this.uploadFilesModalController = true;
      this.accountToSend = item;
    },
    async tableProvider() {
      await AccountsActionsService.counterOffer({
        role_id: this.currentUser.role_id,
      });
      const params = {
        field: this.filterMain.model,
        user_id: this.currentUser.user_id,
        advisor_id: this.isPending
          ? this.currentUser.user_id
          : this.filters[2].model,
        status: this.isPending ? 1 : this.filters[3].model,
        result: this.isPending ? 3 : this.filters[4].model,
        process: this.filters[5].model,
        per_page: this.paginate.perPage,
        actual_page: this.paginate.currentPage,
        from: this.filters[0].model,
        to: this.filters[1].model,
      };
      const res = await AccountsActionsService.searchOfferByStatus(params);
      this.totalRows = res.total || 0;
      this.toPage = res.to || 0;
      this.startPage = res.from || 0;
      this.paginate.perPage = res.per_page;
      this.paginate.currentPage = res.current_page;

      return res.data;
    },
    refreshTable() {
      this.$refs.approvalList.refresh();
    },
    clearFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterMain.model = null;
      this.$refs.approvalList.refresh();
    },
    openTrackingModal(id) {
      this.trackingOfferId = id;
      this.showSettlementTracking = true;
    },
    openOfferModal(item) {
      if (item.result == "PENDING") {
        this.offerModalInfo.mode = "pending";
      } else {
        this.offerModalInfo.mode = "watch";
      }
      const hasParent =
        item.name_parent != null && item.creditor != item.name_parent;
      this.offerModalInfo.client = item.client;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorName += hasParent
        ? " (" + item.name_parent + ")"
        : "";
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = parseFloat(item.balance).toFixed(2);
      this.offerModalInfo.client_balance = item.actual_balance;
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.account_name = item.client_account;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.creditorShortId = item.principal_creditor_id;
      this.offerModalInfo.account = item.client_account_id;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.offerModalInfo.account_number = item.creditor_account;
      this.event_id = item.event_id;
      this.offerModalInfo.client_balance = item.available_balance;

      this.showSettlementOffer = true;
    },
    continueProcess(process, item) {
      switch (process) {
        case "approve":
          this.continueInfo.mode = "Approve";
          break;
        case "reject":
          this.continueInfo.mode = "Reject";
          break;
      }

      this.continueInfo.offerId = item.id;
      this.continueInfo.modal = true;
    },
    continueOk() {
      switch (this.continueInfo.mode) {
        case "Reject":
          this.reviewOffer(false);
          break;
        case "Approve":
          this.reviewOffer(true);
          break;
      }
    },
    async reviewOffer(accept) {
      this.loading = true;
      const res = await AccountsActionsService.updateOffer({
        offer_id: this.continueInfo.offerId,
        user_id: this.currentUser.user_id,
        is_accepted: accept,
        comment: this.continueInfo.message || ".",
      });
      if (res.status != 200) {
        this.$swal.fire({
          icon: "error",
          title: "nel",
        });
        this.handleHidden();
      }
      this.$swal.fire({
        icon: "success",
        title: "Offer Reviewed",
      });
    },
    handleContinueHidden() {
      this.continueInfo.message = "";
      this.continueInfo.offerId = null;
      this.$refs.approvalList.refresh(1);
    },
    colorizeProcess(process) {
      // REQUEST, VERIFICATION, UNDERVIEW
      switch (process) {
        case 3:
          return "light-primary";
        case 1:
          return "light-info";
        case 2:
          return "light-warning";
        default:
          return "light-secondary";
      }
    },
    colorizeResult(result) {
      switch (result) {
        case "ACCEPT":
          return "light-success";
        case "REJECT":
          return "light-danger";
        case "PENDING":
          return "light-warning";
        default:
          return "light-secondary";
      }
    },
    colorizeStatus(status) {
      switch (status) {
        case "REJECTED":
          return "light-danger";
        case "APPROVED":
          return "light-success";
        case "PENDING":
          return "light-info";
        default:
          return "light-secondary";
      }
    },
    closeSettlementModal() {
      this.showSettlementOffer = false;
    },
    async usersPrograms() {
      try {
        const params = {
          idmodule: this.moduleId,
          iduser: this.currentUser.user_id,
          idrole: this.currentUser.role_id,
        };
        const data = await ClientService.usersPrograms(params);
        this.filters[2].options = data;
      } catch (error) {
        console.log(error);
      }
    },
    async openModalBalance({ client_account_id }) {
      this.addPreloader();
      try {
        const {
          id,
          client_name,
          account,
          client_type_id,
          total_payments,
          balance,
        } = await DashboardService.getClientData(client_account_id);
        this.modalBalanceOpened = true;
        this.paymentInformation = {
          client_account_id: id,
          client_name,
          account,
          client_type: client_type_id == 2 ? "DIGITAL" : "REGULAR",
          total_payment: total_payments,
          balance,
        };
      } catch (err) {
        console.error(err);
      }
      this.removePreloader();
    },
    closeModalBalance() {
      this.modalBalanceOpened = false;
    },
    openModalFiles({ client_account_id, client_account: account, program_id }) {
      this.currentClientData = { client_account_id, account, program_id };
      this.modalFilesOpened = true;
    },
    closeModalFiles() {
      this.modalFilesOpened = false;
      this.currentClientData = {};
    },
    handleTotalPaymentUpdate(total_payment) {
      this.paymentInformation.total_payment = total_payment;
    },
  },
  created() {
    // hidden fields if is pending
    if (this.isPending) {
      // tracking
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "tracking"),
        1
      );
      // process
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "process"),
        1
      );
      // creditor change label to "Original Creditor"
      this.fields[
        this.fields.findIndex((field) => field.key === "creditor")
      ].label = "Original Creditor";
      // balance
      this.fields[
        this.fields.findIndex((field) => field.key === "balance")
      ].label = "INITIAL BALANCE";
      // av_balance
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "av_balance"),
        1
      );
      // offer
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "offer"),
        1
      );
      // profit
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "profit"),
        1
      );
      // created_by
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "created_by"),
        1
      );
      // creditor_actual_balance
      this.fields.splice(
        this.fields.findIndex(
          (field) => field.key === "creditor_actual_balance"
        ),
        1
      );
      // offer_amount
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "offer_amount"),
        1
      );
      // status
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "status"),
        1
      );
      // result
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "result"),
        1
      );
      //  visible:false filters 2,3,4,5
      this.filters[2].visible = false;
      this.filters[3].visible = false;
      this.filters[4].visible = false;
      this.filters[5].visible = false;
    } else {
      // creditor_actual_balance
      this.fields.splice(
        this.fields.findIndex(
          (field) => field.key === "creditor_actual_balance"
        ),
        1
      );
      // account_status_ds
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "account_status_ds"),
        1
      );
      // desciption_accountphase
      this.fields.splice(
        this.fields.findIndex(
          (field) => field.key === "desciption_accountphase"
        ),
        1
      );
      // months_behind
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "months_behind"),
        1
      );
      // actual_balance
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "actual_balance"),
        1
      );
      // payments
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "payments"),
        1
      );
      // files
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "files"),
        1
      );
      // creditor_files
      this.fields.splice(
        this.fields.findIndex((field) => field.key === "creditor_files"),
        1
      );
    }
  },
};
</script>
