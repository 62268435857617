<template>
  <b-modal
    ref="transfer-complete-modal"
    :size="'xl'"
    title="DEPOSITS"
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="hidden"
  >
    <TransferCompleteTable />
  </b-modal>
</template>

<script>
import TransferCompleteTable from "@/views/debt-solution/views/accounts-actions/components/TransferCompleteTable.vue";

export default {
  components: {
    TransferCompleteTable,
  },
  mounted() {
    this.toggleModal("transfer-complete-modal");
  },
  methods: {
    hidden() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>