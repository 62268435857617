<template>
  <div>
    <b-modal
      ref="binnacle-order-modal"
      title="Binnacle Order"
      size="xl"
      :header-bg-variant="isDarkSkin ? 'default' : 'primary'"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
      @hidden="closeModal"
      title-tag="h3"
    >
      <div>
        <b-row>
          <!-- Details of Order Payment -->
          <b-col lg="6" xl="5" class="border-right">
            <div class="py-1 px-3 mx-3 mt-2 mb-2 show-module rounded border">
              <b-icon icon="person" class="bg-orange-i"></b-icon>
              <span class="module-name ml-1">{{
                data_binnacle.client_name
              }}</span>
            </div>

            <hr style="margin-top: 0.2rem !important" />
            <div class="scroll-kanban max-height-content">
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Order Payment</span>
                <div class="col-md-8">
                  <strong># {{ details.code }}</strong>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Creditor</span>
                <div class="col-md-8">
                  <strong>{{ data_binnacle.creditor_name }}</strong>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Request by</span>
                <div class="col-md-8">
                  <div>
                    {{ details.request_by }}
                  </div>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Request</span>
                <div class="col-md-8">
                  <div>
                    {{ formatDateTime(details.created_at) }}
                  </div>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Status</span>
                <div class="col-md-8">
                  <div
                    :style="
                      'color:' +
                      details.statusColor +
                      '!important; background: rgba(' +
                      hexToRgb(details.statusColor) +
                      ', 0.2)!important; font-size: 12px;'
                    "
                  >
                    <b-icon icon="circle-fill"> </b-icon>
                    {{ details.status }}
                  </div>
                </div>
              </b-row>
            </div>
          </b-col>

          <!-- Chat -->
          <b-col lg="6" xl="7">
            <div class="py-1 px-2 scroll-kanban max-height-content">
              <div
                class="my-1 rounded card-comments"
                :class="[
                  isDarkSkin ? 'bg-rgba' : 'bg-rgba-light',
                  comments.length === 0
                    ? 'd-flex text-center justify-content-center align-items-center'
                    : '',
                ]"
                ref="scrollList"
              >
                <div v-if="comments.length === 0">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-1 text-warning"
                    size="50"
                  ></feather-icon>

                  <span
                    class="text-warning"
                    style="font-size: 1.4em; font-weight: 700"
                    >No comments</span
                  >
                </div>

                <div else>
                  <template v-for="(item, index) in comments">
                    <div
                      v-if="comments.length > 0"
                      :key="index"
                      :class="{
                        'cursor-pointer': item.is_file,
                        me: item.module_origin == type_message,
                        other: item.module_origin != type_message,
                      }"
                      class="p-1 mb-1 content-comment rounded"
                      @click="showFile(item)"
                      :style="backgroundComment(item.type)"
                    >
                      <span
                        :style="
                          item.user_id == currentUser.user_id
                            ? 'color: #BB1EF5'
                            : 'color: #2BC8CA'
                        "
                        class="font-weight-bold"
                      >
                        {{ item.user_name }}
                      </span>
                      <br />
                      <span> {{ item.content }}</span>
                      <div class="ml-4 text-right" style="color: #6e6b7b">
                        {{ item.date_time | myGlobalDay }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <b-row
                v-if="
                  details.status !== 'NEGOTIATION COMPLETED' &&
                  details.status !== 'NEGOTIATION NOT COMPLETED'
                "
              >
                <b-col sm="12">
                  <div class="d-flex justify-content-between">
                    <v-select
                      v-model="comment.content"
                      :options="optionsMessage"
                      :clearable="false"
                      label="message"
                      style="width: 89%"
                      :disabled="loading"
                    />
                    <b-button
                      variant="success"
                      class="rounded"
                      @click="insertComment()"
                      :disabled="loading"
                      style="width: 10%"
                    >
                      <b-spinner v-if="loading" small type="grow"></b-spinner>
                      <feather-icon v-else icon="SendIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"

/* SERVICES */
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service"

// Import Data
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue"
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue"
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue"
export default {
  components: { StatusAccount, StatusPayment, IconStatus },
  props: {
    order_id: {
      type: Number,
    },
    type_message: {
      type: String,
      default: "ds",
    },
    binnacleModal: {
      type: Boolean,
      default: false,
    },
    data_binnacle: {
      type: Object,
      default: {},
    },
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      content: "",
      data: null,
      comment: {
        content: null,
      },
      optionsMessage: [],
      comments: [],
      details: {
        order_id: null,
        titleHeader: null,
        created_at: null,
        status: null,
        status_id: null,
        statusColor: null,
        request_by: null,
        code: null,
        amount: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.searhBinnacleOrder()
    this.getOptionMessages()
  },
  mounted() {
    this.toggleModal("binnacle-order-modal")
    this.scrollCommentsBottom()
    this.activeSocketBinnacleMessage()
  },

  methods: {
    closeModal() {
      this.$emit("close", this.item)
    },
    async searhBinnacleOrder() {
      try {
        this.addPreloader()
        const params = {
          order_id: this.order_id,
          origin: this.type_message == "ds" ? 1 : 2,
          // user_id: this.currentUser.user_id,
        }
        //const response = await helpdeskService.getDetailsTicket(params);
        const { data } = await SettlementsMadeService.getOrderBinnacle(params)
        const {
          order_id,
          amount,
          code,
          request_by,
          status_id,
          status,
          created_at,
        } = data.data

        this.details.order_id = order_id
        this.details.code = code
        this.details.created_at = created_at
        this.details.amount = amount
        this.details.titleHeader = "Binnacle Order"
        this.details.status_id = status_id
        this.details.status = status
        this.details.statusColor = this.checkStatusColor(this.details.status_id)
        this.details.order_id = order_id
        this.details.request_by = request_by
        this.comments = data.comments
        this.scrollCommentsBottom()
      } catch (error) {
        console.log(error)
      } finally {
        this.removePreloader()
      }
    },
    async getOptionMessages() {
      try {
        const { data } = await SettlementsMadeService.getOptionMessages({
          type: this.type_message,
          status: "active",
        })
        this.optionsMessage = data
      } catch (error) {
        console.log(error)
      }
    },
    async insertComment() {
      if (this.comment.content == null || this.comment.content == "") {
        return
      }
      try {
        this.addPreloader()
        this.scrollCommentsBottom()
        this.loading = true
        const params = {
          order_id: this.order_id,
          binnacle_message_id: this.comment.content.id,
          type: "comment",
          content: this.comment.content.message,
          user_id: this.currentUser.user_id,
          module_origin: this.type_message == "ds" ? "ds" : "management",
        }
        const response = await SettlementsMadeService.insertBinnacleMessage(
          params
        )
        this.comment.content = null
        this.comments = response.data
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        })
        this.comments.splice(this.components.length - 1, 1)
      } finally {
        this.loading = false
        this.removePreloader()
      }
    },
    scrollCommentsBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
        }, 100)
      })
    },
    showFile(item) {
      /* if (item.is_file == 0) return;
      window.open(item.file_path); */
    },
    backgroundComment(type) {
      let boxShadow = "0 1px 3px rgb(0 0 0 / 20%)"
      let style = ""
      if (type == 1) {
        style = `background: ${
          this.isDarkSkin ? `rgb(32 32 36)` : "rgb(255 255 255)"
        }!important;`
      } else if (type == 2) {
        style = `background: ${
          this.isDarkSkin ? `rgb(16 16 18)` : "rgb(228 234 236)"
        }!important;`
      } else {
        style = `background: ${
          this.isDarkSkin ? `rgb(23 23 26)` : "rgb(255 246 244) "
        }!important;`
      }

      return `${style} box-shadow: ${
        !this.isDarkSkin ? boxShadow : ""
      }!important;`
    },
    checkStatusColor(status) {
      switch (true) {
        case status === 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833"
        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207"
        case status === 3: // DEBIT REJECTED
          return "#ff0000"
        case status === 4: // DEBIT CONFIRMED
          return "#66b70b"
        case status === 5: // PENDING COMPLETION
          return "ffd833"
        case status === 6: // COMPLETED
          return "#54b408"
        default:
          break
      }
    },
    formatDateTime(date) {
      return moment(date).format("MM/DD/YYYY HH:mm")
    },
    activeSocketBinnacleMessage() {
      try {
        window.socket.subscribe("management-notifications")
        window.socket.bind(
          "binnacle-message-notifications-management",
          async (response) => {
            const { message, swet_message } = response
            if (this.order_id == swet_message.order_id) {
              const isset_message = this.comments.find(
                (row) => row.id == message.id
              )
              if (isset_message == undefined) {
                this.comments.push(message)
                this.scrollCommentsBottom()
              }
            }
          }
        )
      } catch (error) {
        console.log("Socket-settlement", error)
      }
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.classList.add("style-chooser")
      // ...  adjust the position
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("binnacle-message-notifications-management")
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped>
.card-comments {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  font-size: 1rem;
  min-height: 45vh;
  max-height: 45vh;
  overflow-y: auto;
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
}
.content-comment {
  max-width: 80%;
  border-radius: 20px;
  padding: 5px 10px;
}
.me {
  margin-left: auto;
}
.other {
  margin-right: auto;
}

.bg-rgba {
  background: rgb(0 0 0 / 18%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 4%) !important;
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}

.style-chooser.vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>