<template>
    <b-modal
      ref="offer-tracking"
      title="TRACKING"
      modal-class="modal-primary"
      size="lg"
      hide-footer
      @hidden="close"
    >
      <b-table
        ref="trackingList"
        no-border-collapse
        class="position-relative"
        :fields="fields" 
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="tableProvider"
      >
      
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(process)="data">
          <b-badge v-if="data.item.type_tracking === 1" :variant="colorizeProcess(data.item.process_num)" pill>{{
            data.value
          }}</b-badge>
        </template>
        <template #cell(status)="data">
          <div class="d-flex justify-content-center">
            <feather-icon
                v-if="data.item.type_tracking === 1"
                v-b-tooltip.hover.top="getStatusDescription(data.item.status_num, data.item.type_tracking)"
                :icon="getStatusIcon(data.item.status_num, data.item.type_tracking)"
                size="20"
                class="cursor-pointer"
                :class="getClassStatus(data.item.status_num, data.item.type_tracking)"
            />
            <tabler-icon
                v-if="data.item.type_tracking === 2"
                v-b-tooltip.hover.top="getStatusDescription(data.item.status_num, data.item.type_tracking)"
                :icon="getStatusIcon(data.item.status_num, data.item.type_tracking)"
                size="20"
                class="cursor-pointer"
                :class="getClassStatus(data.item.status_num, data.item.type_tracking)"
            />
          </div>
        </template>
        <template #cell(date)="data">
          {{ data.value | myGlobalWithHour }}
        </template>
      </b-table>
    </b-modal>
  </template>
  
  <script>
  export default {
    props: {
      offer: Number,
    },
    data() {
      return {
        fields: [
          {
            key: "user",
            label: "User",
          },
          {
            key: "process",
            label: "Process",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "date",
            label: "Date",
          },
          {
            key: "comment",
            label: "comment",
          },
          {
            key: "code",
            label: "Payment Order Code",
          }
        ],
      };
    },
    
    mounted() {
    this.toggleModal("offer-tracking");
    // console.log(this.offers)
  },
    methods: {
      close() {
        this.$emit('close')
      },
      async tableProvider() {
        let res = await amgApi.post("/debt-solution/get-offer-tracking", {
          offer_id: this.offer,
        });
  
        return res.data;
      },
      colorizeProcess(process) {  // REQUEST, VERIFICATION, UNDERVIEW
        switch (process) {
          case 3:
            return "light-primary";
          case 1:
            return "light-info";
          case 2:
            return "light-warning";
          default:
            return "light-secondary";
        }
      },
      colorizeStatus(status) {
        switch (status) {
          case "REJECTED":
            return "light-danger";
          case "APPROVED":
            return "light-success";
          case "PENDING":
            return "light-info";
          default:
            return "light-secondary";
        }
      },
      getStatusDescription(status, typeTracking) {
        // typeTracking 1: tracking offer, 2: tracking payment order
        switch (typeTracking){
          case 1:
            switch (status){
              case 1:
                return "PENDING";
              case 2:
                return "REJECTED";
              case 3:
                return "APPROVED";
              default:
                return "UNKNOWN";
            }
          case 2:
            switch (status) {
              case 1: // PENDING DEBIT CONFIRMATION
                return "Pending Debit Confirmation";
              case 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
                return "Pending Processing Due To Insufficient Funds";
              case 3:
                return "Negotiation not completed"; // Negotiation not completed
              case 4: // Negotiation completed
                return "Negotiation completed";
              case 5:
                return "Pending Completion"; // PENDING COMPLETION
              case 6:
                return "Completed"; // COMPLETED
              default:
                break;
            }
        }
      },
      getStatusIcon(status, typeTracking) {
        // typeTracking 1: tracking offer, 2: tracking payment order
        switch (typeTracking){
          case 1:
            switch (status){
              case 1:
                return "ClockIcon";
              case 2:
                return "XCircleIcon";
              case 3:
                return "CheckCircleIcon";
              default:
                return "ClockIcon";
            }
          case 2:
            switch (status) {
              case 1:
                return "CashIcon";
              case 2:
                return "CashIcon";
              case 3:
                return "CashIcon";
              case 4:
                return "CashIcon";
              case 5:
                return "CheckIcon";
              case 6:
                return "ChecksIcon";
              default:
                return "CashIcon";
            }
        }
      },
      getClassStatus(status, typeTracking) {
        // typeTracking 1: tracking offer, 2: tracking payment order
        switch (typeTracking){
          case 1:
            switch (status){
              case 1:
                return "text-info";
              case 2:
                return "text-danger";
              case 3:
                return "text-success";
              default:
                return "";
            }
          case 2:
            switch (status) {
              case 1:
              case 5:
                return "text-yellow";
              case 2:
                return "text-warning";
              case 3:
                return "text-danger";
              case 4:
              case 6:
                return "text-success";
              default:
                return "";
            }
        }
      },
    },
    
  };
  </script>
  <style scoped>
  .text-yellow{
    color: #ffd833 !important;
  }
  </style>