<template>
  <div class="d-flex justify-content-center pt-3">
    <div v-if="checkGeneratedStatus">
      <p class="text-center font-medium-2">
        A check with number #{{ clientCreditorCheck.check_number }} has been
        generated and a<br />
        notification has been sent to Management for debit confirmation<br />
        <br />
        Client: {{ clientCreditorCheck.client_name }} <br />
        Creditor: {{ clientCreditorCheck.creditor_name }} <br />
      </p>
      <div class="d-flex justify-content-center pt-2">
        <b-button class="px-2" variant="success" @click="closeModal"
          >ACCEPT</b-button
        >
      </div>
    </div>

    <div v-else>
      <p class="text-center font-medium-2">
        A payment order has been created with status<br />
        PENDING PROCESSING DUE TO INSUFFICIENT FUNDS <br />
        <br />
        Client: {{ clientCreditorCheck.client_name }} <br />
        Creditor: {{ clientCreditorCheck.creditor_name }} <br />
      </p>
      <div class="d-flex justify-content-center pt-2">
        <b-button class="px-2" variant="primary" @click="closeModal"
          >ACCEPT</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checkGeneratedStatus: {
      type: Boolean,
      required: true,
    },
    clientCreditorCheck: {
      type: Object,
    },
  },
  data() {},
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
