export default [
  {
    key: "client_name",
    label: "Client",
    visible: true,
  },
  {
    key: "deposit",
    label: "Deposit",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "actions",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "detail",
    label: "Offer",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
  },
  {
    key: "creditor",
    label: "Creditor",
    visible: true,
  },
  {
    key: "collection",
    // label: "Collection",
    label: "Actual Creditor",
    visible: true,
  },
  {
    key: "advisor_name",
    label: "Negotiator",
    visible: true,
    class: "text-center",
  },
  {
    key: "balance",
    label: "Actual Balance",
    visible: true,
    class: "text-center",
  },
  {
    key: "client_last_balance",
    label: "Client Last Balance",
    visible: true,
    class: "text-center",
  },
  {
    key: "offer_amount",
    label: "Offer Amount",
    visible: true,
    class: "text-center",
  },

  {
    key: "payment_method",
    label: "Payment Method",
    visible: true,
    class: "text-center",
  },
  {
    key: "saving",
    label: "Saving",
    visible: true,
    class: "text-center",
  },
  {
    key: "profit",
    label: "Profit",
    visible: true,
    class: "text-center",
  },

  {
    key: "payment_type",
    label: "Payment Type",
    visible: true,
    class: "text-center",
    sortable: true,
  },
  {
    key: "bank_name",
    label: "Payment Account",
    visible: true,
    class: "text-center",
  },
  {
    key: "account_name",
    label: "Account Name",
    visible: false,
    class: "text-center",
  },
  {
    key: "payment_mode",
    label: "Payment Format",
    visible: true,
    class: "text-center",
    sortable: true,
  },

  {
    key: "offer_document",
    label: "Docs",
    visible: true,
    class: "text-center",
  },
  {
    key: "id_status",
    label: "Status",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "created_at",
    label: "Offer Created",
    class: "text-center",
    visible: true,
  },
  {
    key: "payment_date",
    label: "Payment Date",
    class: "text-center",
    visible: true,
    sortable: true,
  },

  {
    key: "aproved_at",
    label: "Offer Approved",
    visible: true,
    class: "text-center",
  },
  {
    key: "orden_aproved_at",
    label: "Payment Order Created",
    visible: true,
    class: "text-center",
  },

  {
    key: "tracking",
    label: "Offer Tracking",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },

  {
    key: "actions",
    label: "actions",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "check_manual_exist",
    label: "Manual Check",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
