<template>
  <b-modal
    ref="migration-modal"
    modal-class="modal-primary modal-migrations-pending"
    title-class="h3 text-white"
    title="Migrations Pending"
    size="caro"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <div class="px-2">
      <filter-slot
        :filter="[]"
        :total-rows="FiltersForSlot.totalRows"
        :paginate="FiltersForSlot.paginate"
        :start-page="FiltersForSlot.startPage"
        :to-page="FiltersForSlot.toPage"
        :filter-principal="FiltersForSlot.filterPrincipal"
        @reload="$refs['migrateTable'].refresh()"
      >
        <b-table
          ref="migrateTable"
          slot="table"
          small
          class="blue-scrollbar"
          :items="myProvider"
          :fields="Fields"
          :busy="isBusy"
          primary-key="id"
          responsive="sm"
          sticky-header="50vh"
          show-empty
          no-provider-filtering
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :per-page="FiltersForSlot.paginate.perPage"
          :current-page="FiltersForSlot.paginate.currentPage"
        >
          <template #cell(client)="data">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <router-link
                  :class="[textLink]"
                  :to="{
                    name: 'debt-solution-dashboard',
                    params: {
                      idClient: data.item.account_id,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.client }}
                </router-link>
                <div>{{ data.item.account }}</div>
              </div>
            </div>
          </template>

          <template #cell(detail)="data">
            <feather-icon
              size="22"
              icon="EyeIcon"
              class="text-warning cursor-pointer"
              @click="openOfferModal(data.item)"
              v-b-tooltip.hover.left="'SEE OFFER SUMMARY'"
            />
          </template>

          <template #cell(offer_amount)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>


          <template #cell(payment_method)="data">
            <div class="d-flex align-items-center justify-content-center">
              <div>
                <v-select 
                  v-if="data.item.id == editPaymentMethodOfferId && editPaymentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="editPaymentMethodId"
                  :options="paymentMethodOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.id"
                  class="v-select-custom" 
                  @input="changePaymentMethod(data.item)"
                />
                <span v-else >
                  {{ data.item.payment_method }}
                </span>
              </div>
              <div class="ml-1">
                <feather-icon 
                  v-if="!(data.item.id == editPaymentMethodOfferId && editPaymentMethod)"
                  class="text-warning mr-2 cursor-pointer"
                  icon="Edit2Icon"
                  size="20"
                  @click="editPaymentMethodOfferId = data.item.id ; editPaymentMethod = true; editPaymentMethodId = data.item.method_payment_code"
                />
                <feather-icon 
                  v-else
                  class="text-danger mr-2 cursor-pointer"
                  icon="XIcon"
                  size="20"
                  @click="clearEditPaymentMethod"
                />
              </div>

            </div>
          </template>

          <template #cell(bank_name)="data">
            <div>
              <span v-if="data.item.id_status">
                {{ data.item.bank_name }}
                <br />
                {{ data.item.account_name }}
              </span>
              <span v-else> - </span>
            </div>
          </template>

          <template #cell(payment_date)="data">
            <span>{{ data.value | myGlobal }}</span>
          </template>

          <template #cell(payment_order)="data">
            <b-row>
              <b-col>{{ data.item.order_payment_created_by }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ data.item.orden_aproved_at | myGlobalWithHour }}</b-col>
            </b-row>
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <b-button
                variant="success"
                v-if="
                  (!data.item.id_status || data.item.id_status == 1) &&
                  [1, 2].includes(data.item.method_payment_code) &&
                  (isCeo || isSupervisor)
                "
                v-b-tooltip.hover.right="'Confirm Debit'"
                style="padding: 6px 8px"
                @click="editStatus(data.item, 'confirm')"
              >
                <span>COLLECTED</span>
              </b-button>
              <!-- <b-button
                variant="danger"
                v-if="
                  (!data.item.id_status || data.item.id_status == 1) &&
                  (isCeo || isSupervisor) &&
                  [1, 2].includes(data.item.method_payment_code)
                "
                v-b-tooltip.hover.right="'Reject Debit'"
                style="padding: 6px 8px"
                class="ml-1 mr-1"
                @click="editStatus(data.item, 'reject')"
              >
                <span>NOT COLLECTED</span>
              </b-button> -->
              <b-button
                :variant="data.item.id_status == 5 ? 'info' : 'warning'"
                v-if="
                  enablePifUpload(data.item) ||
                  (data.item.id_status == 5 && !isAdvisor)
                "
                @click="openPifModal(data.item, 1)"
                style="padding: 5px 8px"
                v-b-tooltip.hover.bottom="
                  `${validateIcon(data.item.id_status)}`
                "
              >
                <feather-icon
                  class="cursor-pointer"
                  :icon="data.item.id_status == 5 ? 'CheckIcon' : 'UploadIcon'"
                  size="18"
                  style="margin-right: 4px"
                />
                <span style="text-transform: uppercase">{{
                  validateIcon(data.item.id_status)
                }}</span>
              </b-button>
              <div
                class="d-flex justify-content-center"
                v-if="
                  enablePifUpload(data.item) ||
                  (data.item.id_status == 5 && !isAdvisor)
                "
              >
                <b-button
                  variant="info"
                  v-if="
                    data.item.method_payment_code == 2 &&
                    !data.item.check_manual_exist
                  "
                  style="padding: 5px 8px"
                  class="ml-1"
                  @click="openPifModal(data.item, 2)"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="UploadIcon"
                    size="18"
                  />
                  CHECK MANUAL
                </b-button>
              </div>
            </div>
          </template>

          <template #cell(offer_tracking)="data">
            <feather-icon
              size="20"
              icon="AlignLeftIcon"
              class="text-primary cursor-pointer"
              @click="openSettlementTrackingModal(data.item.id)"
            />
          </template>
        </b-table>
      </filter-slot>
    </div>
    <ConfirmationModal
      v-if="showConfirmationModal"
      :paymentOrder="paymentOrder"
      @onClose="closeConfirmationModal"
      @reload="refreshTable()"
      :type="'to-pay'"
    ></ConfirmationModal>
    <pif-modal
      v-if="pifModal"
      :account_id="account_id"
      :payment_order_id="paymentOrder"
      :tif_data="tifdata"
      :creditor="creditor_name"
      :client="client_name"
      :advisor="advisor_name"
      :type="method_payment_code"
      :is-tab-pay="true"
      :type-button="type_button"
      @close="closePifModal"
      @reload="
        refreshTable();
        pay();
      "
    />
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="event_id"
      @closing="showSettlementOffer = false"
    />
    <settlement-tracking-modal
      :show="settlementTrackingModal"
      :offer="trackingOfferId"
      @closing="closeSettlementTrackingModal"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
import filterSettlement from "@/views/debt-solution/views/accounts-actions/filters/settlement";
import ConfirmationModal from "@/views/debt-solution/views/settlements/components/ConfirmModal.vue";
import PifModal from "@/views/debt-solution/views/settlements-made/components/subcomponents/AddPifModal.vue";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import SettlementTrackingModal from "@/views/debt-solution/views/settlement-offer/SettlementTrackingModal.vue";
export default {
  components: {
    ConfirmationModal,
    PifModal,
    SettlementOfferModal,
    SettlementTrackingModal,
  },
  props: {},
  data() {
    return {
      filters: filterSettlement,
      Fields: [
        {
          key: "client",
          label: "Client Name",
          sortable: false,
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "detail",
          label: "Detail",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "advisor_name",
          label: "Negotiator",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "offer_amount",
          label: "Offer Amount",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payment_method",
          label: "Payment Method",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payment_type",
          label: "Payment Type",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "bank_name",
          label: "Payment Account",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payment_date",
          label: "Payment  Date",
          sortable: true,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payment_order",
          label: "Payment  Order Created",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "offer_tracking",
          label: "Offer Tracking",
          sortable: false,
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      paymentOrder: {},
      showConfirmationModal: false,
      pifModal: false,
      account_id: null,
      creditor_name: "",
      client_name: "",
      advisor_name: "",
      tifdata: "",
      method_payment_code: "",
      type_button: 1,
      offerModalInfo: {},
      showSettlementOffer: false,
      event_id: null,
      settlementTrackingModal: false,
      trackingOfferId: null,
      editPaymentMethodId: null,
      editPaymentMethod: false,
      editPaymentMethodOfferId : null,
      paymentMethodOptions: [{ id: 2, name: 'CHECK (OVERNIGHT)' }, { id: 1, name: 'E-CHECK (CHECKING ACCOUNT)'} ],
      sortBy: "payment_date",
      sortDesc: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  mounted() {
    this.toggleModal("migration-modal");
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async myProvider() {   
      this.isBusy = true;
      this.addPreloader();
      try {
        const params = {
          field: this.FiltersForSlot.filterPrincipal.model,
          user_id: this.currentUser.user_id,
          advisor_id:
            this.isCeo || this.isSupervisor ? null : this.currentUser.user_id,
          status: this.filters[3].model,
          result: this.filters[4].model,
          process: this.filters[5].model,
          per_page: this.FiltersForSlot.paginate.perPage,
          actual_page: this.FiltersForSlot.paginate.currentPage,
          from: this.filters[0].model,
          to: this.filters[1].model,
          type: 2,
          sortBy: this.sortBy,
          sortOrder: this.sortDesc?'asc':'desc',
        };
        const res = await AccountsActionsService.searchOfferByStatus(params);
        this.FiltersForSlot.totalRows = res.total || 0;
        this.FiltersForSlot.toPage = res.to || 0;
        this.FiltersForSlot.startPage = res.from || 0;
        this.FiltersForSlot.paginate.currentPage = res.current_page;
        this.isBusy = false;

        return res.data || [];
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    async editStatus(paymentOrder, type) {
      const moreVals = await this.generatePaymentOrder(paymentOrder);
      this.paymentOrder = { ...paymentOrder, type, ...moreVals };
      this.showConfirmationModal = true;
    },
    async generatePaymentOrder(paymentOrder) {
      try {
        this.addPreloader();
        const vals = await AccountsActionsService.generatePaymentOrder({
          offer_id: paymentOrder.id,
        });
        if (vals.length > 0) {
          this.refreshTable();
          return vals[0];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    closeConfirmationModal(update = false) {
      this.showConfirmationModal = false;
      if (update == true) {
        this.$refs.migrateTable.refresh();
      }
    },
    refreshTable() {
      this.$refs.migrateTable.refresh();
    },
    enablePifUpload({ tif, id_status }) {
      const existsPif = tif != "" && !!tif;
      return (id_status === 4 || id_status === 5) && !existsPif;
    },
    validateIcon(id_status) {
      return id_status == 5 ? "Finalize" : "Upload PIF";
    },
    async openPifModal(
      {
        offer_payment_order_id,
        account_id,
        data_tif,
        creditor_name,
        client_name,
        advisor_name,
        method_payment_code,
      },
      type
    ) {
      this.pifModal = true;
      this.creditor_name = creditor_name;
      this.client_name = client_name;
      this.advisor_name = advisor_name;
      this.paymentOrder = offer_payment_order_id;
      this.account_id = account_id;
      this.tifdata = data_tif;
      this.method_payment_code = method_payment_code;
      this.type_button = type;
    },
    closePifModal() {
      this.pifModal = false;
      this.client_name = null;
      this.creditor_name = null;
      this.advisor_name = null;
      this.paymentOrder = null;
      this.account_id = null;
      this.tifdata = null;
      this.method_payment_code = null;
    },
    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.client_name;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorAccount = item.creditor_account;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = item.balance;
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.account_name = item.account;
      this.offerModalInfo.account = item.account_id;
      this.event_id = item.event_id;
      this.offerModalInfo.account = item.account_id;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.showSettlementOffer = true;
    },
    async openSettlementTrackingModal(id) {
      this.trackingOfferId = id;
      this.settlementTrackingModal = true;
    },
    closeSettlementTrackingModal() {
      this.settlementTrackingModal = false;
    },
    pay() {
      this.$emit("pay");
    },
    async changePaymentMethod(item) {
      const result = await this.showConfirmSwal();
      if(!result.isConfirmed){
        await this.clearEditPaymentMethod();
        return;
      } 
      try {
        this.addPreloader();
        const params={
          offer_id: item.id,
          payment_method_id: this.editPaymentMethodId
        }
        await AccountsActionsService.changePaymentMethod(params); 
        this.$set(item, "payment_method", this.paymentMethodOptions.find(option => option.id == this.editPaymentMethodId).name );
        this.$set(item, "method_payment_code", this.editPaymentMethodId);
      } catch (error) {
        console.error(error);
      } finally {
        this.clearEditPaymentMethod();
        this.removePreloader();
      } 
    },
    async clearEditPaymentMethod(){ 
      this.editPaymentMethod = false;
      this.editPaymentMethodOfferId = null;
      this.editPaymentMethodId = null;
    }
  }, 
};
</script>

<style lang="scss" scoped>
  .modal-migrations-pending {
    .v-select-custom { 
      min-width: 270px;
    }
  }
</style>