<template>
  <b-modal
    no-close-on-backdrop
    ref="modal-pif"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="
      (isTabMade || isTabCompleted) && isValid
        ? 'Finalize Process'
        : 'Upload Pif'
    "
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <validation-observer ref="form">
      <b-form-group label="Upload File Pif" v-if="isTabPay && typeButton == 1">
        <div v-if="!tif_data">
          <validation-provider rules="required" v-slot="{ errors }">
            <b-form-file
              ref="file"
              v-model="fileToUpload"
              @input="fileFill"
              placeholder="Upload File"
              accept=".pdf"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </b-form-group>
      <b-form-group label="Upload File Check" v-if="typeButton == 2">
        <div>
          <validation-provider rules="required" v-slot="{ errors }">
            <drag-and-drop
              label-for="id-document"
              v-model="fileToUploadCheck"
              :files-array="fileToUploadCheck"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <div></div>
          <b-button variant="primary" @click="saveFile" class="mt-1">{{
            isValid ? "Finalize process " : "Refer to supervisor"
          }}</b-button>
        </div>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>
<script>
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import filesMixin from "@/views/debt-solution/views/settlements-made/mixins/filesMixin";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    DragAndDrop,
  },
  props: {
    account_id: {
      type: String,
    },
    payment_order_id: {
      type: Number,
    },
    tif_data: {
      type: String,
    },
    client: {
      type: String,
    },
    creditor: {
      type: String,
    },
    advisor: {
      type: String,
    },
    type: {
      type: Number,
    },
    isTabPay: {
      type: Boolean,
    },
    typeButton: {
      type: Number,
    },
    creditor_id: {
      type: Number,
      default: null
    }
  },
  mixins: [filesMixin],
  data() {
    return {
      form: {
        file: "",
        namedoc: "",
        account: "",
        size: "",
        payment_order_id: "",
        motiveId: "",
        finished: false,
        created_by: "",
        creditor: "",
        client: "",
        advisor: "",
        check_cashing_date: null,
      },
      fileToUpload: [],
      offerPaymentOrderMotives: [],
      collectionDate: null,
      fileToUploadCheck: [],
    };
  },
  methods: {
    async getOfferPaymentOrderMotives() {
      const data = await SettingsService.getOfferPaymentOrderMotives(2);
      if (data.status === 200) {
        this.offerPaymentOrderMotives = data.data;
        if (this.offerPaymentOrderMotives.length > 0) {
          this.motive = this.offerPaymentOrderMotives[0];
        }
        if (this.offerPaymentOrderMotives.length === 0) {
          this.offerPaymentOrderMotives = [];
          this.motive = null;
          this.motiveSelected = {
            id: null,
            motive: "",
          };
        }
      }
    },

    closeModal() {
      this.$emit("close");
    },
    async fileFill() {
      const file = this.fileToUpload.type;

      if (file != "application/pdf") {
        this.showInfoSwal("Only Pdf Files Allowed", "Warning");
      } else {
        this.form.namedoc = this.fileToUpload.name;
        this.form.size = this.fileToUpload.size;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileToUpload);
        reader.onload = async (file) => {
          this.form.file = file.target.result;
        };

        if (this.isCeo || this.isSupervisor) {
          this.form.finished = true;
        }
      }
    },
    async saveFile() {
      try {
        if (this.isCeo || this.isSupervisor) {
          this.form.finished = true;
          this.form.payment_order_id = this.payment_order_id;
          this.form.created_by = this.currentUser.user_id;
        } else {
          (this.form.client = this.client),
            (this.form.creditor = this.creditor);
          this.form.advisor = this.advisor;
        }
        this.form.account = this.account_id;
        this.form.payment_order_id = this.payment_order_id;
        this.form.created_by = this.currentUser.user_id;
        this.form.creditor_id = this.creditor_id;

        const result = await this.$refs.form.validate();
        if (result) {
          this.addPreloader();
          const formData = new FormData();
          if (this.fileToUploadCheck.length > 0) {
            this.fileToUploadCheck.forEach((file) => {
              formData.append("fileCheck[]", file, file.name);
            });
          } else {
            formData.append("fileCheck", "");
          }

          formData.append("form", JSON.stringify(this.form));
          if (this.typeButton == 1) {
            formData.append("isTabPay", true);
          }

          const data = await SettlementsMadeService.saveTifFile(formData);
          if (data.status == 200) {
            const fileName = "Settlement Complete -" + this.account_id + ".pdf";
            this.showSuccessSwal();
            this.$emit("reload", this.form.payment_order_id);
            this.closeModal();
            this.form.payment_order_id = this.payment_order_id;
            this.form.created_by = this.currentUser.user_id;
            if (
              (this.isCeo || this.isSupervisor || this.isChief) &&
              this.type == 1
            ) {
              await this.forceFileDownloadPdfWithDownload(data.data, fileName);
            }
          } else {
            (this.form.client = this.client),
              (this.form.creditor = this.creditor);
            this.form.advisor = this.advisor;
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
  async created() {
    await this.getOfferPaymentOrderMotives();
  },

  mounted() {
    this.toggleModal("modal-pif");
  },
  computed: {
    isValid() {
      return this.isSupervisor || this.isCeo;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isTabMade() {
      return this.$route.meta.tab == 2;
    },
    isTabCompleted() {
      return this.$route.meta.tab == 3;
    },
    isAdvisor() {
      return this.currentUser.role_id === 3;
    },
  },
};
</script>
