<template>
  <b-modal
    v-model="show"
    :title="paymentOrder.type == 'confirm' ? 'Confirm Debit' : 'Reject Debit'"
    title-tag="h3"
    hide-footer
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <div class="sticky-top">
      <b-row>
        <b-col sm="6">
          <h5>Client</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            <router-link
              :class="[textLink]"
              :to="{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: paymentOrder.client_account_id,
                },
              }"
              target="_blank"
            >
              <div class="d-flex justify-content-center align-items-center">
                <span>
                  {{ paymentOrder.client || paymentOrder.client_name }}
                  ({{ paymentOrder.account }})
                </span>
              </div>
            </router-link>
          </p>
        </b-col>
        <b-col sm="6">
          <h5>Creditor</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            {{ paymentOrder.creditor || paymentOrder.creditor_name }}
            <feather-icon
              v-b-tooltip.hover.auto="'Offer Detail'"
              icon="EyeIcon"
              class="cursor-pointer text-warning"
              style="margin-bottom: 3px"
              size="20"
              @click="$emit('openDetail', paymentOrder)"
            />
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <h5>Bank Account</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            {{
              paymentOrder.account_number ? paymentOrder.account_number : "-"
            }}
          </p>
        </b-col>
        <b-col sm="6">
          <h5>Amount</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            $
            {{
              (paymentOrder.amount || paymentOrder.payment_fractions_amount)
                | currency
            }}
          </p>
        </b-col>
      </b-row>
    </div>
    <validation-observer ref="formConfirmDebit">
      <b-form>
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="my-2"
            v-if="paymentOrder.type == 'confirm'"
          >
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-group
                label="Check Number"
                label-for="operation-number"
              >
                <b-form-input
                  v-model="form.operationNumber"
                  :class="errors[0] ? 'border-danger' : ''"
                  class="text-uppercase"
                  id="operation-number"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="6"
            md="6"
            class="mt-2"
            v-if="paymentOrder.type == 'confirm'"
          >
            <validation-provider rules="required" v-slot="{ errors }">
              <label>Payment Date:</label>
              <div :class="errors[0] ? 'error-date' : ''">
                <b-form-datepicker
                  v-model="form.operationDate"
                  placeholder="Enter date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :class="errors[0] ? 'border-danger' : null"
                >
                </b-form-datepicker>
              </div>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="12"
            class="mt-2"
            v-if="paymentOrder.type == 'reject'"
          >
            <b-form-group label="Motives">
              <v-select
                v-model="form.motiveId"
                :options="offerPaymentOrderMotives"
                :clearable="false"
                label="motive"
                :reduce="(val) => val.id"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              type == 'to-pay' && method == 2 && paymentOrder.type == 'confirm'
            "
          >
            <b-form-group label="Upload File Check" v-if="true">
              <div>
                <validation-provider rules="required" v-slot="{ errors }">
                  <drag-and-drop
                    label-for="id-document"
                    v-model="fileToUploadCheck"
                    :files-array="fileToUploadCheck"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </b-form-group>
          </b-col>

          <!-- save button -->
          <b-col cols="12" md="12" class="my-2 text-right">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="onSubmit"
            >
              Confirm
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";

import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";

/* services */
import SettlementService from "@/views/debt-solution/views/settlements/services/settlement.service";
import SettingsService from "@/views/commons/components/settings/services/Settings.service";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";

// import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  props: {
    type: {
      type: String,
      default: "Pending",
    },
    paymentOrder: {
      type: Object,
      required: true,
    },
  },
  components: {
    flatPickr,
    vSelect,
    DragAndDrop,
  },
  directives: { Ripple },
  data() {
    return {
      show: true,
      offerPaymentOrderMotives: [],
      form: {
        id:
          this.type == "to-pay"
            ? this.paymentOrder.offer_payment_order_id
            : this.paymentOrder.id,
        client:
          this.type == "to-pay"
            ? this.paymentOrder.client_name
            : this.paymentOrder.client,
        creditor:
          this.type == "to-pay"
            ? this.paymentOrder.creditor_name
            : this.paymentOrder.creditor,
        userId: "",
        roleId: "",
        idUserCreate: "",
        status: "",
        operationDate: "",
        operationNumber: this.paymentOrder.pre_operation_number,
        motiveId: "",
        id_offer: "",
      },
      status: [],
      configFlatPickr: {
        altInput: false,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        locale: "en",
        minDate: "2023-02-10",
        maxDate: moment().format("YYYY-MM-DD"),
      },
      collectionDate: null,
      fileToUploadCheck: [],
      method: this.paymentOrder.method_payment_code,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    S_COUNTER_UNFINISHED: {
      get() {
        return this.$store.state["DebtSolutionSettlementOfferStore"]
          .S_COUNTER_UNFINISHED;
      },
      set(value) {
        this.$store.commit(
          "DebtSolutionSettlementOfferStore/M_COUNTER_UNFINISHED",
          value
        );
      },
    },
  },

  methods: {
    ...mapActions({
      GET_PAYMENT_STATUS_TOTAL:
        "DebtSolutionSettlementsMadeStore/A_GET_PAYMENT_STATUS_TOTAL",
    }),
    async onSubmit() {
      const result = await this.$refs.formConfirmDebit.validate();
      if (result) {
        if (this.paymentOrder.type == "confirm") {
          this.confirmedDebit();
        } else {
          this.rejectedDebit();
        }
      }
    },

    async getOfferPaymentOrderMotives() {
      const data = await SettingsService.getOfferPaymentOrderMotives(1);
      if (data.status === 200) {
        this.offerPaymentOrderMotives = data.data;
        if (this.offerPaymentOrderMotives.length > 0) {
          this.motive = this.offerPaymentOrderMotives[0];
        }
        if (this.offerPaymentOrderMotives.length === 0) {
          this.offerPaymentOrderMotives = [];
          this.motive = null;
          this.motiveSelected = {
            id: null,
            motive: "",
          };
        }
      }
    },

    async confirmedDebit() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const formData = new FormData();
          this.form.userId = this.currentUser.user_id;
          this.form.roleId = this.currentUser.role_id;
          if (this.fileToUploadCheck.length > 0) {
            this.fileToUploadCheck.forEach((file) => {
              formData.append("fileCheck[]", file, file.name);
            });
          } else {
            formData.append("fileCheck", "");
          }
          formData.append("form", JSON.stringify(this.form));
          const { data } = await SettlementService.confirmDebit(formData);
          if (data.type == "success") {
            this.showSuccessSwal(data.message);
            this.close(true);
            this.$emit("reload");
          } else {
            this.showErrorSwal("", data.message);
          }
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async rejectedDebit() {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          this.form.userId = this.currentUser.user_id;
          this.form.roleId = this.currentUser.role_id;
          this.form.id_offer = this.paymentOrder.id;

          const { data } = await SettlementService.rejectedDebit(this.form);
          if (data.type == "success") {
            this.S_COUNTER_UNFINISHED = this.S_COUNTER_UNFINISHED + 1;
            this.showSuccessSwal(data.message);
            this.close(true);
            this.$emit("reload");
          } else {
            this.showErrorSwal("", data.message);
          }
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getStatus() {
      try {
        this.addPreloader();
        this.status = [];
        const { data, status } = await SettlementService.getStatusOrdersPayment(
          {}
        );
        if (status == 200) {
          this.status.push(data[2]);
          this.status.push(data[3]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    close(update = false) {
      this.$emit("onClose", update);
    },
    handleSubmit() {
      //   this.$emit("onSubmit");
    },
  },
  async created() {
    await this.getStatus();
    await this.getOfferPaymentOrderMotives();
  },
  mounted() {},
};
</script>
  
  <style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.error-date > * {
  border: 1px solid #fc424a !important;
}

.py-1s {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
  