var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-view-documents-case",attrs:{"title":_vm.onlyShow ? 'Deposit Details' : 'Deposit',"title-class":"h3 text-white font-weight-bolder","size":"md","modal-class":"modal-primary","no-close-on-backdrop":"","hide-footer":_vm.onlyShow ? true : false},on:{"hidden":_vm.close},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.sendRequestDeposit}},[_vm._v("DONE")])]},proxy:true}]),model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"sticky-top"},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('h5',[_vm._v("Client")]),_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s"},[_vm._v(" "+_vm._s(_vm.client)+" ")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('h5',[_vm._v("Payment Account")]),_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s"},[_vm._v(" "+_vm._s(_vm.paymentAccount)+" ")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('h5',[_vm._v("Amount")]),_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s"},[_vm._v(" "+_vm._s(_vm._f("currencyZero")(_vm.amount))+" ")])])],1)],1),_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"pt-2 mb-2"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Check Number"}},[_c('validation-provider',{attrs:{"name":"check","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########################'),expression:"'#########################'"}],class:errors[0] ? 'border-danger' : null,attrs:{"disabled":_vm.onlyShow ? true : false},model:{value:(_vm.transferNum),callback:function ($$v) {_vm.transferNum=$$v},expression:"transferNum"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Amount"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required|greated-than-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger' : null,attrs:{"disabled":_vm.onlyShow ? true : false},model:{value:(_vm.transferAmount),callback:function ($$v) {_vm.transferAmount=$$v},expression:"transferAmount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                  min: 0.0,
                  maxlength: 11,
                },false))]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',{class:errors[0] ? 'border-danger' : null,attrs:{"placeholder":"Enter date","date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                },"disabled":_vm.onlyShow ? true : false},model:{value:(_vm.transferDate),callback:function ($$v) {_vm.transferDate=$$v},expression:"transferDate"}})]}}])})],1)],1)],1),_c('div',[(_vm.onlyShow)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('h5',[_vm._v("Updated By")]),_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s"},[_vm._v(" "+_vm._s(_vm.onlyShow.transfer_updated_by)+" | "+_vm._s(_vm._f("myGlobalDay")(_vm.onlyShow.transfer_updated_at))+" ")])])],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }