<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="tabFilters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
      @reset-all-filters="refreshTable()"
    >
      <!-- BUTTON CHANGE ALL OR BASIC COLUMNS -->
      <template #buttons>
        <div class="ml-2">
          <b-button type="button" variant="primary" @click="allColumns(true)">
            <tabler-icon
              title="SL File"
              :icon="!showAllColumns ? 'Tallymark4Icon' : 'Tallymark2Icon'"
              size="13"
              style="margin-right: 0.5 rem"
              class="align-middle cursor-pointer"
            />
            {{ !showAllColumns ? "All columns" : "Basic columns" }}
          </b-button>
        </div>
      </template>
      <!-- END BUTTON CHANGE ALL OR BASIC COLUMNS -->
      <template #table>
        <b-table
          ref="refSettlementsTable"
          id="offer_payment_order_id"
          slot="table"
          :items="SettlementsMadeProvider"
          small
          :fields="tabFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="d-flex align-items-center justify-content-between">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'debt-solution-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
              <feather-icon
                v-if="
                  (data.item.payment_mode === 'MAIL' &&
                    data.item.remaining_days <= 7) ||
                  ((data.item.payment_mode === 'BY PHONE' ||
                    data.item.payment_mode === 'ONLINE') &&
                    data.item.myssing_days <= 7)
                "
                v-b-tooltip.hover.html.right="
                  getTooltip(
                    data.item.remaining_days,
                    data.item.myssing_days,
                    data.item.shipping_days,
                    data.item.payment_mode
                  )
                "
                :icon="
                  hasWarningDiff(
                    data.item.remaining_days,
                    data.item.payment_mode,
                    data.item.myssing_days
                  )
                    ? 'AlertCircleIcon'
                    : hasDangerDiff(
                        data.item.remaining_days,
                        data.item.payment_mode,
                        data.item.myssing_days
                      )
                    ? 'AlertTriangleIcon'
                    : ''
                "
                size="18"
                class="cursor-pointer"
                :class="
                  hasWarningDiff(
                    data.item.remaining_days,
                    data.item.payment_mode,
                    data.item.myssing_days
                  )
                    ? 'text-warning'
                    : hasDangerDiff(
                        data.item.remaining_days,
                        data.item.payment_mode,
                        data.item.myssing_days
                      )
                    ? 'text-danger'
                    : ''
                "
              />
            </div>
            <span style="font-weight: 100">{{ data.item.account }} </span>
          </template>
          <template #cell(creditor_name)="data">
            <span>
              <strong
                class="cursor-pointer"
                :class="[textLink]"
                @click="editCreditorAccount(data.item.creditor_id)"
                >{{ data.item.creditor }}</strong
              >
              <span
                class="mb-1"
                v-if="data.item.parents != data.item.creditor_name"
              >
                <br />
                ({{ data.item.parents }})
              </span>
              <br />
              <span>#{{ data.item.creditor_account }}</span>
            </span>
          </template>
          <template #cell(collection)="data">
            <span>
              <strong>{{ data.item.collection }}</strong>
              <br />
              <span v-if="data.item.collection_account"
                >#{{ data.item.collection_account }}</span
              >
            </span>
          </template>
          <template #cell(balance)="data">
            <span> $ {{ data.value | currencyZero }}</span>
          </template>
          <template #cell(client_balance)="data">
            <span :class="data.value > 0 ? 'text-primary' : 'text-danger'">
              $ {{ data.value | currencyZero }}
            </span>
          </template>
          <template #cell(offer_amount)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>
          <template #cell(offer_percent)="data">
            % {{ getPercentage(data.item.balance, data.item.offer_amount) }}
          </template>
          <template #cell(saving)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>
          <template #cell(profit)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>
          <template #cell(payment_date)="data">
            <span>{{ data.value | myGlobal }}</span>
          </template>

          <template #cell(created_at)="data">
            {{ data.value | myGlobal }}
          </template>
          <template #cell(orden_aproved_at)="data">
            <b-row>
              <b-col>{{ data.item.order_payment_created_by }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ data.value | myGlobalWithHour }}</b-col>
            </b-row>
          </template>
          <template #cell(result)="data">
            <b-badge class="w-100" :variant="colorizeResult(data.value)" pill>{{
              data.value
            }}</b-badge> </template
          ><template #cell(process)="data">
            <b-badge
              class="w-100"
              :variant="colorizeProcess(data.value)"
              pill
              >{{ data.value }}</b-badge
            >
          </template>
          <template #cell(status)="data">
            <feather-icon
              v-if="data.item.status === 'PENDING'"
              @click="openMessageDetail(data.item)"
              v-b-tooltip.hover.left="'PENDING'"
              icon="ClockIcon"
              size="20"
              class="text-info"
              :class="
                data.item.last_comment ? 'cursor-pointer' : 'cursor-default'
              "
            />
            <feather-icon
              v-if="data.item.status === 'REJECTED'"
              v-b-tooltip.hover.left="'REJECTED'"
              icon="XCircleIcon"
              size="20"
              class="cursor-pointer text-danger"
            />
            <feather-icon
              v-if="data.item.status === 'APPROVED'"
              v-b-tooltip.hover.left="'APPROVED'"
              icon="CheckCircleIcon"
              size="20"
              class="cursor-pointer text-success"
            />
          </template>
          <template #cell(created_by)="data">
            <span>
              {{ data.item.created_by }}
            </span>
            <br />
            <span>
              <small>
                {{ data.item.created_at | myGlobal }}
              </small>
            </span>
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              size="20"
              class="text-primary cursor-pointer"
              v-b-tooltip.hover.left="'SEE OFFER TRACKING'"
              @click="openTrackingOfferModal(data.item.id)"
              icon="ListIcon"
            />
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="isCeo"
                size="22"
                icon="CheckCircleIcon"
                class="text-info cursor-pointer mr-1"
                @click="openResumeModal(data.item)"
                v-b-tooltip.hover.left="'APPROVE OR REJECT OFFER'"
              />
              <feather-icon
                size="22"
                icon="EyeIcon"
                class="text-warning cursor-pointer"
                @click="openOfferModal(data.item)"
                v-b-tooltip.hover.left="'SEE OFFER SUMMARY'"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <b-modal
      id="modalSettlementOffer"
      :title="`Comment on the offer by ${offerModalInfo.client_name}`"
      title-class="h5 text-white font-weight-bolder"
      size="lg"
      centered
      v-if="detailModal"
      v-model="detailModal"
      @hidden="detailModal = false"
    >
      <div>
        {{ offerModalInfo.last_comment.comment }}
      </div>
      <div class="text-right text-muted mt-2">
        {{ offerModalInfo.last_comment.user_name }} |
        {{ offerModalInfo.last_comment.created_at | myGlobalWithHour }}
      </div>
      <template #modal-footer>
        <b-button variant="outline-secondary" @click="detailModal = false">
          Close
        </b-button>
      </template>
    </b-modal>
    <settlement-resume-modal
      :show="showResumeModal"
      :info="resumeModalInfo"
      @reload="refreshTable()"
      @closing="closeResumeModal"
    />
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="event_id"
      @reload="refreshTable()"
      @closing="showSettlementOffer = false"
    />
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :id-creditor-to-pass="idCreditorToPass"
      :state-modal="stateModal"
      @close="addCreditorModalController = false"
    />
    <offer-tracking-modal
      v-if="showModalTrackingOffer"
      @close="showModalTrackingOffer = false"
      :offer="trackingOfferId"
    />
  </div>
</template>
<script>
import debtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import filters from "@/views/debt-solution/views/settlements/data/filters-to-be-approved.data.js";
import fields from "@/views/debt-solution/views/settlements/data/fields-to-be-approved.data.js";
import filesMixin from "@/views/debt-solution/views/settlements-made/mixins/filesMixin";
import SettlementResumeModal from "@/views/management/views/approval-process/settlement-approval/components/ResumedOfferModal.vue";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import OfferTrackingModal from "@/views/commons/components/clients/dashboard/information-client/time-line/modals/trackings/OfferTrackingModal.vue";

export default {
  components: {
    SettlementResumeModal,
    SettlementOfferModal,
    AddCreditorDsModal,
    OfferTrackingModal,
  },
  mixins: [filesMixin],
  data() {
    return {
      showModalTrackingOffer: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: "",
      sortDesc: true,
      fields,
      filters,
      isBusy: false,
      offerPaymentOrderModalIsOpen: false,
      offerPaymentOrderId: null,
      offerData: {
        offer_id: null,
        offer_payment_fraction_id: null,
        payment_amount: null,
        user_id: null,
        bank_account_type: null,
        payment_method: null,
        offer_payment_order_id: null,
      },
      paidReasonsObservationsOpened: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      items: [],
      haveoffers: false,
      trackingOfferId: null,
      settlementTrackingModal: false,
      settlementsMadeMadeTrackingModal: false,
      pifModal: false,
      paymentOrder: null,
      account_id: null,
      tifdata: null,
      creditor_name: null,
      client_name: null,
      advisor_name: null,
      check: false,
      checkData: {},
      clientCreditorCheck: {
        client_name: null,
        creditor_name: null,
      },
      checkGeneratedStatus: false,
      binnacleModal: false,
      order_id: null,
      showAllColumns: false,
      dataCheck: {},
      itemBinacle: {},
      addCreditorModalController: false,
      stateModal: false,
      idCreditorToPass: null,
      showSettlementOffer: false,
      offerModalInfo: {},
      showResumeModal: false,
      resumeModalInfo: {},
      event_id: null,
      detailModal: false,
    };
  },
  async mounted() {
    await this.getUsersAdvisor();
  },
  created() {},

  methods: {
    openMessageDetail(item) {
      this.offerModalInfo = item;
      item.last_comment = JSON.parse(item.last_comment);
      let comment = item.last_comment.comment.split(":");
      if (comment.length > 1) {
        item.last_comment.comment = comment[1];
      }
      this.offerModalInfo = item;
      this.detailModal = true;
    },
    openTrackingOfferModal(offer_id) {
      this.showModalTrackingOffer = true;
      this.trackingOfferId = offer_id;
    },
    closeTrackingOfferModal() {
      this.showModalTrackingOffer = false;
      this.trackingOfferId = null;
    },
    getTooltip(remaining_days, missing_days, shipping_days, payment_mode) {
      return `
      <strong>Missing Days: </strong> ${missing_days} <br> 
      <strong>Shipping Days: </strong> ${shipping_days} <br>
      <strong>Remaining Days: </strong> ${remaining_days} <br>
      `;
    },
    hasWarningDiff(remaining_days, payment_mode, missing_days) {
      if (payment_mode === "MAIL") {
        return remaining_days <= 7 && remaining_days >= 4;
      } else {
        return missing_days <= 7 && missing_days >= 4;
      }
    },

    hasDangerDiff(remaining_days, payment_mode, missing_days) {
      if (payment_mode === "MAIL") {
        return remaining_days < 4;
      } else {
        return missing_days < 4;
      }
    },

    ...mapActions({
      A_COUNT: "DebtSolutionSettlementsMadeStore/A_COUNT_NOT_TIF",
      A_COUNTER_SETTLEMENTS:
        "DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS",
      GET_PAYMENT_STATUS_TOTAL:
        "DebtSolutionSettlementOfferStore/A_GET_PAYMENT_STATUS_TOTAL",
    }),
    ...mapMutations({
      M_UPDATE_TO_BE_APPROVED_GRID:
        "DebtSolutionSettlementOfferStore/M_UPDATE_TO_BE_APPROVED_GRID",
    }),
    colorizeAvailableBalance(client_balance) {
      switch (client_balance) {
        case client_balance > 0:
          return "text-primary";
        case client_balance <= 0:
          return "text-danger";
      }
    },
    colorizeResult(result) {
      switch (result) {
        case "ACCEPT":
          return "light-success";
        case "REJECT":
          return "light-danger";
      }
    },
    colorizeProcess(process) {
      switch (process) {
        case "VERIFICATION":
          return "light-warning";
        case "UNDERVIEW":
          return "light-primary";
        case "REQUEST":
          return "light-secondary";
      }
    },
    openResumeModal(item) {
      this.resumeModalInfo = {
        negotiator: item.negotiator,
        client: item.client_name,
        client_account: item.account,
        creditor: item.creditor,
        creditor_account: item.creditor_account,
        balance: item.balance,
        offer_amount: item.offer_amount,
        saving: item.saving,
        profit: item.profit,
        settlement_cost: item.settlement_cost,
        av_balance: item.client_balance,
        payment_type: item.payment_method,
        payment_format: item.payment_format,
        payment_date: item.payment_date,
        offer_created_at: item.offer_created_at,
        payment_method: item.payment_method2,
        id: item.id,
        creditor_id: item.creditor_id,
        client_account_id: item.client_account_id,
        motive_id: item.motive_id,
        ds_credit_id: item.ds_credit_id,
        collection: item.collection,
        collection_account: item.collection_account,
        last_comment: item.last_comment,
      };
      this.showResumeModal = true;
    },
    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.client_name;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorAccount = item.creditor_account;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = item.balance;
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.account_name = item.account;
      this.showSettlementOffer = true;
      this.event_id = item.event_id;
      this.offerModalInfo.account = item.account_id;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.offerModalInfo.account_number = item.creditor_account;
    },

    async refreshTable() {
      this.A_COUNTER_SETTLEMENTS({
        role_id: this.currentUser.role_id,
        user_id: this.currentUser.user_id,
      });
      this.$refs.refSettlementsTable.refresh();
    },
    async SettlementsMadeProvider(ctx) {
      this.validateFilters();
      try {
        let advisor_id =
          this.currentUser.role_id == 3
            ? this.currentUser.user_id
            : this.filters[9].model;

        const params = {
          name_text: this.filterPrincipal.model,
          created_from: this.filters[0].model,
          created_to: this.filters[1].model,
          approved_from: this.filters[2].model,
          approved_to: this.filters[3].model,
          payment_order_from: this.filters[4].model,
          payment_order_to: this.filters[5].model,
          payment_date_from: this.filters[6].model,
          payment_date_to: this.filters[7].model,
          status: this.filters[8].model,
          advisor_id: advisor_id,
          request_type: this.filters[10].model,
          creditor: this.filters[11].savedValue,
          payment_format: this.filters[12].model,
          status_offer: this.filters[13].model,
          user_id: this.currentUser.user_id,

          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          tab: this.$route.meta.tab,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc == true ? "ASC" : "DESC",
          origin: this.moduleId,
        };
        const data = await SettlementsMadeService.getSettlementsMade(params);
        if (data.status === 200) {
          this.items = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          return this.items || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    validateFilters() {
      const negotiator = this.filters[9];
      if (this.currentUser.role_id == 3) {
        negotiator.visible = false;
      }
    },
    setPhone(recovery) {
      if (!recovery) {
        return "#-";
      }
      return recovery[0].phone == null ? "# -" : `# ${recovery[0].phone}`;
    },
    async getUsersAdvisor() {
      const idModule = this.$route.matched[0].meta.module;
      const { data } = await debtSolutionServices.getAdvisors(idModule);
      this.filters[9].options = data;
    },

    closeResumeModal() {
      this.showResumeModal = false;
    },
    editCreditorAccount(id) {
      this.addCreditorModalController = true;
      this.stateModal = 0;
      this.idCreditorToPass = id;
    },
    allColumns(frombutton = false) {
      //change value when the tab is completed and click from button
      this.showAllColumns = frombutton
        ? !this.showAllColumns
        : this.showAllColumns;
    },
    getPercentage(offer_balance, offer_amount) {
      if (offer_amount == 0) return `${0} %`;
      const res = (Number(offer_amount) / offer_balance) * 100;
      return `${res.toFixed(2)}`;
    },
  },
  computed: {
    tabFields() {
      if (this.showAllColumns) {
        this.fields.map((item) => {
          item.visible = true;
        });
      } else {
        const visibleColumns = [
          "client_name",
          "creditor_name",
          "balance",
          "offer_amount",
          "created_at",
          "process",
          "status",
          "result",
          "actions",
          "payment_date",
          "advisor_name",
        ];
        this.fields.map((row, _) => {
          visibleColumns.includes(row.key)
            ? (row.visible = true)
            : (row.visible = false);
        });
      }
      return this.fields.filter((item) => item.visible === true);
    },
    isCeo() {
      return this.currentUser.role_id === 1;
    },
    isSupervisor() {
      return this.currentUser.role_id === 2;
    },
    isAdvisor() {
      return this.currentUser.role_id === 3;
    },
    tabFilters() {
      return this.filters.filter((item) => item.visible === true);
    },
    creditorFilter() {
      return this.filters[11].model;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_UPDATE_TO_BE_APPROVED_GRID:
        "DebtSolutionSettlementOfferStore/G_UPDATE_TO_BE_APPROVED_GRID",
    }),
  },
  watch: {
    async creditorFilter() {
      const data = await debtSolutionServices.getCreditorsByName(
        this.creditorFilter
      );
      this.filters[11].options = [{ data: [...data.data] }];
    },
    G_UPDATE_TO_BE_APPROVED_GRID(newVal) {
      if (newVal) {
        this.$refs.refSettlementsTable.refresh();
        this.M_UPDATE_TO_BE_APPROVED_GRID(false);
      }
    },
  },
};
</script>
<style scoped></style>
