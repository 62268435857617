export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Advisor',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Balance From',
    model: null,
    cols: 6,
    visible: true,
    key: 'balance_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Balance To',
    model: null,
    cols: 6,
    visible: true,
    key: 'balance_to',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Total Debt From',
    model: null,
    cols: 6,
    visible: true,
    key: 'debt_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Total Debt To',
    model: null,
    cols: 6,
    visible: true,
    key: 'debt_to',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Remaining Balance From',
    model: null,
    cols: 6,
    visible: true,
    key: 'rem_from',
    inputType: 'number',
  },
  {
    type: 'input',
    margin: true,
    showLabel: true,
    label: 'Remaining Balance To',
    model: null,
    cols: 6,
    visible: true,
    key: 'rem_to',
    inputType: 'number',
  },
]
