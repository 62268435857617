<template>
  <b-modal
    v-model="isVisible"
    modal-class="custom-modal-amg"
    size="lg"
    @hidden="handleClose"
    centered
  >
    <template #modal-title>
      <div class="d-flex align-items-center">
        <tabler-icon icon="RocketIcon" class="text-white" size="20" />
        <h2 class="text-white m-0">Do you want to make the payment?</h2>
      </div>
    </template>
    <div class="mt-1">
      <template v-if="dataRow.payment_type != 'AMG'">
        <b-col cols="12" class="p-0 d-flex align-items-center" style="gap: 5px">
          <tabler-icon icon="UserIcon" size="20" /><span
            class="title-main fs-6"
            >Client Info:</span
          >
        </b-col>
        <hr class="border border-primary my-0" />
        <b-container class="mt-1 mb-1">
          <b-row class="custom-row">
            <b-col cols="12" md="6">
              <span class="title-tag">CLIENT / ACCOUNT</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    `${dataRow.client_name} (${dataRow.account})`
                  }}
                </p>
              </div>
            </b-col>
            <!-- <b-col cols="12" md="6">
              <span class="title-tag">ACCOUNT</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.account
                  }}
                </p>
              </div>
            </b-col> -->
            <b-col cols="12" md="6" v-if="doc != null">
              <span class="title-tag">{{ doc }}</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    docNum
                  }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <span class="title-tag">DOB</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.dob | myGlobal
                  }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <span class="title-tag">ADDRESS</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.address
                  }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>

        <b-col cols="12" class="p-0 d-flex align-items-center" style="gap: 5px">
          <tabler-icon icon="BuildingBankIcon" size="20" /><span
            class="title-main fs-6"
            >Bank Detail:</span
          >
        </b-col>
        <hr class="border border-primary my-0" />
        <b-container class="mt-1">
          <b-row class="custom-row">
            <b-col cols="12" md="4">
              <span class="title-tag">BANK</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.bank_account
                      ? JSON.parse(dataRow.bank_account).bank_name || "N/A"
                      : "N/A"
                  }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <span class="title-tag">ROUTING NUMBER</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.bank_account
                      ? `XXXX${JSON.parse(dataRow.bank_account).routing_number.slice(-4)}` || "N/A"
                      : "N/A"
                  }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <span class="title-tag">ACCOUNT NUMBER</span>
              <div class="border border-primary rounded">
                <p
                  class="d-flex align-items-center justify-content-center my-1s"
                  style="gap: 5px"
                >
                  {{
                    dataRow.bank_account
                      ? `XXXX${JSON.parse(dataRow.bank_account).account_number.slice(-4)}` || "N/A"
                      : "N/A"
                  }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-col
        cols="12"
        class="p-0 mt-1 d-flex align-items-center"
        style="gap: 5px"
      >
        <amg-icon icon="MoneyBagIcon" size="20" /><span class="title-main fs-6"
          >Settlement Detail:</span
        >
      </b-col>
      <hr class="border border-primary my-0" />
      <b-container class="mt-1">
        <b-row class="custom-row">
          <b-col cols="12" md="6">
            <span class="title-tag">CREDITOR</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataRow.creditor_name || "N/A" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">ACCOUNT NUMBER</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{
                  dataRow.collection_account == null
                    ? dataRow.creditor_account
                    : dataRow.collection_account
                }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">BALANCE</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataRow.balance | currencyTwo | myMoney }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">OFFER AMOUNT</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataRow.offer_amount | currencyTwo | myMoney }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">PAYMENT METHOD</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataRow.payment_method || "N/A" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span class="title-tag">PAYMENT TYPE</span>
            <div class="border border-primary rounded">
              <p
                class="d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ dataRow.payment_type || "N/A" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <template #modal-footer>
      <div class="d-flex" style="gap: 5px">
        <b-button @click="done" variant="success">Done</b-button>
        <!-- <b-button variant="danger">Cancel</b-button> -->
      </div>
    </template>
  </b-modal>
</template>

<script>
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    dataRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      showDoc: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    doc() {
      if(this.dataRow.ssn != null){
        this.showDoc = true
        return "SSN"
      }else if(this.dataRow.itin != null){
        this.showDoc = true
        return "ITIN"
      }else if(this.dataRow.cpn != null){
        this.showDoc = true
        return "CPN"
      }
    },
    docNum() {
      if(this.dataRow.ssn != null){
        return this.dataRow.ssn
      }else if(this.dataRow.itin != null){
        return this.dataRow.itin
      }else if(this.dataRow.cpn != null){
        return this.dataRow.cpn
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeModal");
    },

    async done() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        await SettlementsMadeService.makePaymentOffer({
          created_by: this.currentUser.user_id,
          check_id: this.dataRow.check_id,
        });
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Offer updated successfully"
        );
        this.handleClose();
        this.$emit("done");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
  created() {
    console.log(this.dataRow);
    this.isVisible = true;
  },
};
</script>

<style scoped>
.title-tag {
  font-weight: bold;
}
.title-main {
  font-size: 1.2em;
}
.my-1s {
  margin-top: 1s; /* Adjust as necessary */
}
.custom-row > div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
