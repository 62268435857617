<template>
  <b-modal
    v-model="onControl"
    title="ACCOUNT TRACKING"
    title-tag="h3"
    modal-class="modal-primary"
    body-class="p-0"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <b-table
      ref="trackingList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="tableProvider"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(section)="data">
        <b-badge
          :variant="colorizeProcess(data.item.section_num)"
          pill
        >{{
          data.item.section.toUpperCase()
        }}</b-badge>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobalWithHour }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import PriorityOffersService from '@/views/debt-solution/views/priority-offers/services/priorityOffersService'

export default {
  props: {
    dsListCreditId: String,
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: 'section',
          label: 'Section',
        },
        {
          key: 'created_by_name',
          label: 'Created By',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
      ],
    }
  },
  created() {
    this.onControl = true
  },
  methods: {
    async tableProvider() {
      const res = await PriorityOffersService.getPendingMadeDsListCreditTracking({
        ds_list_credit_id: this.dsListCreditId,
      })

      return res.data
    },
    close() {
      this.$emit('close')
    },
    colorizeProcess(process) {
      switch (process) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-info'
        default:
          return 'light-secondary'
      }
    },
  },
}
</script>
