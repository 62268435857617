export default [
    {
        key: "client",
        label: "Client",
        visible: true,
    },
    {
        key: "creditor",
        label: "Creditor",
        visible: true,
    },
    {
        key: "av_balance",
        label: "Client Last Balance",
        visible: true,
        sortable: true,
        class: "text-center",
    },
    {
        key: "offer_amount",
        label: "Offer Amount",
        visible: true,
        sortable: true,
    },
    {
        key: "porcentage_offer",
        label: "Porcentage Amount",
        visible: true,
        sortable: false,
    },
    {
        key: "created_by",
        label: "Negotiator",
        visible: true,
    },
    {
        key: "offer_created_at",
        label: "Offer Created",
        visible: true,
    },
    {
        key: "payment_date",
        label: "Payment Date",
        visible: true,
    },
    {
        key: "payment_type",
        label: "Payment Type",
        visible: true,
    },
    {
        key: "process",
        label: "Process",
        visible: true,
    },
    {
        key: "tracking",
        label: "Tracking",
        visible: true,
    },
    {
        key: "rejected_date",
        label: "Rejected By",
        sortable: true,
        visible: true,
    },
    {
        key: "actions",
        label: "Show",
        visible: true,
    }
]