import { amgApi } from '@/service/axios'

class CreateSettlementService {

    async getSettlements(params) {
        try {
            return await amgApi.post(
                'debt-solution/get-settlements',
                params,
            )
        } catch (error) {
            console.log('Error PriorityOffersService: getPriorityOffers')
        }
    }

    async getSettlementsDetails(params) {
        try {
            return await amgApi.post(
                'debt-solution/get-settlements-details',
                params,
            )
        } catch (error) {
            console.log('Error PriorityOffersService: getSettlementsDetails')
        }
    }async getNegotiationsActive(params){
        const data = await amgApi.post("debt-solution/negotiations-active", params);
        return data;
    }


}

export default new CreateSettlementService()
