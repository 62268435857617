<template>
  <b-modal
    v-model="onControl"
    :title="'ACC ' + this.clientName + ' ' + this.client_account"
    modal-class="modal-primary"
    title-tag="h3"
    size="xl"
    scrollable
    hide-footer
    @hidden="close(false)"
  >
    <b-row>
      <b-col>
        <h5>Client</h5>
        <router-link
          v-if="
            isSupervisor || isCeo || currentUser.user_id === this.advisor_id
          "
          v-b-tooltip.hover
          title="Go to Client's Dashboard"
          target="_blank"
          :class="textLink"
          :to="{
            name: 'debt-solution-dashboard',
            params: {
              idClient: this.client_account_id,
            },
          }"
        >
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s account-link"
          >
            {{ this.clientName }}
          </p>
        </router-link>
        <p
          v-else
          class="rounded text-primary border-primary font-medium-1 text-center py-1s account-link-advisor"
        >
          {{ this.clientName }}
        </p>
      </b-col>
      <b-col>
        <h5>Account</h5>
        <p
          class="rounded text-primary border-primary font-medium-1 text-center py-1s"
        >
          {{ this.client_account }}
        </p>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end mr-2">
          <div
            :class="
              this.st_balance == 1
                ? 'av-balance-border-blue'
                : 'av-balance-border-red'
            "
            class="row"
          >
            <div
              :class="
                this.st_balance == 1
                  ? 'av-balance-text-blue'
                  : 'av-balance-text-red'
              "
              class="text-center"
            >
              Available Balance
            </div>
            <h2
              v-if="this.available_balance != null"
              :class="
                this.st_balance == 1
                  ? 'av-balance-content-blue'
                  : 'av-balance-content-red'
              "
            >
              $
              {{
                this.available_balance
                  ? formatPrice(this.available_balance)
                  : 0.0
              }}
            </h2>
            <h2
              v-if="this.available_balance == null"
              :class="
                this.st_balance == 1
                  ? 'av-balance-content-blue'
                  : 'av-balance-content-red'
              "
            >
              $ 0
            </h2>
          </div>
        </div></b-col
      >
    </b-row>
    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refSettlementOffersDetailsTable'].refresh()"
        @reset-all-filters="resetAllFilters"
      >
        <template #table>
          <b-table
            ref="refSettlementOffersDetailsTable"
            no-border-collapse
            class="position-relative"
            :fields="visibleFields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="getSettlementOffersDetails"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client_name)="data">
              <router-link
                v-if="
                  isSupervisor ||
                  isCeo ||
                  currentUser.user_id === data.item.advisor_id
                "
                target="_blank"
                :class="textLink"
                :to="{
                  name: 'debt-solution-dashboard',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                ><span :id="'client' + data.item.id">
                  {{ data.item.client_name }}
                  <br /> </span
              ></router-link>
              <span v-else :id="'client' + data.item.id"
                >{{ data.item.client_name }}<br
              /></span>
              <span>{{ data.item.client_account }}</span>
              <b-tooltip
                :target="'client' + data.item.id"
                triggers="hover"
                placement="left"
              >
                <span>
                  SSN: {{ data.item.lead_ssn }}
                  <br />
                  DOB: {{ data.item.lead_dob }}
                  <br />
                  ADDRESS: {{ data.item.lead_address }}
                </span>
              </b-tooltip>
            </template>
            <template #cell(creditor_name)="data">
              <span :id="'creditor' + data.item.id">
                <span
                  :class="textLink"
                  class="cursor-pointer"
                  @click="editCreditorAccount(data.item.id)"
                  >{{ data.item.creditor_name }}</span
                >
                <br />
                # {{ data.item.creditor_account }}
              </span>
              <b-tooltip
                :target="'creditor' + data.item.id"
                triggers="hover"
                placement="right"
              >
                <span>
                  CONTACT NUMBER:
                  <span />
                </span>
              </b-tooltip>
            </template>
            <template #cell(available_balance)="data">
              <span
                :class="
                  data.item.available_balance > 0
                    ? 'text-primary'
                    : 'text-danger'
                "
                >$ {{ data.item.available_balance | currency }}</span
              >
            </template>
            <template #cell(last_offer_date)="data">
              <span>{{ data.item.last_offer_date | myGlobalWithHour }}</span>
            </template>
            <template #cell(months_behind)="data">
              <span>{{ data.item.months_behind }}</span>
            </template>
            <template #cell(company_percent)="data">
              {{
                data.item.company_percent === "1050.00000"
                  ? "-"
                  : parseFloat(data.item.company_percent).toFixed(2)
              }}
              %
            </template>
            <template #cell(actions)="data">
              <div
                class="w-100 d-flex justify-content-center"
                style="gap: 1rem"
              >
                <feather-icon
                  v-if="data.item.account_phase_id !== 8"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="22"
                  @click="openSettlementModal('create', data.item)"
                />

                <div
                  v-if="data.item.rejected_offers > 0"
                  class="rejected-counter cursor-pointer"
                  @click="openRejectedOffersModal(data.item)"
                >
                  {{ data.item.rejected_offers }}
                </div>
                <!--                <feather-icon-->
                <!--                  class="text-primary cursor-pointer"-->
                <!--                  icon="ListIcon"-->
                <!--                  size="22"-->
                <!--                  @click="openTrackingPendingMadeDsListCreditModal(data.item.id)"-->
                <!--                />-->
              </div>
            </template>
            <template #cell(id_credits_account)="data">
              <div class="d-flex justify-content-center">
                <feather-icon
                  icon="FolderIcon"
                  :class="data.item.cc_files > 0 ? 'text-warning' : ''"
                  size="18"
                  class="cursor-pointer"
                  @click="openModalFiles(data.item)"
                />
              </div>
            </template>
            <template #custom-foot>
              <b-tr>
                <b-td>
                  <span class="font-small-4">Total:</span>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="initialBalance"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="creditorActualBalance"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
                <b-td
                  :class="isDarkSkin ? 'bg-category-modal' : 'bg-secondary'"
                >
                  <b-badge
                    variant="light"
                    :class="isDarkSkin ? 'text-black' : 'text-dark'"
                  >
                    <money
                      class="font-small-3"
                      :value="lastOfferAmount"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      style="border: none"
                      disabled
                    />
                  </b-badge>
                </b-td>
              </b-tr>
            </template>
          </b-table>
        </template>
      </filter-slot>
      <settlement-offer-modal
        v-if="showSettlementModal"
        :offer-info="settlementModalInfo"
        :eventId="event_id"
        @closing="closeSettlementModal"
        @refresh="$refs.refSettlementOffersDetailsTable.refresh()"
      />
      <rejected-offers-modal
        :info="settlementModalInfo"
        :eventId="event_id"
        :show="showRejectedOffersModal"
        @closing="showRejectedOffersModal = false"
      />
      <tracking-pending-made-ds-list-credit-modal
        v-if="trackingPendingMadeDsListCreditModalOn"
        :ds-list-credit-id="dsListCreditForTracking"
        @close="trackingPendingMadeDsListCreditModalOn = false"
      />
      <add-creditor-ds-modal
        v-if="addCreditorModalController"
        :id-creditor-to-pass="idCreditorToPass"
        :state-modal="stateModal"
        @close="addCreditorModalController = false"
      />
      <upload-files-modal
        v-if="uploadFilesModalController"
        :idcreditor="accountToSend.id"
        :idcreditoraccount="accountToSend.id_credits_account"
        :caid="client_account_id"
        @close="uploadFilesModalController = false"
      />
    </div>
  </b-modal>
</template>

<script>
import CreateSettlementsGridDetailsFilters from "@/views/debt-solution/views/settlements/components/create-settlements-grid-details.filters";
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import TrackingPendingMadeDsListCreditModal from "@/views/debt-solution/views/priority-offers/components/modal/TrackingPendingMadeDsListCreditModal.vue";
import RejectedOffersModal from "@/views/debt-solution/views/settlement-offer/RejectedOffersModal.vue";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import { mapGetters } from "vuex";
import CreateSettlementService from "@/views/debt-solution/views/settlements/services/create-settlement.service";
import UploadFilesModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/UploadFilesModal.vue";
import Fields from "@/views/debt-solution/views/settlements/components/creditors.fields.js"

export default {
  name: "CreateSettlementGridModalDetails",
  components: {
    SettlementOfferModal,
    RejectedOffersModal,
    TrackingPendingMadeDsListCreditModal,
    AddCreditorDsModal,
    UploadFilesModal,
  },
  props: {
    clientInformation: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      initialBalance: 0,
      creditorActualBalance: 0,
      lastOfferAmount: 0,
      accountToSend: {},
      uploadFilesModalController: false,
      clientName: "",
      client_account: "",
      available_balance: "",
      client_account_id: "",
      st_balance: false,
      onControl: true,
      fields: Fields,
      filters: CreateSettlementsGridDetailsFilters,
      itemsPriorityOffers: [],
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },

      showSettlementModal: false,
      settlementModalInfo: {
        mode: "",
        client: "",
        creditor: "",
        creditorId: null,
        balance: null,
        percentage: null,
        id: null,
        accountStatusDsNum: null,
        creditorName: null,
      },
      showRejectedOffersModal: false,
      trackingPendingMadeDsListCreditModalOn: false,
      dsListCreditForTracking: "",
      addCreditorModalController: false,
      stateModal: 0,
      idCreditorToPass: null,
      advisor_id: null,
      event_id: null,
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    creditorFilter() {
      return this.filters[3].model;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    itemsPriorityOffers(oldval, newval) {
      if (newval != null) {
        if (this.itemsPriorityOffers.length > 0) {
          this.advisor_id = this.itemsPriorityOffers[0].advisor_id;
        }
      }
    },
    async creditorFilter() {
      await this.getCreditorsByName();
    },
  },
  async created() {
    await this.setUpFiltersData();
  },
  methods: {
    openModalFiles(item) {
      this.uploadFilesModalController = true;
      this.accountToSend = item;
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    close(update = false) {
      this.$emit("close", update);
    },
    async getSettlementOffersDetails(ctx) {
      this.addPreloader();
      const data = await CreateSettlementService.getSettlementsDetails({
        text: this.filterPrincipal.model,
        perPage: this.paginate.perPage,
        nPage: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : "created_at",
        order: ctx.sortDesc ? "desc" : "asc",
        creditor: this.filters[3].savedValue,
        advisor: this.filters[2].model,
        lastOfferResultPending: this.filters[4].model,
        lastOfferResultMade: this.filters[5].model,
        dateFrom: this.filters[0].model,
        dateTo: this.filters[1].model,
        hasBalance: 1,
        type: 1, // type: 1 pending, 2 made
        client_account_id: this.clientInformation.client_account_id,
      });
      this.itemsPriorityOffers = data.data.data;
      this.startPage = data.data.from || 0;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.totalRows = data.data.total || 0;
      this.toPage = data.data.to || 0;
      if (this.itemsPriorityOffers.length > 0) {
        this.clientName = data.data.data[0].client_name;
        this.client_account = data.data.data[0].client_account;
        this.available_balance = data.data.data[0].available_balance;
        this.st_balance = data.data.data[0].st_balance;
        this.client_account_id = data.data.data[0].client_account_id;

        this.initialBalance = data.data.data[0].total_initial_balance;
        this.creditorActualBalance =
          data.data.data[0].total_creditor_actual_balance;
        this.lastOfferAmount = data.data.data[0].total_last_offer_amount;
      } else {
        this.initialBalance = 0;
        this.creditorActualBalance = 0;
        this.lastOfferAmount = 0;
      }
      this.removePreloader();
      return this.itemsPriorityOffers || [];
    },
    async getCreditorsByName() {
      const data = await PriorityOffersService.getCreditorsByName(
        this.creditorFilter
      );
      if (data.status === 200) {
        this.filters[3].options = [{ data: [...data.data] }];
      }
    },
    async setUpFiltersData() {
      const data = await PriorityOffersService.getUsersByModule(
        this.$route.matched[0].meta.module
      );
      if (data.status === 200) {
        this.filters[2].options = data.data;
      }
    },
    openTrackingPendingMadeDsListCreditModal(id) {
      this.trackingPendingMadeDsListCreditModalOn = true;
      this.dsListCreditForTracking = id;
    },
    async openSettlementModal(mode, item, isMigrating = false) {
      const negotiations = await CreateSettlementService.getNegotiationsActive({
        creditor_id: item.id,
      });
      var confirm = null;
      if (negotiations.data[0].negotiations_active > 0) {
        confirm = await this.showConfirmSwal(
          "This creditor has negotiations in process",
          "Do you want to create an offer anyway?"
        );
      }
      if (confirm == null || confirm.isConfirmed == true) {
        this.settlementModalInfo = {};
        this.settlementModalInfo.mode = mode;
        this.settlementModalInfo.migrate = isMigrating;
        this.settlementModalInfo.client = item.client_name;
        this.settlementModalInfo.creditorName = item.creditor_name;
        this.settlementModalInfo.creditorId = item.id;
        this.settlementModalInfo.balance = item.actual_balance;
        this.settlementModalInfo.client_balance =
          this.clientInformation.available_balance;
        this.settlementModalInfo.percentage = item.min_percent;
        this.settlementModalInfo.id = mode == "create" ? null : item.offer_id;
        this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
        this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
        this.settlementModalInfo.creditorContact =
          item.principal_creditor_contact_number;
        this.settlementModalInfo.profitPercent = item.profit_percent;
        this.settlementModalInfo.account_name = item.client_account;
        this.settlementModalInfo.client_account_id = item.client_account_id;
        this.settlementModalInfo.creditor_id = item.creditor_id;
        this.settlementModalInfo.account = item.creditor_account;
        this.settlementModalInfo.accountStatusDsNum =
          item.account_status_ds_num;
        this.settlementModalInfo.account = item.client_account_id;
        this.showSettlementModal = true;
        this.event_id = item.event_id;
        this.settlementModalInfo.id_analysis = item.id_analysis;
        this.settlementModalInfo.clientAccountId = item.client_account_id;
        this.settlementModalInfo.ssn = item.ssn;
        this.settlementModalInfo.itin = item.itin;
        this.settlementModalInfo.cpn = item.cpn;
        this.settlementModalInfo.dob = item.dob;
        this.settlementModalInfo.address = item.address;
        this.settlementModalInfo.account_number = item.creditor_account;
        this.showSettlementModal = true;
      }
    },
    openRejectedOffersModal(item) {
      this.settlementModalInfo.mode = "watch";
      this.settlementModalInfo.client = item.client_name;
      this.settlementModalInfo.creditorName = item.creditor_name;
      this.settlementModalInfo.creditorId = item.id;
      this.settlementModalInfo.balance = item.actual_balance;
      this.settlementModalInfo.percentage = item.min_percent;
      this.settlementModalInfo.id = item.offer_id;
      this.settlementModalInfo.creditorShortId = item.principal_creditor_id;
      this.settlementModalInfo.creditorAgent = item.principal_creditor_agent;
      this.settlementModalInfo.creditorContact =
        item.principal_creditor_contact_number;
      this.event_id = item.event_id;
      this.settlementModalInfo.profitPercent = item.profit_percent;
      this.settlementModalInfo.client_account_id = item.client_account_id;
      this.settlementModalInfo.id_analysis = item.id_analysis;
      this.settlementModalInfo.account = item.client_account_id;
      this.settlementModalInfo.ssn = item.ssn;
      this.settlementModalInfo.itin = item.itin;
      this.settlementModalInfo.cpn = item.cpn;
      this.settlementModalInfo.dob = item.dob;
      this.settlementModalInfo.address = item.address;
      this.settlementModalInfo.account_number = item.creditor_account;

      this.showRejectedOffersModal = true;
    },
    closeSettlementModal() {
      this.showSettlementModal = false;
    },
    editCreditorAccount(id) {
      this.addCreditorModalController = true;
      this.stateModal = 0;
      this.idCreditorToPass = id;
    },
    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
        filter.savedValue = null;
      });
      this.filterPrincipal.model = null;
      this.$refs.refSettlementOffersDetailsTable.refresh();
    },
  },
};
</script>

<style scoped>
.rejected-counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  background: #fc424a1f;
  color: #fc424a;
  border: 1px solid #fc424a;
  border-radius: 50%;
  font-size: 1rem;
}

.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: 245px;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.account-link:hover {
  text-decoration: underline;
}
</style>
