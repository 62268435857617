export default [
  {
    key: 'created_by',
    label: 'User',
    visible: true,
    class: "text-center",
  },
  {
    key: 'commentary',
    label: 'Observation',
    visible: true,
    class: "text-center",
  },
  {
    key: 'created_at',
    label: 'Created at',
    visible: true,
    class: "text-center",
  },
]
