<template>
  <div class="container-indicators">
    <!-- <div
      class="d-flex justify-content-end"
      id="payments_to_be_confirmed"
      style="cursor: pointer"
    >
      <b-tooltip target="payments_to_be_confirmed" triggers="hover" placement="bottom">
        <li style="display: flex; margin-bottom: 0.5rem">
          <tabler-icon
            :icon="`CashIcon`"
            size="19"
            class="align-middle scale"
            style="color: #ff6207; width: 20%"
          />
          <span style="margin-left: 0.5rem"
            >Pending processing due to insufficient funds</span
          >
        </li>
        <li style="display: flex; margin-bottom: 0.5rem">
          <tabler-icon
            :icon="`ClockIcon`"
            size="19"
            class="align-middle scale"
            style="color: #ff0000; width: 20%"
          />
          <span style="margin-left: 0.5rem"
            >Pending processing due to insufficient funds (urgent)</span
          >
        </li>
      </b-tooltip>
      <div
        class="av-balance-border av-balance-border-orange col float-right mr-1"
      >
        <div
          :class="'av-balance-text av-balance-text-orange'"
          class="text-center"
        >
          <span style="background: #793300; border-radius: 5px; padding: 0 8px;font-size:15px"
            >Payments to be Confirmed</span
          >
        </div>
        <h2 :class="'av-balance-content av-balance-content-orange'" style="font-size:15px">
          $
          {{
            indicatorsTotal.payments_to_be_confirmed
              ? formatPrice(indicatorsTotal.payments_to_be_confirmed)
              : 0.0
          }}
        </h2>
      </div>
    </div> -->
    <div
      id="confirmed_payments"
      style="cursor: pointer"
    >
      <b-tooltip
        target="confirmed_payments"
        triggers="hover"
        placement="bottom"
      >
        <ul style="list-style: none; margin-left: 0; padding-left: 0">
          <li style="display: flex; align-items: center; margin-bottom: 0.5rem">
            <tabler-icon
              :icon="`CashIcon`"
              size="19"
              class="align-middle scale"
              style="color: #66b70b"
            />
            <span style="margin-left: 0.5rem">Negotiation completed</span>
          </li>
          <li style="display: flex; align-items: center; margin-bottom: 0.5rem">
            <tabler-icon
              icon="CheckIcon"
              size="19"
              class="align-middle scale"
              style="color: #ffd833"
            />
            <span style="margin-left: 0.5rem">Pending completion</span>
          </li>
          <li style="display: flex; align-items: center">
            <tabler-icon
              icon="ChecksIcon"
              size="19"
              class="align-middle scale"
              style="color: #54b408"
            />
            <span style="margin-left: 0.5rem">Completed</span>
          </li>
        </ul>
      </b-tooltip>
      <div
        class="av-balance-border av-balance-border-green col float-right mr-1"
      >
        <div class="av-balance-text av-balance-text-green text-center">
          <span
            style="
              background: #154a06;
              border-radius: 5px;
              padding: 0 8px;
              font-size: 15px;
            "
            >Confirmed Payments</span
          >
        </div>
        <h2
          class="av-balance-content av-balance-content-green"
          style="font-size: 15px"
        >
          $
          {{
            indicatorsTotal.confirmed_payments
              ? formatPrice(indicatorsTotal.confirmed_payments)
              : 0.0
          }}
        </h2>
      </div>
    </div>
    <div
      id="pending_to_be_paid"
      style="cursor: pointer"
    >
      <b-tooltip
        target="pending_to_be_paid"
        triggers="hover"
        placement="bottom"
      >
        <ul style="list-style: none; margin-left: 0; padding-left: 0">
          <li style="display: flex; align-items: center; margin-bottom: 0.5rem">
            <tabler-icon
              :icon="`CashIcon`"
              size="19"
              class="align-middle scale"
              style="color: #ffd833"
            />
            <span style="margin-left: 0.5rem">Pending debit confirmation</span>
          </li>
           <li style="display: flex; align-items: center; margin-bottom: 0.5rem">
            <tabler-icon
              :icon="`MailIcon`"
              size="19"
              class="align-middle scale"
              style="color: #ffd833"
            />
            <span style="margin-left: 0.5rem">In Correspondence</span>
          </li>
           <li style="display: flex; align-items: center; margin-bottom: 0.5rem">
            <tabler-icon
              :icon="`TruckDeliveryIcon`"
              size="19"
              class="align-middle scale"
              style="color: #ffd833"
            />
            <span style="margin-left: 0.5rem">In Courier</span>
          </li>
        </ul>
      </b-tooltip>
      <div
        class="av-balance-border av-balance-border-yellow col float-right mr-1"
      >
        <div
          :class="'av-balance-text av-balance-text-yellow'"
          class="text-center"
        >
          <span
            style="
              background: #827930;
              border-radius: 5px;
              padding: 0 8px;
              font-size: 15px;
            "
            >Pending to be paid</span
          >
        </div>
        <h2
          :class="'av-balance-content av-balance-content-yellow'"
          style="font-size: 15px"
        >
          $
          {{
            indicatorsTotal.pending_to_be_paid
              ? formatPrice(indicatorsTotal.pending_to_be_paid)
              : 0.0
          }}
        </h2>
      </div>
    </div>
    <div
      id="rejected_payments"
      style="cursor: pointer"
    >
      <b-tooltip target="rejected_payments" triggers="hover" placement="bottom">
        <tabler-icon
          :icon="`CashIcon`"
          size="19"
          class="align-middle scale"
          style="color: #ff0000; width: 20%"
        />
        <span style="margin-left: 0.5rem">Negotiation not completed</span>
      </b-tooltip>

      <div class="av-balance-border av-balance-border-red col float-right mr-1">
        <div class="av-balance-text av-balance-text-red text-center">
          <span
            style="
              background: #530000;
              border-radius: 5px;
              padding: 0 8px;
              font-size: 15px;
            "
            >Rejected Payments</span
          >
        </div>
        <h2
          class="av-balance-content av-balance-content-red"
          style="font-size: 15px"
        >
          $
          {{
            indicatorsTotal.rejected_payments
              ? formatPrice(indicatorsTotal.rejected_payments)
              : 0.0
          }}
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    indicatorsTotal: {},
  },
  data() {
    return {};
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style lang="scss" scoped>
.av-balance-border {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid;
}

.av-balance-text {
  color: rgb(246, 246, 246);
  padding: 3px;
  margin: 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: white;
}

.av-balance-content {
  color: white;
  padding: 3px;
  margin: 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  text-align: center;
  font-weight: bold;
}

/* Estilos por color */
.av-balance-border-green {
  border-color: #009900;
}

.av-balance-text-green {
  background-color: #009900;
}

.av-balance-content-green {
  background-color: #00cc00;
}

.av-balance-border-orange {
  border-color: #ff6600;
}

.av-balance-text-orange {
  background-color: #ff6600;
}

.av-balance-content-orange {
  background-color: #ff9933;
}

.av-balance-border-blue {
  border-color: #0066cc;
}

.av-balance-text-blue {
  background-color: #0066cc;
}

.av-balance-content-blue {
  background-color: #0099ff;
}

.av-balance-border-red {
  border-color: #cc0000;
}

.av-balance-text-red {
  background-color: #cc0000;
}

.av-balance-content-red {
  background-color: #ff0000;
}

.av-balance-border-yellow {
  border-color: #e7b900;
}

.av-balance-text-yellow {
  background-color: #e7b900;
}

.av-balance-content-yellow {
  background-color: #c1c159;
}

.container-indicators {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .container-indicators {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
</style>