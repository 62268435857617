export default [
  {
    key: "client_name",
    label: "CLIENT",
    visible: true,
  },
  {
    key: "creditor",
    label: "CREDITOR",
    visible: true,
  },
  {
    key: "collection",
    label: "ACTUAL CREDITOR",
    visible: true,
  },
  {
    key: "offer_amount",
    label: "OFFER AMOUNT",
    thClass: "text-center",
    tdClass: "text-center pt-1",
    visible: true,
  },
  {
    key: "bank_name",
    label: "PAYMENT ACCOUNT",
    visible: true,
  },
  {
    key: "check_number",
    label: "CHECK NUMBER",
    visible: true,
  },
  {
    key: "check_date",
    label: "DATE",
    thClass: "text-center",
    tdClass: "text-center pt-1",
    visible: true,
  },
  {
    key: "check_amount",
    label: "AMOUNT",
    thClass: "text-right",
    tdClass: "text-right",
    visible: true,
  },
  {
    key: "transfer_updated_by",
    label: "DONE BY",
    thClass: "text-center",
    tdClass: "text-center pt-1",
    visible: true,
  },
];
