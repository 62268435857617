var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":'ACC ' + this.clientName + ' ' + this.client_account,"modal-class":"modal-primary","title-tag":"h3","size":"xl","scrollable":"","hide-footer":""},on:{"hidden":function($event){return _vm.close(false)}},model:{value:(_vm.onControl),callback:function ($$v) {_vm.onControl=$$v},expression:"onControl"}},[_c('b-row',[_c('b-col',[_c('h5',[_vm._v("Client")]),(
          _vm.isSupervisor || _vm.isCeo || _vm.currentUser.user_id === this.advisor_id
        )?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],class:_vm.textLink,attrs:{"title":"Go to Client's Dashboard","target":"_blank","to":{
          name: 'debt-solution-dashboard',
          params: {
            idClient: this.client_account_id,
          },
        }}},[_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s account-link"},[_vm._v(" "+_vm._s(this.clientName)+" ")])]):_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s account-link-advisor"},[_vm._v(" "+_vm._s(this.clientName)+" ")])],1),_c('b-col',[_c('h5',[_vm._v("Account")]),_c('p',{staticClass:"rounded text-primary border-primary font-medium-1 text-center py-1s"},[_vm._v(" "+_vm._s(this.client_account)+" ")])]),_c('b-col',[_c('div',{staticClass:"d-flex justify-content-end mr-2"},[_c('div',{staticClass:"row",class:this.st_balance == 1
              ? 'av-balance-border-blue'
              : 'av-balance-border-red'},[_c('div',{staticClass:"text-center",class:this.st_balance == 1
                ? 'av-balance-text-blue'
                : 'av-balance-text-red'},[_vm._v(" Available Balance ")]),(this.available_balance != null)?_c('h2',{class:this.st_balance == 1
                ? 'av-balance-content-blue'
                : 'av-balance-content-red'},[_vm._v(" $ "+_vm._s(this.available_balance ? _vm.formatPrice(this.available_balance) : 0.0)+" ")]):_vm._e(),(this.available_balance == null)?_c('h2',{class:this.st_balance == 1
                ? 'av-balance-content-blue'
                : 'av-balance-content-red'},[_vm._v(" $ 0 ")]):_vm._e()])])])],1),_c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refSettlementOffersDetailsTable'].refresh()},"reset-all-filters":_vm.resetAllFilters},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSettlementOffersDetailsTable",staticClass:"position-relative",attrs:{"no-border-collapse":"","fields":_vm.visibleFields,"show-empty":"","no-provider-filtering":"","sticky-header":"50vh","primary-key":"id","responsive":"sm","items":_vm.getSettlementOffersDetails,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [(
                _vm.isSupervisor ||
                _vm.isCeo ||
                _vm.currentUser.user_id === data.item.advisor_id
              )?_c('router-link',{class:_vm.textLink,attrs:{"target":"_blank","to":{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.client_account_id,
                },
              }}},[_c('span',{attrs:{"id":'client' + data.item.id}},[_vm._v(" "+_vm._s(data.item.client_name)+" "),_c('br')])]):_c('span',{attrs:{"id":'client' + data.item.id}},[_vm._v(_vm._s(data.item.client_name)),_c('br')]),_c('span',[_vm._v(_vm._s(data.item.client_account))]),_c('b-tooltip',{attrs:{"target":'client' + data.item.id,"triggers":"hover","placement":"left"}},[_c('span',[_vm._v(" SSN: "+_vm._s(data.item.lead_ssn)+" "),_c('br'),_vm._v(" DOB: "+_vm._s(data.item.lead_dob)+" "),_c('br'),_vm._v(" ADDRESS: "+_vm._s(data.item.lead_address)+" ")])])]}},{key:"cell(creditor_name)",fn:function(data){return [_c('span',{attrs:{"id":'creditor' + data.item.id}},[_c('span',{staticClass:"cursor-pointer",class:_vm.textLink,on:{"click":function($event){return _vm.editCreditorAccount(data.item.id)}}},[_vm._v(_vm._s(data.item.creditor_name))]),_c('br'),_vm._v(" # "+_vm._s(data.item.creditor_account)+" ")]),_c('b-tooltip',{attrs:{"target":'creditor' + data.item.id,"triggers":"hover","placement":"right"}},[_c('span',[_vm._v(" CONTACT NUMBER: "),_c('span')])])]}},{key:"cell(available_balance)",fn:function(data){return [_c('span',{class:data.item.available_balance > 0
                  ? 'text-primary'
                  : 'text-danger'},[_vm._v("$ "+_vm._s(_vm._f("currency")(data.item.available_balance)))])]}},{key:"cell(last_offer_date)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(data.item.last_offer_date)))])]}},{key:"cell(months_behind)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.months_behind))])]}},{key:"cell(company_percent)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.company_percent === "1050.00000" ? "-" : parseFloat(data.item.company_percent).toFixed(2))+" % ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"w-100 d-flex justify-content-center",staticStyle:{"gap":"1rem"}},[(data.item.account_phase_id !== 8)?_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusIcon","size":"22"},on:{"click":function($event){return _vm.openSettlementModal('create', data.item)}}}):_vm._e(),(data.item.rejected_offers > 0)?_c('div',{staticClass:"rejected-counter cursor-pointer",on:{"click":function($event){return _vm.openRejectedOffersModal(data.item)}}},[_vm._v(" "+_vm._s(data.item.rejected_offers)+" ")]):_vm._e()],1)]}},{key:"cell(id_credits_account)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('feather-icon',{staticClass:"cursor-pointer",class:data.item.cc_files > 0 ? 'text-warning' : '',attrs:{"icon":"FolderIcon","size":"18"},on:{"click":function($event){return _vm.openModalFiles(data.item)}}})],1)]}},{key:"custom-foot",fn:function(){return [_c('b-tr',[_c('b-td',[_c('span',{staticClass:"font-small-4"},[_vm._v("Total:")])]),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.initialBalance,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.creditorActualBalance,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1),_c('b-td',{class:_vm.isDarkSkin ? 'bg-category-modal' : 'bg-secondary'},[_c('b-badge',{class:_vm.isDarkSkin ? 'text-black' : 'text-dark',attrs:{"variant":"light"}},[_c('money',_vm._b({staticClass:"font-small-3",staticStyle:{"border":"none"},attrs:{"value":_vm.lastOfferAmount,"disabled":""}},'money',{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    },false))],1)],1)],1)]},proxy:true}])})]},proxy:true}])}),(_vm.showSettlementModal)?_c('settlement-offer-modal',{attrs:{"offer-info":_vm.settlementModalInfo,"eventId":_vm.event_id},on:{"closing":_vm.closeSettlementModal,"refresh":function($event){return _vm.$refs.refSettlementOffersDetailsTable.refresh()}}}):_vm._e(),_c('rejected-offers-modal',{attrs:{"info":_vm.settlementModalInfo,"eventId":_vm.event_id,"show":_vm.showRejectedOffersModal},on:{"closing":function($event){_vm.showRejectedOffersModal = false}}}),(_vm.trackingPendingMadeDsListCreditModalOn)?_c('tracking-pending-made-ds-list-credit-modal',{attrs:{"ds-list-credit-id":_vm.dsListCreditForTracking},on:{"close":function($event){_vm.trackingPendingMadeDsListCreditModalOn = false}}}):_vm._e(),(_vm.addCreditorModalController)?_c('add-creditor-ds-modal',{attrs:{"id-creditor-to-pass":_vm.idCreditorToPass,"state-modal":_vm.stateModal},on:{"close":function($event){_vm.addCreditorModalController = false}}}):_vm._e(),(_vm.uploadFilesModalController)?_c('upload-files-modal',{attrs:{"idcreditor":_vm.accountToSend.id,"idcreditoraccount":_vm.accountToSend.id_credits_account,"caid":_vm.client_account_id},on:{"close":function($event){_vm.uploadFilesModalController = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }