<template>
  <div class="w-75 mx-auto">
    <b-row>
      <b-col sm="12">
        <b-card
          class="box-shadow-0 mb-0"
          :style="'background-color:transparent !important;'"
        >
          <div
            class="p-4 rounded-0"
            :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'"
          >
            <b-col>
              <b-row class="pl-1 pr-1">
                <b-col>
                  <!-- <h2 class="pb-2">CHECK/ MONEY ORDER/ CASHIER CHECK</h2> -->
                  <h2 v-if="checkData.payment_type === 'CASHIER CHECK'">
                    CASHIER CHECK
                  </h2>
                  <h2 v-else-if="checkData.payment_type === 'MONEY ORDER'">
                    MONEY ORDER
                  </h2>
                  <h2 v-else-if="checkData.payment_type === 'CHECK'">CHECK</h2>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  class="pb-1"
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >PAY TO/ PAGAR A:
                  </strong>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >CREDITOR:
                  </strong>
                  <span
                    :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                    class="text-uppercase"
                    >{{ offerData.creditor_name }}</span
                  >
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >AMOUNT:
                  </strong>
                  <span
                    :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                    class="text-uppercase"
                    >${{ offerData.payment_amount }}</span
                  >
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >ADDRESS:
                  </strong>
                  <span
                    :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                    class="text-uppercase"
                    >{{ offerData.creditor_address }}</span
                  >
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  class="pr-2"
                  :style="'border-width:2px !important;border-color: #AEB6BF !important ;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >MEMO:
                  </strong>
                  <span
                    :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                    class="text-uppercase"
                    >{{
                      offerData.memo_cashier_check_money_order ||
                      "MEMO GOES HERE"
                    }}</span
                  >
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col>
                  <div
                    class="pb-1 pr-2 border-bottom"
                    :style="'border-width:2px !important;border-color: #AEB6BF !important ;'"
                  ></div>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  class="p-1"
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >FROM/ DE:
                  </strong>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >CLIENT NAME:
                  </strong>
                  <span
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                  >
                    {{ offerData.client_name }}
                  </span>
                </b-col>
              </b-row>
              <b-row class="pl-1 pr-1">
                <b-col
                  :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
                >
                  <strong
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >ADDRESS:
                  </strong>
                  <span
                    class="mr-1 text-uppercase"
                    :style="'font-size:14px !important;'"
                    >{{ offerData.address_company_box }}
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="w-100 ml-0 mr-0">
      <PurchaseOrder
      v-if="settlementData"
        :itemData="settlementData"
        @sendParams="receiveParams"
        @validateOk="generateOfferPaymentOrder()"
        ref="purchase_order"
      />
    </b-row>
    <b-row v-if="previewMode">
      <div class="mx-auto">
        <div>
          <b-button
            :disabled="isLoading"
            variant="success"
            @click="validateForm()"
            >CONFIRM<b-spinner v-if="isLoading" class="ml-1" small
          /></b-button>
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import SettlementsmadeServices from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import PurchaseOrder from "@/views/debt-solution/views/settlements/components/PurchaseOrderModal.vue";
export default {
  components: { PurchaseOrder },
  props: {
    offerData: {
      type: Object,
      required: true,
    },
    checkData: {
      type: Object,
      required: true,
    },
    previewMode: {
      type: Boolean,
      default: true,
    },
    settlementData: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      purchaseOrderParams: {},
      typeCode: null,
      isMoneyCashier: 0,
    };
  },
  mounted() {},
  methods: {
    receiveParams(val) {
      this.purchaseOrderParams = val;
    },
    validateForm() {
      this.$refs.purchase_order.validateFields();
    },
    async generateOfferPaymentOrder() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      try {
        this.addPreloader();
        if (this.settlementData.payment_method === "CASHIER CHECK") {
          this.typeCode = 2;
        } else if (this.settlementData.payment_method === "MONEY ORDER") {
          this.typeCode = 1;
        }

        if (
          this.settlementData.payment_method === "CASHIER CHECK" ||
          this.settlementData.payment_method === "MONEY ORDER"
        ) {
          this.isMoneyCashier = 1;
        }

        let paramsPurchaseOrder = {
          operation_number: this.purchaseOrderParams.opNumber,
          operation_date: this.purchaseOrderParams.opDate,
          type: this.typeCode,
          correspondence_account_id: this.purchaseOrderParams.selectAccount.id,
          creditor_id: this.settlementData.creditor_id,
          creditor_mailing_address: this.settlementData.mailing_address,
          is_money_cashier: this.isMoneyCashier,
          offer_payment_code: this.settlementData.code,
        };
        const data = await SettlementsmadeServices.generateOfferPaymentOrder({
          ...this.offerData,
          ...paramsPurchaseOrder,
        });
        const has_available_balance = data.has_available_balance === 1;
        const is_two_days_or_less_to_payment_date =
          data.is_two_days_or_less_to_payment_date === 1;

        switch (true) {
          case !has_available_balance:
            this.showWarningSwal(
              "Cashier Check/Money Order check not generated",
              "The Cashier Check/Money Order check could not be generated due to insufficient funds."
            );
            this.$emit("physicalPaymentGeneratedWithCheck");
            break;
          case has_available_balance && !is_two_days_or_less_to_payment_date:
            this.showSuccessSwal(
              "A payment order has been created.",
              "Success"
            );
            this.$emit("physicalPaymentGeneratedWithCheck");
            break;
          case has_available_balance && is_two_days_or_less_to_payment_date:
            this.showInfoSwal(
              "A payment order has been created but it's missing two days or less to the payment date"
            );
            this.$emit("physicalPaymentGeneratedWithCheck");
            break;
          default:
            break;
        }
      } catch (err) {
        this.showErrorSwal(err);
        console.error(err);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300;500;900&display=swap");
.ligth-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px #0090e7 !important;
  border-radius: 2px !important;
}
.dark-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px rgba(255, 255, 255, 0.90127801120448177) !important;
  border-radius: 2px !important;
}
</style>