export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Created From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6,
        visible: true,
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Created To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6,
        visible: true,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Negotiator",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        visible: false,
        cols: 12,
    },
    {
        type: "select",
        label: "Process",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "REQUEST" },
            { value: 2, label: "VERIFICATION" },
            { value: 3, label: "UNDERVIEW" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
    },
    {
        type: "select",
        label: "Payment Format",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "BY PHONE" },
            { value: 2, label: "MAIL" },
            { value: 3, label: "ONLINE" }
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        visible: true,
    },
    {
        type: "select",
        label: "Payment Type",
        margin: true,
        showLabel: true,
        options: [
            { value: null, label: "All" },
            { value: 1, label: "E-CHECK (CHECKING ACCOUNT)" },
            { value: 2, label: "CHECK (OVERNIGHT)" },
            { value: 3, label: "CASHIER CHECK" },
            { value: 4, label: " MONEY ORDER" },
            // { value: 5, label: " DEBIT/CREDIT CARD" },
        ],
        model: "",
        reduce: "value",
        selectText: "label",
        cols: 12,
        visible: true,
    },
    {
        type: 'suggest',
        margin: true,
        showLabel: true,
        label: 'Creditor',
        model: null,
        placeholder: 'Select',
        savedValue: null,
        showText: 'Select',
        visible: true,
        options: [],
        reduce: 'id',
        selectText: 'value',
        cols: 12,
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Rejected From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6,
        visible: true,
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "Rejected To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
        cols: 6,
        visible: true,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Rejected By",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        visible: true,
        cols: 12,
    },
]