<template>
  <div>
    <div v-if="getRequestType == 2" class="d-flex justify-content-end">
      <b-button
        v-if="isCeo"
        ref="transferPendingSettlement"
        class="mt-1"
        variant="outline-info"
        style="position: relative; width: 200px"
        @click="showTransferComplete"
      >
        <span>
          DEPOSITS
          <b-badge v-if="totalDeposits > 0" variant="light-info" pill
            >{{ totalDeposits }}
          </b-badge>
        </span>
      </b-button>
    </div>
    <div v-if="getTab == 2" class="d-flex justify-content-end">
      <b-button
        ref="transferPendingSettlement"
        class="mt-1"
        variant="outline-warning"
        style="position: relative; width: 250px"
        @click="openPendingMigrations"
      >
        <span>
          PENDING MIGRATIONS
          <b-badge v-if="totalMigrations > 0" variant="light-warning" pill
            >{{ totalMigrations }}
          </b-badge>
        </span>
      </b-button>
    </div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="tabFilters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :change-columns-by-client="true"
      @reload="refreshTable()"
      @reset-all-filters="refreshTable()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button type="button" variant="primary" @click="allColumns(true)">
            <tabler-icon
              title="SL File"
              :icon="!showAllColumns ? 'Tallymark4Icon' : 'Tallymark2Icon'"
              size="13"
              style="margin-right: 0.5 rem"
              class="align-middle cursor-pointer"
            />
            {{ !showAllColumns ? "All columns" : "Basic columns" }}
          </b-button>
        </div>
        <div class="ml-2" v-if="getRequestType">
          <b-button
            :class="{
              'item-magenta': selectType == 1,
              'bg-light-secondary': selectType != 1,
            }"
            type="button"
            variant="primary"
            @click="selectedType(1)"
          >
            BY PHONE
          </b-button>
        </div>
        <div class="ml-2" v-if="getRequestType">
          <b-button
            :class="{
              'item-purple': selectType == 2,
              'bg-light-secondary': selectType != 2,
            }"
            type="button"
            variant="primary"
            @click="selectedType(2)"
          >
            MAIL
          </b-button>
        </div>
        <div class="ml-2" v-if="getRequestType">
          <b-button
            :class="{
              'item-jade': selectType == 3,
              'bg-light-secondary': selectType != 3,
            }"
            type="button"
            variant="primary"
            @click="selectedType(3)"
          >
            ONLINE
          </b-button>
        </div>
      </template>
      {{ checkData }}
      <template #custom-vselect>
        <v-select
          v-model="filters[8].model"
          :options="filters[8].options"
          :reduce="(obj) => obj.value"
        >
          <template #selected-option="{ label, icon, color }">
            <tabler-icon
              :icon="`${icon}`"
              size="19"
              class="align-middle scale"
              :style="`color:${color}`"
            />
            <span style="margin-left: 0.5rem">{{ label }}</span>
          </template>
          <template #option="{ label, icon, color }">
            <tabler-icon
              :icon="`${icon}`"
              size="19"
              class="align-middle scale"
              :style="`color:${color};margin-left:-1rem`"
            />
            <span style="margin-left: 0.5rem">{{ label }}</span>
          </template>
        </v-select>
      </template>
      <template #table>
        <b-table
          ref="refSettlementsTable"
          id="offer_payment_order_id"
          slot="table"
          :items="SettlementsMadeProvider"
          small
          :fields="tabFields"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <router-link
                  :class="[textLink]"
                  :to="{
                    name: 'debt-solution-dashboard',
                    params: {
                      idClient: data.item.account_id,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.client_name }}
                </router-link>
                <div>{{ data.item.account }}</div>
                <div>
                  <b-badge
                    v-if="data.item.type_offer === 'MIGRATION'"
                    variant="light-primary"
                    >MIGRATION
                  </b-badge>
                </div>
              </div>

              <div v-if="isTabToPay">
                <feather-icon
                  v-if="
                    (data.item.payment_mode === 'MAIL' &&
                      data.item.remaining_days <= 7) ||
                    ((data.item.payment_mode === 'BY PHONE' ||
                      data.item.payment_mode === 'ONLINE') &&
                      data.item.myssing_days <= 7 &&
                      data.item.method_payment_code != 1)
                  "
                  v-b-tooltip.hover.html.right="
                    getTooltip(
                      data.item.remaining_days,
                      data.item.myssing_days,
                      data.item.shipping_days,
                      data.item.payment_mode
                    )
                  "
                  :icon="
                    hasWarningDiff(
                      data.item.remaining_days,
                      data.item.payment_mode,
                      data.item.myssing_days
                    )
                      ? 'AlertCircleIcon'
                      : hasDangerDiff(
                          data.item.remaining_days,
                          data.item.payment_mode,
                          data.item.myssing_days
                        )
                      ? 'AlertTriangleIcon'
                      : ''
                  "
                  size="18"
                  class="cursor-pointer"
                  :class="
                    hasWarningDiff(
                      data.item.remaining_days,
                      data.item.payment_mode,
                      data.item.myssing_days
                    )
                      ? 'text-warning'
                      : hasDangerDiff(
                          data.item.remaining_days,
                          data.item.payment_mode,
                          data.item.myssing_days
                        )
                      ? 'text-danger'
                      : ''
                  "
                />
              </div>
            </div>
          </template>

          <template #cell(creditor)="data">
            <span>
              <strong
                class="cursor-pointer"
                :class="[textLink]"
                @click="editCreditorAccount(data.item.creditor_id)"
                >{{ data.item.creditor }}</strong
              >
              <br />
              <span>#{{ data.item.creditor_account }}</span>
            </span>
          </template>

          <template #cell(collection)="data">
            <span>
              <strong>{{ data.item.collection }}</strong>
              <br />
              <span>#{{ data.item.collection_account }}</span>
            </span>
          </template>

          <template #cell(balance)="data">
            <span> $ {{ data.value | currencyZero }}</span>
          </template>

          <template #cell(client_last_balance)="data">
            <span :class="data.value > 0 ? 'text-primary' : 'text-danger'">
              $ {{ data.value | currencyZero }}</span
            >
          </template>

          <template #cell(offer_amount)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>

          <template #cell(saving)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>

          <template #cell(profit)="data">
            $ <span>{{ data.value | currencyZero }}</span>
          </template>

          <template #cell(aproved_at)="data">
            <template>
              <div v-if="isTabToPay">
                <b-row>
                  <b-col>{{ data.item.approved_by }}</b-col>
                </b-row>
                <b-row>
                  <b-col>{{ data.value | myGlobalWithHour }}</b-col>
                </b-row>
              </div>
              <div v-else>
                <b-row>
                  <b-col>{{ data.item.finished_by }}</b-col>
                </b-row>
                <b-row>
                  <b-col>{{ data.item.finished_at | myGlobalWithHour }}</b-col>
                </b-row>
              </div>
            </template>
          </template>

          <template #cell(payment_date)="data">
            <span>{{ data.value | myGlobal }}</span>
          </template>

          <template #cell(id_status)="data">
            <template v-if="data.item.check_expense && isTabToPay">
              <tabler-icon
                v-if="getTab != 5"
                v-b-tooltip.hover.bottom
                :title="getTitleOfferWithCheck(data.item.check_expense)"
                :icon="getIconOfferWithCheck(data.item.check_expense)"
                size="20"
                :class="getColorStatusExpenses(data.item.check_expense)"
              />
            </template>
            <template v-else>
              <tabler-icon
                v-if="getTab != 5"
                v-b-tooltip.hover.bottom="
                  `${tooltipIconProcess(
                    data.item.id_status,
                    data.item.purchase_order_exists,
                    data.item.method_payment_code
                  )}`
                "
                :icon="`${statusIconProcess(
                  data.item.id_status,
                  data.item.payment_is_due_within_three_days,
                  data.item.purchase_order_exists,
                  data.item.method_payment_code
                )}`"
                size="20"
                :class="`${data.item.id_status === 3 ? 'cursor-pointer' : ''}`"
                :style="`color: ${checkStatusColor(
                  data.item.id_status,
                  data.item.payment_is_due_within_three_days,
                  data.item.purchase_order_exists,
                  data.item.method_payment_code
                )};`"
                @click="
                  openNotPaidReasonsObservations(
                    data.item.id_status,
                    data.item.offer_payment_order_id
                  )
                "
              />
              <tabler-icon
                v-else
                icon="CashIcon"
                :id="`tooltip-icon-${data.index}`"
                style="color: #ff0000"
              />
              <b-tooltip
                :target="`tooltip-icon-${data.index}`"
                triggers="hover"
              >
                <div>
                  <span style="text-transform: uppercase"
                    >{{
                      tooltipIconProcess(data.item.id_status) ||
                      "Negotiation not completed"
                    }}
                  </span>
                  <br />
                  <div class="text-left" style="padding-top: 4px">
                    <span
                      class="font-small"
                      v-if="data.item.status_in_correspondence"
                    >
                      - CORRESPONDENCE:
                      {{ data.item.status_in_correspondence }}</span
                    >
                    <br v-if="data.item.status_in_correspondence" />
                    <span class="font-small" style="text-transform: uppercase">
                      - DEBT SOLUTION:
                      {{
                        tooltipIconProcess(data.item.id_status) ||
                        "CHECK NOT GENERATED"
                      }}
                    </span>
                  </div>
                </div>
              </b-tooltip>
            </template>
          </template>

          <template #cell(created_at)="data">
            {{ data.value | myGlobal }}
          </template>

          <template #cell(orden_aproved_at)="data">
            <b-row>
              <b-col>{{ data.item.order_payment_created_by }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ data.value | myGlobalWithHour }}</b-col>
            </b-row>
          </template>

          <template #cell(offer_document)="data">
            <a v-if="data.item.pdf" :href="data.item.pdf" target="_blank">
              <feather-icon
                v-b-tooltip.hover.bottom
                title="SL File"
                icon="FileIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle text-primary cursor-pointer scale"
              />
            </a>

            <template>
              <a v-if="data.item.tif" :href="data.item.tif" target="_blank">
                <feather-icon
                  v-b-tooltip.hover.bottom
                  title="PIF File"
                  icon="FileTextIcon"
                  size="19"
                  class="align-middle text-success cursor-pointer scale"
                />
              </a>
            </template>
            <a
              v-if="
                !data.item.check_id &&
                data.item.type_offer != 'MIGRATION' &&
                data.item.offer_payment_order_id != null &&
                ((data.item.id_status != 2 && (isSupervisor || isCeo)) ||
                  (data.item.id_status != 2 &&
                    data.item.user_id == currentUserId))
              "
              @click="openModalCheck(data.item)"
            >
              <transition>
                <tabler-icon
                  v-b-tooltip.hover.bottom
                  title="See Check"
                  icon="FileCheckIcon"
                  size="19"
                  style="margin-right: 0.5 rem"
                  class="align-middle cursor-pointer scale"
                />
              </transition>
            </a>
            <a
              v-if="data.item.id_status == 6"
              class="text-danger"
              target="_blank"
              v-b-tooltip.bottom="'Settlement Complete File'"
              @click="
                generatePdfOnFinalizeProcess(
                  data.item.offer_payment_order_id,
                  data.item.account
                )
              "
            >
              <amg-icon
                icon="FilePdfIcon"
                style="margin-right: 0.5 rem"
                size="15"
              />
            </a>

            <a
              target="_blank"
              v-if="data.item.check_manual_exist"
              @click="openOtherFiles(data.item, 1)"
            >
              <tabler-icon
                v-b-tooltip.hover.bottom
                title="Check File"
                icon="FileCheckIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle text-success cursor-pointer scale"
              />
            </a>

            <a
              target="_blank"
              v-if="data.item.shipment_exist"
              @click="openOtherFiles(data.item, 2)"
            >
              <tabler-icon
                v-b-tooltip.hover.bottom
                title="Correspondence File"
                icon="FileCheckIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle text-warning cursor-pointer scale"
              />
            </a>
            <a
              v-if="data.item.check_path"
              :href="data.item.check_path"
              target="_blank"
            >
              <tabler-icon
                v-b-tooltip.hover.bottom
                :title="`See Check: ${formatDateHour(
                  data.item.check_path_date
                )}`"
                icon="FileCheckIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle cursor-pointer scale"
              />
            </a>
            <a
              v-if="data.item.evidence_path"
              :href="data.item.evidence_path"
              target="_blank"
            >
              <tabler-icon
                v-b-tooltip.hover.bottom
                :title="`Correspondence File: ${formatDateHour(
                  data.item.evidence_path_date
                )}`"
                icon="FileCheckIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle text-warning cursor-pointer scale"
              />
            </a>
            <a
              v-if="data.item.collect_path"
              :href="data.item.collect_path"
              target="_blank"
            >
              <tabler-icon
                v-b-tooltip.hover.bottom
                :title="`Check File: ${formatOnlyDate(data.item.collect_date)}`"
                icon="FileCheckIcon"
                size="19"
                style="margin-right: 0.5 rem"
                class="align-middle text-success cursor-pointer scale"
              />
            </a>
          </template>

          <template #cell(bank_name)="data">
            <div>
              <span v-if="data.item.id_status"> {{ data.item.bank_name }}</span>
              <span v-else> --</span>
            </div>
            <div>
              <span
                v-if="
                  enablePifUpload(
                    data.item.tif,
                    data.item.id_status,
                    data.item.user_id
                  ) ||
                  (data.item.id_status == 5 && !isAdvisor)
                "
                >{{ data.item.account_name }}</span
              >
              <span v-else> --</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <template v-if="data.item.check_expense && isTabToPay">
              <template v-if="['COLLECTED'].includes(data.item.check_expense)">
                <b-button
                  :variant="data.item.id_status == 5 ? 'info' : 'warning'"
                  @click="
                    openPifModal(
                      data.item.offer_payment_order_id,
                      data.item.account,
                      data.item.tif,
                      data.item.creditor_name,
                      data.item.client_name,
                      data.item.advisor_name,
                      data.item.method_payment_code,
                      1,
                      data.item
                    )
                  "
                  style="padding: 5px 8px"
                  v-b-tooltip.hover.bottom="
                    `${validateIcon(data.item.id_status)}`
                  "
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="
                      data.item.id_status == 5 ? 'CheckIcon' : 'UploadIcon'
                    "
                    size="18"
                    style="margin-right: 4px"
                  />
                  <span style="text-transform: uppercase">{{
                    validateIcon(data.item.id_status)
                  }}</span>
                </b-button>
              </template>
              <template v-else-if="data.item.check_expense == 'PENDING REVIEW'">
                <b-button @click="openMakePayment(data.item)" variant="success"
                  >MAKE PAYMENT</b-button
                >
              </template>
              <template v-else>
                <strong
                  :class="getColorStatusExpenses(data.item.check_expense)"
                  >{{ getTextStatusExpenses(data.item.check_expense) }}</strong
                >
              </template>
            </template>
            <template v-else>
              <div
                v-if="
                  data.item.method_payment_code == 2 &&
                  data.item.status_in_correspondence == 'IN PROCCESS'
                "
              >
                <b-badge variant="light-primary">
                  <span>IN CORRESPONDENCE</span>
                </b-badge>
              </div>
              <div v-else class="d-flex justify-content-center">
                <b-button
                  variant="success"
                  v-if="
                    isTabToPay &&
                    data.item.id_status == 1 &&
                    [1, 2].includes(data.item.method_payment_code) &&
                    (isCeo || isSupervisor)
                  "
                  v-b-tooltip.hover.right="'Confirm Debit'"
                  style="padding: 6px 8px"
                  @click="editStatus(data.item, 'confirm')"
                >
                  <span>COLLECTED</span>
                </b-button>
                <b-button
                  variant="danger"
                  v-if="
                    isTabToPay &&
                    data.item.id_status == 1 &&
                    (isCeo || isSupervisor) &&
                    [1, 2].includes(data.item.method_payment_code)
                  "
                  v-b-tooltip.hover.right="'Reject Debit'"
                  style="padding: 6px 8px"
                  class="ml-1 mr-1"
                  @click="editStatus(data.item, 'reject')"
                >
                  <span>NOT COLLECTED</span>
                </b-button>

                <b-button
                  v-if="isTabToPay && data.item.id_status == null"
                  style="padding: 6px 8px"
                  @click="openOfferPaymentOrderModal(data.item, data.item)"
                  class="cursor-pointer"
                  v-b-tooltip.hover.bottom="'Generate Payment Order'"
                >
                  <tabler-icon
                    icon="ClipboardIcon"
                    size="20"
                    style="margin-right: 4px"
                  />
                  <span>GENERATE</span>
                </b-button>

                <b-button
                  :variant="data.item.id_status == 5 ? 'info' : 'warning'"
                  v-if="
                    enablePifUpload(
                      data.item.tif,
                      data.item.id_status,
                      data.item.user_id
                    ) ||
                    (data.item.id_status == 5 && !isAdvisor)
                  "
                  @click="
                    openPifModal(
                      data.item.offer_payment_order_id,
                      data.item.account,
                      data.item.tif,
                      data.item.creditor_name,
                      data.item.client_name,
                      data.item.advisor_name,
                      data.item.method_payment_code,
                      1,
                      data.item
                    )
                  "
                  style="padding: 5px 8px"
                  v-b-tooltip.hover.bottom="
                    `${validateIcon(data.item.id_status)}`
                  "
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="
                      data.item.id_status == 5 ? 'CheckIcon' : 'UploadIcon'
                    "
                    size="18"
                    style="margin-right: 4px"
                  />
                  <span style="text-transform: uppercase">{{
                    validateIcon(data.item.id_status)
                  }}</span>
                </b-button>
                <div
                  class="d-flex justify-content-center"
                  v-if="
                    enablePifUpload(
                      data.item.tif,
                      data.item.id_status,
                      data.item.user_id
                    ) ||
                    (data.item.id_status == 5 && !isAdvisor)
                  "
                >
                  <b-button
                    variant="info"
                    v-if="
                      data.item.method_payment_code == 2 &&
                      !data.item.check_manual_exist
                    "
                    style="padding: 5px 8px"
                    class="ml-1"
                    @click="
                      openPifModal(
                        data.item.offer_payment_order_id,
                        data.item.account,
                        data.item.tif,
                        data.item.creditor_name,
                        data.item.client_name,
                        data.item.advisor_name,
                        data.item.method_payment_code,
                        2,
                        data.item
                      )
                    "
                  >
                    <feather-icon
                      class="cursor-pointer"
                      icon="UploadIcon"
                      size="18"
                    />
                    CHECK MANUAL
                  </b-button>
                </div>
                <div v-if="isTabToPay">
                  <feather-icon
                    v-if="
                      data.item.id_status === 2 &&
                      data.item.debit_can_be_done === 1 &&
                      !data.item.check_number
                    "
                    v-b-tooltip.hover.bottom="`Generate Paycheck`"
                    icon="MailIcon"
                    size="20"
                    class="text-warning cursor-pointer"
                    @click="openOfferPaymentOrderModal(data.item, data.item)"
                  />
                </div>
              </div>
              <div
                v-if="getTab == 5 && !isAdvisor"
                class="d-flex justify-content-center"
              >
                <feather-icon
                  v-b-tooltip.hover.top="
                    ` ${data.item.viewed == 0 ? 'Unread' : 'Read'} `
                  "
                  icon="EyeIcon"
                  size="18"
                  :class="
                    data.item.viewed == 0 ? 'text-success cursor-pointer' : ''
                  "
                  :style="data.item.viewed == 0 ? '' : 'color: #727272'"
                  @click="updateViewed(data.item)"
                />
              </div>
            </template>
          </template>

          <template #cell(detail)="data">
            <feather-icon
              size="22"
              icon="EyeIcon"
              class="text-warning cursor-pointer"
              @click="openOfferModal(data.item)"
              v-b-tooltip.hover.left="'SEE OFFER SUMMARY'"
            />
          </template>

          <template #cell(check_manual_exist)="data">
            <feather-icon
              v-if="data.item.check_manual_exist"
              size="20"
              icon="EyeIcon"
              class="text-primary cursor-pointer"
              @click="
                openModalListFileCheckManual(data.item.offer_payment_order_id)
              "
            />
            <feather-icon
              v-else-if="data.item.method_payment_code == 2"
              class="cursor-pointer text-warning"
              icon="UploadIcon"
              size="20"
              @click="
                openPifModal(
                  data.item.offer_payment_order_id,
                  data.item.account,
                  data.item.tif,
                  data.item.creditor_name,
                  data.item.client_name,
                  data.item.advisor_name,
                  data.item.method_payment_code,
                  2,
                  data.item
                )
              "
            />
            <span v-else>-</span>
          </template>

          <template #cell(deposit)="data">
            <b-badge
              v-if="[2, 'CLIENT'].includes(data.item.payment_type)"
              class="cursor-pointer text-uppercase"
              :variant="getVariantDeposit(data.item.deposit_id)"
              @click="openModalDeposit(data.item)"
            >
              {{ data.item.deposit }}
            </b-badge>
            <span v-else>-</span>
          </template>

          <template #cell(tracking)="data">
            <feather-icon
              v-if="data.item.check_expense"
              size="20"
              icon="AlignLeftIcon"
              class="text-primary cursor-pointer"
              @click="openChecksTrackingModal(data.item)"
            />
            <feather-icon
              v-else
              size="20"
              icon="AlignLeftIcon"
              class="text-primary cursor-pointer"
              @click="
                openSettlementTrackingModal(
                  data.item.id,
                  data.item.offer_payment_order_id
                )
              "
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <settlement-tracking-modal
      :show="settlementTrackingModal"
      :offer="trackingOfferId"
      @closing="closeSettlementTrackingModal"
    />
    <offer-payment-order-modal
      v-if="offerPaymentOrderModalIsOpen"
      :settlementData="itemData"
      :active-modal="offerPaymentOrderModalIsOpen"
      :offer-data="offerData"
      :dataCheck="dataCheck"
      @close="closeOfferPaymentOrderModal"
      @reload="refreshTable()"
    />
    <pif-modal
      v-if="pifModal"
      :account_id="account_id"
      :payment_order_id="paymentOrder"
      :tif_data="tifdata"
      :creditor="creditor_name"
      :client="client_name"
      :advisor="advisor_name"
      :type="method_payment_code"
      :is-tab-pay="isTabToPay"
      :type-button="type_button"
      :creditor_id="itemSelected.creditor_id"
      @close="closePifModal"
      @reload="refreshTable()"
    />
    <not-paid-reasons-observations
      v-if="paidReasonsObservationsOpened"
      :active-modal="paidReasonsObservationsOpened"
      :offer-payment-order-id="offerPaymentOrderId"
      @close="closeNotPaidReasonsObservations"
    />
    <!-- componente generateCheck -->
    <generate-check
      v-if="check"
      :settlementData="this.itemData"
      :checkData="checkData"
      :clientCreditorCheck="clientCreditorCheck"
      :checkGeneratedStatus="checkGeneratedStatus"
      :isACheck="isACheck"
      @closeModal="hideModal"
      @reload="refreshTable()"
      @refreshModalCheck="(dataCheck) => refreshModalCheck(dataCheck)"
    />
    <ConfirmationModal
      v-if="showConfirmationModal"
      :paymentOrder="paymentOrder"
      @onClose="closeConfirmationModal"
      @reload="refreshTable()"
      @openDetail="openOfferModal"
      :type="'to-pay'"
    ></ConfirmationModal>

    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :id-creditor-to-pass="idCreditorToPass"
      :state-modal="stateModal"
      @close="addCreditorModalController = false"
      @reload="refreshTable()"
    />

    <purchase-order-modal
      :itemData="itemData"
      v-if="purchaseOrderModal"
      @success="successPurchaseOrderModal()"
      @hidden="hiddePurchaseOrderModal()"
    />

    <list-file-check-manual
      v-if="modalListFileCheckManual"
      :ds-offer-payment-orders-id="offerPaymentOrderId"
      @close="modalListFileCheckManual = false"
    />

    <modal-deposit
      v-if="modalDeposit"
      :ds-offer-payment-orders-id="offerPaymentOrderId"
      :client="clientDeposit"
      :payment-account="paymentAccountDeposit"
      :amount="amountDeposit"
      :only-show="onlyShowDeposit"
      @close="
        modalDeposit = false;
        onlyShowDeposit = null;
      "
      @refresh="
        $refs.refSettlementsTable.refresh();
        countDeposits();
      "
    />

    <transfer-complete-modal
      v-if="showTransferCompleteModal"
      @close="showTransferCompleteModal = false"
    />

    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="event_id"
      @closing="showSettlementOffer = false"
    />
    <MigrationsPendingModal
      v-if="showPendingMigrations"
      @pay="countPendings()"
      @close="showPendingMigrations = false"
    />
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    ></ChecksTrackingModal>
    <MakePaymentModal
      v-if="showMakePayment"
      :dataRow="selectedRow"
      @closeModal="closeMakePayment"
      @done="refreshTable"
    />
  </div>
</template>
<script>
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import SettlementTrackingModal from "@/views/debt-solution/views/settlement-offer/SettlementTrackingModal.vue";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import OfferPaymentOrderModal from "@/views/debt-solution/views/settlements-made/components/modals/GenerateOfferPaymentOrder.vue";
import PifModal from "@/views/debt-solution/views/settlements-made/components/subcomponents/AddPifModal.vue";
import debtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
import NotPaidReasonsObservations from "@/views/debt-solution/views/settlements-made/components/modals/RejectedReasonsModal.vue";
import { mapGetters, mapActions } from "vuex";
import SettlementsTrackingMade from "@/views/debt-solution/views/settlements-made/components/subcomponents/SettlementsMadeMadeTracking.vue";
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import filters from "@/views/debt-solution/views/settlements/data/filters-to-pay.data.js";
import fields from "@/views/debt-solution/views/settlements/data/fields-to-pay.data.js";
import GenerateCheck from "@/views/debt-solution/views/settlements-made/components/modals/GenerateCheck.vue";
import BinnacleOrderModal from "@/views/debt-solution/views/settlements-made/components/modals/BinnacleOrderModall.vue";
import filesMixin from "@/views/debt-solution/views/settlements-made/mixins/filesMixin";
import ConfirmationModal from "@/views/debt-solution/views/settlements/components/ConfirmModal.vue";
import PurchaseOrderModal from "@/views/debt-solution/views/settlements/components/PurchaseOrderModal.vue";
import ListFileCheckManual from "@/views/debt-solution/views/settlements/modals/ListFileCheckManual.vue";
import ModalDeposit from "@/views/debt-solution/views/settlements/modals/ModalDeposit.vue";
import TransferCompleteModal from "@/views/debt-solution/views/settlements/components/TransferCompleteModal.vue";
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import MigrationsPendingModal from "@/views/debt-solution/views/settlements/components/MigrationsPendingModal.vue";
import SettlementServices from "@/views/debt-solution/views/settlements/services/settlement.service.js";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
import SendingToCreditorsService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
import MakePaymentModal from "@/views/debt-solution/views/settlement-offer/MakePaymentModal.vue";
import moment from "moment";
export default {
  components: {
    ViewClientDetails,
    SettlementTrackingModal,
    SettlementsTrackingMade,
    OfferPaymentOrderModal,
    PifModal,
    NotPaidReasonsObservations,
    GenerateCheck,
    BinnacleOrderModal,
    ConfirmationModal,
    AddCreditorDsModal,
    PurchaseOrderModal,
    ListFileCheckManual,
    ModalDeposit,
    TransferCompleteModal,
    SettlementOfferModal,
    MigrationsPendingModal,
    ChecksTrackingModal,
    MakePaymentModal,
  },
  mixins: [filesMixin],
  data() {
    return {
      purchaseOrderModal: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: this.$route.meta.tab == 3 ? "created_at" : "default",
      sortDesc: true,
      fields,
      filters,
      isACheck: null,
      isBusy: false,
      offerPaymentOrderModalIsOpen: false,
      offerPaymentOrderId: null,
      showConfirmationModal: false,
      offerData: {
        offer_id: null,
        offer_payment_fraction_id: null,
        payment_amount: null,
        user_id: null,
        bank_account_type: null,
        payment_method: null,
        offer_payment_order_id: null,
        address: null,
      },
      paidReasonsObservationsOpened: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      items: [],
      haveoffers: false,
      trackingOfferId: null,
      settlementTrackingModal: false,
      pifModal: false,
      paymentOrder: null,
      account_id: null,
      tifdata: null,
      creditor_name: null,
      client_name: null,
      advisor_name: null,
      check: false,
      checkData: {},
      clientCreditorCheck: {
        client_name: null,
        creditor_name: null,
      },
      checkGeneratedStatus: false,
      binnacleModal: false,
      order_id: null,
      showAllColumns: false,
      dataCheck: {},
      itemBinacle: {},
      addCreditorModalController: false,
      stateModal: false,
      idCreditorToPass: null,
      itemData: [],
      method_payment_code: null,
      modalListFileCheckManual: false,
      offerPaymentOrderId: null,
      modalDeposit: false,
      onlyShowDeposit: null,
      selectType: null,
      clientDeposit: null,
      paymentAccountDeposit: null,
      amountDeposit: null,
      showTransferCompleteModal: false,
      type_button: 0,
      offerModalInfo: {},
      showSettlementOffer: false,
      event_id: null,
      totalDeposits: 0,
      showPendingMigrations: false,
      totalMigrations: 0,
      showChecksTrackingModal: false,
      selectedCheck: null,
      showMakePayment: false,
      selectedRow: null,
      itemSelected: null,
    };
  },
  async mounted() {
    await this.destroySocketTransferPending();
    this.activeSocketTransferPending();
    await this.getUsersAdvisor();
    this.allColumns(false);
    this.countDeposits();
    this.countPendings();
  },
  methods: {
    formatDateHour(created) {
      return created ? moment(created).format("MM/DD/YYYY hh:mm A") : "-";
    },
    formatOnlyDate(created) {
      return created ? moment(created).format("MM/DD/YYYY") : "-";
    },
    async countDeposits() {
      const result = await SettlementServices.countDeposits();
      this.totalDeposits = result.data;
    },
    async countPendings() {
      const result = await AccountsActionsService.counterMigrateOffers();
      this.totalMigrations = result;
    },
    async updateViewed(item) {
      if (item.viewed == 0 || item.viewed == null) {
        const confirm = await this.showConfirmSwal(
          `Are you sure you want to mark this offer as read?`
        );
        if (confirm.isConfirmed) {
          const result = await SettlementsMadeService.updateViewed({
            offer_id: item.id,
          });
          if (result.status == 200) {
            this.S_COUNTER_UNFINISHED = this.S_COUNTER_UNFINISHED - 1;
            this.A_COUNTER_SETTLEMENTS({
              role_id: this.currentUser.role_id,
              user_id: this.currentUser.user_id,
            });
            this.$refs.refSettlementsTable.refresh();
          }
        }
      }
    },
    getTooltip(remaining_days, missing_days, shipping_days, payment_mode) {
      // if (payment_mode === "MAIL") {
      return `
      <strong>Missing Days: </strong> ${missing_days} <br>
      <strong>Shipping Days: </strong> ${shipping_days} <br>
      <strong>Remaining Days: </strong> ${remaining_days} <br>
      `;
      // } else {
      // return `k
      // <strong>Missing Days: </strong> ${missing_days} <br>
      // `;
      // }
    },
    hasWarningDiff(remaining_days, payment_mode, missing_days) {
      if (payment_mode === "MAIL") {
        return remaining_days <= 7 && remaining_days >= 4;
      } else {
        return missing_days <= 7 && missing_days >= 4;
      }
    },

    hasDangerDiff(remaining_days, payment_mode, missing_days) {
      if (payment_mode === "MAIL") {
        return remaining_days < 4;
      } else {
        return missing_days < 4;
      }
    },

    hiddePurchaseOrderModal() {
      this.purchaseOrderModal = false;
    },
    successPurchaseOrderModal() {
      this.hiddePurchaseOrderModal();
      this.refreshTable();
    },
    activaSocket() {
      try {
        // window.socket.subscribe("management-notifications");
        window.socket.bind(
          "input-balance-notifications-management",
          async (response) => {
            if (this.isTabMade) {
              this.refreshTable();
            }
          }
        );
      } catch (error) {
        console.log("Socket-settlement", error);
      }
    },
    async reloadIndicators() {
      await this.GET_PAYMENT_STATUS_TOTAL();
    },
    async openModalCheck(data) {
      this.isACheck = [
        "E-CHECK (CHECKING ACCOUNT)",
        "CHECK (OVERNIGHT)",
        // "DEBIT/CREDIT CARD",
      ].includes(data.payment_method);

      const params = {
        offer_payment_order_id: data.offer_payment_order_id,
      };

      try {
        let checkData = {};
        this.addPreloader();

        switch (this.isACheck) {
          case true /* Physical or Virtual Check */:
            this.checkData = await SettlementsMadeService.getCheck(params);

            this.checkData = { ...this.checkData, tif: data.tif };
            break;
          case false /* Cashier Check/ Money Order */:
            checkData =
              await SettlementsMadeService.getBankAccountDataByPaymentOrderId(
                params
              );

            this.checkData = {
              ...checkData,
              bank_account_name: checkData.account_name,
              tif: data.tif,
              address_company_box: data.address_company_box,
              memo_cashier_check_money_order: `Settlement pay to ${data.original_creditor_name} - ${data.creditor_account} - ${data.client_name}`,
            };
            this.checkData.payment_type = data.payment_method;

            break;
        }

        this.check = true;
        this.clientCreditorCheck = {
          ...data,
          creditor_address: data.address,
          bank_account_type: data.payment_type,
          payment_amount: data.payment_fractions_amount,
          memo: this.checkData?.account_list_credits,
          memo_cashier_check_money_order: `Settlement pay to ${data.original_creditor_name} - ${data.creditor_account} - ${data.client_name}`,
          address_company_box: data.address_company_box,
        };
        this.itemData = data;
        this.removePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    async openSettlementTrackingModal(id, payment_id) {
      this.trackingOfferId = id;
      this.settlementTrackingModal = true;
    },
    closeSettlementTrackingModal() {
      this.settlementTrackingModal = false;
    },
    async refreshTable() {
      this.offerPaymentOrderModalIsOpen = false;
      this.$refs.refSettlementsTable.refresh();
      await this.GET_PAYMENT_STATUS_TOTAL();
    },
    closePifModal() {
      this.pifModal = false;
      this.client_name = null;
      this.creditor_name = null;
      this.advisor_name = null;
      this.paymentOrder = null;
      this.account_id = null;
      this.tifdata = null;
      this.method_payment_code = null;

      this.A_COUNTER_SETTLEMENTS({
        role_id: this.currentUser.role_id,
        user_id: this.currentUser.user_id,
      });
    },
    async SettlementsMadeProvider(ctx) {
      this.validateFilters();
      try {
        const advisor_id = this.filters[9].model;
        const params = {
          name_text: this.filterPrincipal.model,
          created_from: this.filters[0].model,
          created_to: this.filters[1].model,
          approved_from: this.filters[2].model,
          approved_to: this.filters[3].model,
          payment_order_from: this.filters[4].model,
          payment_order_to: this.filters[5].model,
          payment_date_from: this.filters[6].model,
          payment_date_to: this.filters[7].model,
          status: this.filters[8].model,
          advisor_id: advisor_id,
          request_type: this.$route.meta.tab == 3 ? this.getRequestType : null,
          payment_format: this.filters[10].model,
          creditor: this.filters[11].savedValue,
          role_id: this.currentUser.role_id,

          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          tab: this.$route.meta.tab,
          sortBy: ctx.sortBy,
          sortOrder: ctx.sortDesc == true ? "ASC" : "DESC",
          origin: this.moduleId,
        };
        this.A_COUNT({ user_id: this.isAdvisor ? this.currentUserId : 0 });
        const data = await SettlementsMadeService.getSettlementsMade(params);
        if (data.status === 200) {
          this.items = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;

          return this.items || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    validateActions(offer) {
      return (
        this.currentUser.user_id == offer ||
        this.isSupervisor ||
        this.isCeo ||
        (this.$route.meta.tab != 1 && this.currentUser.user_id == offer)
      );
    },
    validateFilters() {
      const forderfrom = this.filters[2];
      const forderto = this.filters[3];
      const orderFrom = this.filters[4];
      const orderTo = this.filters[5];
      const paymentType = this.filters[10];
      const status = this.filters[8];
      const negotiator = this.filters[9];
      if (this.isTabToPay) {
        status.visible = true;
        orderFrom.visible = false;
        forderfrom.label = "Offer Approved From";
        forderto.label = "Offer Approved To";
        orderTo.visible = false;
        status.options = [
          {
            value: 1,
            label: "PENDING DEBIT CONFIRMATION",
            icon: "CashIcon",
            color: "#ffd833",
          },
          {
            value: 2,
            label: "PENDING PROCESSING DUE TO INSUFFICIENT FUNDS",
            icon: "CashIcon",
            color: "#ff6207",
          },
          {
            value: 4,
            label: "NEGOTIATION COMPLETED",
            icon: "CashIcon",
            color: "#66b70b",
          },
          {
            value: 7,
            label: "IN CORRESPONDENCE",
            icon: "MailIcon",
            color: "#ffd833",
          },
        ];
        paymentType.options = [
          { value: 1, label: "BY PHONE" },
          { value: 2, label: "MAIL" },
          { value: 3, label: "ONLINE" },
        ];
        negotiator.visible = true;
      } else {
        paymentType.options = [
          { value: 1, label: "BY PHONE" },
          { value: 2, label: "MAIL" },
          { value: 3, label: "ONLINE" },
        ];
        status.visible = true;
        status.options = [
          {
            value: 3,
            label: "NEGOTIATION NOT COMPLETED",
            icon: "CashIcon",
            color: "#ff0000",
          },
          {
            value: 6,
            label: "COMPLETED",
            icon: "ChecksIcon",
            color: "#54b408",
          },
        ];
        if (this.isAdvisor) {
          negotiator.visible = false;
        } else {
          negotiator.visible = true;
        }
        // status.visible = false;
        orderFrom.visible = true;
        forderfrom.label = "Payment Order Created From";
        forderto.label = "Payment Order Created To";
        orderTo.visible = true;
      }
    },
    setPhone(recovery) {
      if (!recovery) {
        return "#-";
      }
      return recovery[0].phone == null ? "# -" : `# ${recovery[0].phone}`;
    },
    async getUsersAdvisor() {
      const idModule = this.$route.matched[0].meta.module;
      const { data } = await debtSolutionServices.getAdvisors(idModule);
      this.filters[9].options = data;
    },
    async openOfferPaymentOrderModal(
      {
        id,
        payment_fractions_id,
        payment_fractions_amount,
        payment_type,
        payment_format,
        payment_method,
        offer_payment_order_id,
        client_name,
        creditor_name,
        address,
        myssing_days,
        shipping_days,
        creditor_address,
        creditor_state,
      },
      data
    ) {
      const titleSwal = offer_payment_order_id
        ? "Generated checked"
        : "Are you sure?";
      const textSwal = offer_payment_order_id
        ? "Would you like generate a check?"
        : "You won't be able to revert this!";

      const { isConfirmed } = await this.showConfirmSwal(titleSwal, textSwal);
      if (!isConfirmed) return;

      const shippingDayIsAValidValue = !!shipping_days;
      const has_creditor_address = !!address;

      switch (true) {
        case shippingDayIsAValidValue &&
          !this.isShippingDaysLessThanMissingDays(
            myssing_days,
            shipping_days
          ) &&
          this.isMailPaymentFormat(
            payment_method
          ) /* Alert when the check is not going to arrive on time */:
          return this.showWarningSwal(
            "Unable to generate payment order",
            `Missing days(${myssing_days}) are less than\nShipping days(${shipping_days})`
          );
        case !shippingDayIsAValidValue &&
          this.isMailPaymentFormat(
            payment_method
          ) /* Alert when there is not a shipping days value created for the creditor address */:
          return this.showWarningSwal(
            `Warning \n No shipping data available for (${creditor_state})\n ${
              myssing_days <= 2 && this.isMailPaymentFormat(payment_method)
                ? "and the payment date is due in two days or less"
                : ""
            }`
          );
        case myssing_days < 3 &&
          this.isMailPaymentFormat(
            payment_method
          ) /* Alert when the payment date is to close (less than three days) */:
          return this.showWarningSwal(
            `Warning \n The payment date is due in two days or less\n`
          );
        case !has_creditor_address /* Alert when the creditor doesn't has an address*/:
          return this.showWarningSwal(
            "Payment order can't be generated",
            "The creditor doesn't have an address, please inform your supervisor to update it"
          );
        default:
          break;
      }
      this.offerData = {
        offer_id: id,
        offer_payment_fraction_id: payment_fractions_id,
        payment_amount: payment_fractions_amount,
        user_id: this.currentUserId,
        bank_account_type: payment_type,
        payment_format: payment_format,
        payment_method: payment_method,
        offer_payment_order_id,
        client_name,
        creditor_name,
        creditor_address,
        address_company_box: data.address_company_box,
        memo_cashier_check_money_order: `Settlement pay to ${data.original_creditor_name} - ${data.creditor_account} - ${data.client_name}`,
      };
      this.offerPaymentOrderModalIsOpen = true;
      this.dataCheck = data;
      this.itemData = data;
    },
    closeOfferPaymentOrderModal(refresh) {
      this.offerData = {};
      this.offerPaymentOrderModalIsOpen = false;
      // refresh data after generate offer_payment_order

      this.A_COUNTER_SETTLEMENTS({
        role_id: this.currentUser.role_id,
        user_id: this.currentUser.user_id,
      });

      refresh ? this.refreshTable() : null;
    },

    async openPifModal(
      payment_order_id,
      account_id,
      data_tif,
      creditor_name,
      client_name,
      advisor_name,
      method_payment_code,
      type,
      item
    ) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      this.pifModal = true;
      this.creditor_name = creditor_name;
      this.client_name = client_name;
      this.advisor_name = advisor_name;
      this.paymentOrder = payment_order_id;
      this.account_id = account_id;
      this.tifdata = data_tif;
      this.method_payment_code = method_payment_code;
      this.type_button = type;
      this.itemSelected = item;
      console.log("item", item);
    },
    enablePifUpload(tif, status, user_id) {
      const existsPif = tif != "" && !!tif;
      return this.getTab == 2 && (status === 4 || status === 5) && !existsPif;
    },
    checkStatusColor(
      status,
      is_past_due_payment_date,
      purchase_order,
      method_payment_code
    ) {
      switch (true) {
        case status === 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833";
        case status === 2 && is_past_due_payment_date === 1:
          return "#ff0000";

        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code):
          return "#ff0000";

        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207";
        case status === 3: // DEBIT REJECTED
          return "#ff0000";
        case status === 4: // Negotiation completed
          return "#008000";
        case status === 5: // PENDING COMPLETION
          return "#ffd833";
        case status === 6: // COMPLETED
          return "#54b408";
        default:
          break;
      }
    },
    statusIconProcess(
      status,
      is_past_due_payment_date,
      purchase_order,
      method_payment_code
    ) {
      switch (true) {
        case status === 2 && is_past_due_payment_date === 1:
          return "ClockIcon";
        case status === 1 && !purchase_order && method_payment_code == 2: // PENDING DEBIT CONFIRMATION
          return "MailIcon";
        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "MailIcon";
        case status >= 1 && status <= 4:
          return "CashIcon";
        case status === 5:
          return "CheckIcon";
        case status === 6:
          return "ChecksIcon";
        default:
          break;
      }
    },
    tooltipIconProcess(status, purchase_order, method_payment_code) {
      switch (true) {
        case status === 1 && method_payment_code == 1: // PENDING DEBIT CONFIRMATION
          return "Pending Debit Confirmation";

        case status === 1 &&
          !purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "Purchase Order";

        case status === 1 && !purchase_order && method_payment_code == 2: // PENDING DEBIT CONFIRMATION
          return "In Correspondence";

        case status === 1 &&
          purchase_order &&
          [3, 4].includes(method_payment_code): // PENDING DEBIT CONFIRMATION
          return "In Correspondence";
        // case 1: // PENDING DEBIT CONFIRMATION
        //   return "Pending Debit Confirmation";

        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "Pending Processing Due To Insufficient Funds";
        case status === 3:
          return "Negotiation not completed"; // Negotiation not completed
        case status === 4: // Negotiation completed
          return "Negotiation completed";
        case status === 5:
          return "Pending Completion"; // PENDING COMPLETION
        case status === 6:
          return "Completed"; // COMPLETED
        default:
          break;
      }
    },
    openNotPaidReasonsObservations(status, offer_payment_order_id) {
      if (status !== 3) return;
      this.offerPaymentOrderId = offer_payment_order_id;
      this.paidReasonsObservationsOpened = true;
    },
    closeNotPaidReasonsObservations() {
      this.offerPaymentOrderId = null;
      this.paidReasonsObservationsOpened = false;
    },
    ...mapActions({
      A_COUNT: "DebtSolutionSettlementsMadeStore/A_COUNT_NOT_TIF",
      A_COUNTER_SETTLEMENTS:
        "DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS",
      GET_PAYMENT_STATUS_TOTAL:
        "DebtSolutionSettlementOfferStore/A_GET_PAYMENT_STATUS_TOTAL",
    }),
    finishGeneratedCheckProcess() {
      this.currentProcessState = 3;
      this.checkGeneratedStatus = true;
    },
    hideModal() {
      this.check = false;
    },
    hiddeModalBinnacle(item) {
      this.$set(item, "count_message", 0);
      this.binnacleModal = false;
    },
    allColumns(frombutton = false) {
      this.showAllColumns = frombutton
        ? !this.showAllColumns
        : this.showAllColumns;
    },
    refreshModalCheck(dataCheck) {
      this.checkData = dataCheck;
    },
    async generatePdfOnFinalizeProcess(offerPaymentOrderId, clientAccountCode) {
      try {
        this.addPreloader();
        this.addPreloader();
        const data = await SettlementsMadeService.generatePdfFinalizeProcess(
          offerPaymentOrderId
        );
        const fileName = "Settlement Complete -" + clientAccountCode + ".pdf";
        if (data.status == 200) {
          await this.forceFileDownloadPdfWithDownload(data.data, fileName);
          this.removePreloader();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },
    editStatus(paymentOrder, type) {
      this.paymentOrder = { ...paymentOrder, type };
      this.showConfirmationModal = true;
    },
    closeConfirmationModal(update = false) {
      // execute action to update coutners
      this.A_COUNTER_SETTLEMENTS({
        role_id: this.currentUser.role_id,
        user_id: this.currentUser.user_id,
      });

      this.showConfirmationModal = false;
      if (update == true) {
        this.$refs.refSettlementsTable.refresh();
      }
    },
    editCreditorAccount(id) {
      this.addCreditorModalController = true;
      this.stateModal = 0;
      this.idCreditorToPass = id;
    },
    validateIcon(id_status) {
      return id_status == 5 ? "Finalize" : "Upload PIF";
    },
    isShippingDaysLessThanMissingDays(missingDays, shippingDays) {
      return shippingDays < missingDays;
    },
    isMailPaymentFormat(paymentFormat) {
      return (
        paymentFormat == "CHECK (OVERNIGHT)" ||
        paymentFormat == "CASHIER CHECK" ||
        paymentFormat == "MONEY ORDER"
      );
    },
    openModalListFileCheckManual(id) {
      this.offerPaymentOrderId = id;
      this.modalListFileCheckManual = true;
    },
    getVariantDeposit(deposit_id) {
      switch (deposit_id) {
        case 1:
          return "light-warning";
          break;
        case 2:
          return "light-info";
          break;
        case 3:
          return "light-success";
          break;
        default:
          return;
      }
    },
    openModalDeposit(item) {
      if (item.deposit_id != 1) {
        this.onlyShowDeposit = {
          transfer_number: item.transfer_number,
          transfer_date: item.transfer_date,
          transfer_amount: item.transfer_amount,
          transfer_updated_at: item.transfer_updated_at,
          transfer_updated_by: item.transfer_updated_by,
        };
      }
      this.offerPaymentOrderId = item.offer_payment_order_id;
      this.clientDeposit = item.client_name;
      this.paymentAccountDeposit = item.bank_name;
      this.amountDeposit = item.offer_amount;
      this.modalDeposit = true;
    },
    activeSocketTransferPending() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind("refresh-table-settlement", async (response) => {
          if (this.isCeo || this.isChief) {
            this.refreshTable();
          }
        });
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    destroySocketTransferPending() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("refresh-table-settlement");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
    selectedType(type) {
      if (this.selectType == type) {
        this.selectType = null;
      } else {
        this.selectType = type;
        this.filters[10].model = type;
        this.refreshTable();
      }
    },
    showTransferComplete() {
      this.showTransferCompleteModal = true;
    },

    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.client_name;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorAccount = item.creditor_account;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = item.balance;
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.account_name = item.account;
      this.offerModalInfo.account = item.account_id;
      this.showSettlementOffer = true;
      this.event_id = item.event_id;
      this.offerModalInfo.account = item.account_id;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.offerModalInfo.creditorShortId = item.principal_creditor_id;
      this.offerModalInfo.account_number = item.creditor_account;
    },
    openPendingMigrations() {
      this.showPendingMigrations = true;
    },
    async openOtherFiles(item, value) {
      const params = {
        offer_payment_order_id: item.offer_payment_order_id,
      };
      const { data } =
        await SendingToCreditorsService.getSendingToCreditorRegisteredPayment(
          params
        );
      if (data.length == 0) return;
      let url = "";
      if (value == 1) {
        url = data[0].check_file;
      } else {
        url = data[0].evidence;
      }
      window.open(url, "_blank");
    },
    getTextStatusExpenses(data) {
      const status = {
        PENDING: "IN CEO",
        "PENDING COLLECTION": "PENDING COLLECT",
        COLLECTED: "COLLECTED",
        REJECTED: "REJECTED BY CEO",
        "SEND TO COURIER": "IN COURIER",
        "SEND TO CORRESPONDENCE": "IN CORRESPONDENCE",
        "NOT COLLECTED": "NOT COLLECTED",
        CANCELLED: "CANCELLED BY CORRESPONDENCE",
        GENERATED: "GENERATED",
        "APPROVED BY CEO": "APPROVED BY CEO",
        "PENDING REVIEW": "PENDING PAYMENT",
        "PAYMENT MADE": "PENDING COLLECT",
      };

      return status[data] || "Unknown";
    },
    getColorStatusExpenses(data) {
      const status = {
        PENDING: "text-warning-status",
        "PENDING COLLECTION": "text-warning-status",
        COLLECTED: "text-success-status",
        REJECTED: "text-danger",
        CANCELLED: "text-danger",
        "SEND TO COURIER": "text-warning-status",
        "SEND TO CORRESPONDENCE": "text-warning-status",
        GENERATED: "text-success-status",
        "PENDING REVIEW": "text-warning-status",
        "PAYMENT MADE": "text-warning-status",
      };
      return status[data] || "text-primary";
    },

    getIconOfferWithCheck(data) {
      const status = {
        PENDING: "ClockIcon",
        "PENDING COLLECTION": "CashIcon",
        COLLECTED: "CashIcon",
        REJECTED: "AlertTriangleIcon",
        "SEND TO COURIER": "TruckDeliveryIcon",
        "SEND TO CORRESPONDENCE": "MailIcon",
        "NOT COLLECTED": "CashIcon",
        CANCELLED: "AlertTriangleIcon",
        GENERATED: "CheckIcon",
        "APPROVED BY CEO": "ClockIcon",
        "PAYMENT MADE": "CashIcon",
        "PENDING REVIEW": "ClockIcon",
      };
      return status[data] || "ClockIcon";
    },

    getTitleOfferWithCheck(data) {
      const status = {
        PENDING: "IN CEO",
        "PENDING COLLECTION": "PENDING COLLECT",
        COLLECTED: "NEGOTIATION COMPLETED",
        REJECTED: "REJECTED BY CEO",
        "SEND TO COURIER": "IN COURIER",
        "SEND TO CORRESPONDENCE": "IN CORRESPONDENCE",
        "NOT COLLECTED": "NOT COLLECTED",
        CANCELLED: "CANCELLED BY CORRESPONDENCE",
        GENERATED: "GENERATED",
        "APPROVED BY CEO": "APPROVED BY CEO",
        "PAYMENT MADE": "PENDING COLLECT",
        "PENDING REVIEW": "PENDING PAYMENT",
      };
      return status[data] || "Unknown";
    },

    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
    },

    openMakePayment(item) {
      this.showMakePayment = true;
      this.selectedRow = item;
    },
    closeMakePayment() {
      this.showMakePayment = false;
      // this.refreshTable();
    },
  },
  computed: {
    isInProcess() {
      return this.type === "in-process";
    },
    isConfirmed() {
      return this.type === "confirmed";
    },
    isRejected() {
      return this.type === "rejected";
    },
    getTab() {
      return this.$route.meta.tab;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
    isAdvisor() {
      return this.currentUser.role_id === 3;
    },
    tabFields() {
      if (this.getTab == 2) {
        this.fields[19].label = "Offer Approved";
      } else {
        this.fields[17].label = "FINISHED AT";
        this.fields[2].visible = false;
      }

      if (this.showAllColumns) {
        this.fields.map((item) => {
          item.visible = true;
        });
      } else {
        const visibleColumns =
          this.getTab == 2
            ? [
                "client_name",
                "deposit",
                "actions",
                "detail",
                "creditor_name",
                "balance",
                "payment_type",
                "bank_name",
                "offer_amount",
                "payment_method",
                "offer_document",
                "id_status",
                "payment_date",
                "orden_aproved_at",
                "tracking",
              ]
            : [
                "client_name",
                "deposit",
                this.getTab != 3 ? "actions" : null,
                "detail",
                "creditor_name",
                "balance",
                "offer_amount",
                "collection",
                "offer_document",
                "id_status",
                "payment_type",
                "payment_date",
                "orden_aproved_at",
                "tracking",
              ];

        this.fields.map((row, _) => {
          visibleColumns.includes(row.key)
            ? (row.visible = true)
            : (row.visible = false);
        });
      }
      this.fields[25].visible = this.getTab == 3 && this.showAllColumns; //&& this.getRequestType == 2
      this.fields[1].visible =
        this.getTab == 3 && this.isCeo && this.getRequestType == 2;
      this.fields[6].visible = this.getTab == 2;
      this.fields[7].visible = this.getTab == 3;
      this.fields[21].visible = this.getTab != 2; // in tab==2 change offer aproved to oder aproved

      // COLUMN BANK NAME AND BANK ACCOUNT HIDDEN IN ADVISOR PROFILE
      if (this.isAdvisor) {
        this.fields[14].visible = false;
        this.fields[15].visible = false;
      }
      return this.fields.filter((item) => item.visible === true);
    },
    isTabToPay() {
      return this.$route.meta.tab == 2;
    },
    isTabToBeApproved() {
      return this.$route.meta.tab == 1;
    },
    isTabFinished() {
      return this.$route.meta.tab == 3;
    },
    isTabUnfinished() {
      return this.$route.meta.tab == 5;
    },
    isTabMade() {
      return this.$route.meta.tab == 2;
    },
    tabFilters() {
      if (this.isTabUnfinished || this.isTabFinished) {
        return this.filters.filter(
          (item) =>
            item.visible === true && item.label != "Payment Order Status"
        );
      } else {
        return this.filters.filter((item) => item.visible === true);
      }
    },
    creditorFilter() {
      return this.filters[11].model;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    S_COUNTER_UNFINISHED: {
      get() {
        return this.$store.state["DebtSolutionSettlementOfferStore"]
          .S_COUNTER_UNFINISHED;
      },
      set(value) {
        this.$store.commit(
          "DebtSolutionSettlementOfferStore/M_COUNTER_UNFINISHED",
          value
        );
      },
    },
    getRequestType() {
      return this.$route.meta.request_type;
    },
  },
  watch: {
    async creditorFilter() {
      const data = await debtSolutionServices.getCreditorsByName(
        this.creditorFilter
      );
      this.filters[11].options = [{ data: [...data.data] }];
    },
  },
};
</script>
<style scoped>
.font-small {
  font-size: 0.75em;
}
.item-purple {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}

.item-magenta {
  background-color: #89375f !important;
  border-color: #89375f !important;
}

.item-jade {
  background-color: #00a86b !important;
  border-color: #00a86b !important;
}
.text-warning-status {
  color: #ffd833 !important;
}

.text-success-status {
  color: #008000 !important;
}
</style>
