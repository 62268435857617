<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="tabFilters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
      @reset-all-filters="refreshTable()"
    >
      <template #buttons>
        <div class="ml-2">
          <b-button type="button" variant="primary" @click="allColumns(true)">
            <tabler-icon
              title="SL File"
              :icon="!showAllColumns ? 'Tallymark4Icon' : 'Tallymark2Icon'"
              size="13"
              style="margin-right: 0.5 rem"
              class="align-middle cursor-pointer"
            />
            {{ !showAllColumns ? "All columns" : "Basic columns" }}
          </b-button>
        </div>
      </template>
      <template #table>
        <b-table
          ref="refSettlementsTableRejected"
          id="refSettlementsTable"
          slot="table"
          :items="providerTable"
          small
          :fields="tabFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client)="data">
            <router-link
              target="_blank"
              :class="[textLink]"
              :to="`/debtsolution/clients/account/${data.item.client_account_id}`"
            >
              {{ data.value }}</router-link
            ><br />
            {{ data.item.client_account }}
          </template>
          <template #cell(creditor)="data">
            <span>
              <strong
                class="cursor-pointer"
                :class="[textLink]"
                @click="editCreditorAccount(data.item.creditor_id)"
                >{{ data.item.creditor }}</strong
              >
              <br />
              <span>#{{ data.item.creditor_account }}</span>
            </span>
          </template>
          <template #cell(av_balance)="data">
            <span :class="data.value > 0 ? 'text-primary' : 'text-danger'">
              $ {{ data.value | currencyZero }}</span
            >
          </template>
          <template #cell(offer_amount)="data">
            <span class="text-nowrap"> $ {{ data.value | currencyZero }} </span>
          </template>
          <template #cell(saving)="data">
            <span class="text-nowrap"> $ {{ data.value | currencyZero }} </span>
          </template>
          <template #cell(profit)="data">
            <span class="text-nowrap"> $ {{ data.value | currencyZero }} </span>
          </template>
          <template #cell(offer_created_at)="data">
            <span class="text-nowrap">
              {{ data.value | myGlobal }}
            </span>
          </template>
          <template #cell(payment_date)="data">
            <span class="text-nowrap">
              {{ data.value | myGlobal }}
            </span>
          </template>
          <template #cell(payment_type)="data">
            <div class="w-100 text-center">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
              <br />
              <span
                v-if="data.item.payment_format"
                class="text-nowrap badge"
                :class="
                  data.item.payment_format == 'BY PHONE'
                    ? 'badge-success'
                    : data.item.payment_format == 'MAIL'
                    ? 'badge-warning'
                    : 'badge-primary'
                "
              >
                {{ data.item.payment_format }}
              </span>
            </div>
          </template>
          <template #cell(process)="data">
            <span
              class="text-nowrap badge"
              :class="
                data.value == 'UNDERVIEW'
                  ? 'badge-primary'
                  : data.value == 'VERIFICATION'
                  ? 'badge-warning'
                  : 'badge-info'
              "
            >
              {{ data.value }}
            </span>
          </template>
          <template #cell(porcentage_offer)="data">
            <span class="text-nowrap">
              %
              {{
                parseFloat(
                  (100 * data.item.offer_amount) / data.item.balance
                ).toFixed(2)
              }}
            </span>
          </template>
          <template #cell(tracking)="data">
            <feather-icon
              size="22"
              icon="AlignLeftIcon"
              class="text-primary cursor-pointer"
              @click="openTrackingModal(data.item.id)"
            />
          </template>
          <template #cell(rejected_date)="data">
            <span class="row">{{ data.item.rejected_by_name }}</span>
            <span class="row">{{ data.item.rejected_date | myGlobal }}</span>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              size="22"
              icon="EyeIcon"
              class="text-warning cursor-pointer"
              @click="openOfferModal(data.item)"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <settlement-tracking-modal
      :show="showSettlementTracking"
      :offer="trackingOfferId"
      @closing="showSettlementTracking = false"
    />
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :id-creditor-to-pass="idCreditorToPass"
      :state-modal="stateModal"
      @close="addCreditorModalController = false"
    />
    <settlement-offer-modal
      v-if="showSettlementOffer"
      :offer-info="offerModalInfo"
      :eventId="eventId"
      @closing="closeSettlementModal"
    />
  </div>
</template>

<script>
import SettlementOfferModal from "@/views/debt-solution/views/settlement-offer/SettlementOfferModal.vue";
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";
import SettlementTrackingModal from "@/views/debt-solution/views/settlement-offer/SettlementTrackingModal.vue";
import fields from "../data/field.settlementRejected";
import tabFilters from "../data/filters.settlementRejected";
import settlementsService from "../services/settlements-rejected.service";
import { mapGetters } from "vuex";
export default {
  components: {
    SettlementTrackingModal,
    AddCreditorDsModal,
    SettlementOfferModal,
  },
  data() {
    return {
      showSettlementOffer: false,
      offerModalInfo: {},
      addCreditorModalController: false,
      stateModal: false,
      idCreditorToPass: null,
      showSettlementTracking: false,
      trackingOfferId: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      tabFilters,
      fields,
      totalRows: 0,

      startPage: null,
      toPage: null,
      sortBy: "id",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      items: [],
      showAllColumns: false,
      eventId: null,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    paymentType() {
      return [
        { value: null, label: "All" },
        { value: 1, label: "E-CHECK (CHECKING ACCOUNT)" },
        { value: 2, label: "CHECK (OVERNIGHT)" },
        { value: 3, label: "CASHIER CHECK" },
        { value: 4, label: " MONEY ORDER" },
        // { value: 5, label: " DEBIT/CREDIT CARD" },
      ];
    },
    paymentFormatFilter() {
      return this.tabFilters[4].model;
    },
    creditorFilter() {
      return this.tabFilters[6].model;
    },
    tabFields() {
      if (this.showAllColumns) {
        this.fields.map((item) => {
          item.visible = true;
        });
      } else {
        const visibleColumns = [
          "client",
          "creditor",
          "av_balance",
          "offer_amount",
          "created_by",
          "payment_date",
          "tracking",
          "rejected_by_name",
          "actions",
        ];
        this.fields.map((row, _) => {
          visibleColumns.includes(row.key)
            ? (row.visible = true)
            : (row.visible = false);
        });
      }
      return this.fields.filter((item) => item.visible === true);
    },
  },
  methods: {
    allColumns(frombutton = false) {
      //change value when the tab is completed and click from button
      this.showAllColumns = frombutton
        ? !this.showAllColumns
        : this.showAllColumns;
    },
    closeSettlementModal() {
      this.showSettlementOffer = false;
    },
    openOfferModal(item) {
      this.offerModalInfo.mode = "watch";
      this.offerModalInfo.client = item.client;
      this.offerModalInfo.creditorName = item.creditor;
      this.offerModalInfo.creditorId = item.creditor_id;
      this.offerModalInfo.balance = parseFloat(item.balance).toFixed(2);
      this.offerModalInfo.percentage = item.percentage || 0;
      this.offerModalInfo.id = item.id;
      this.offerModalInfo.clientAccountId = item.client_account_id;
      this.offerModalInfo.creditor_id = item.ds_credit_id;
      this.offerModalInfo.account_name = item.client_account;
      this.offerModalInfo.id_analysis = item.id_analysis;
      this.offerModalInfo.account = item.client_account_id;
      this.offerModalInfo.ssn = item.ssn;
      this.offerModalInfo.itin = item.itin;
      this.offerModalInfo.cpn = item.cpn;
      this.offerModalInfo.dob = item.dob;
      this.offerModalInfo.address = item.address;
      this.eventId = item.event_id;
      this.showSettlementOffer = true;
    },
    editCreditorAccount(id) {
      this.addCreditorModalController = true;
      this.stateModal = 0;
      this.idCreditorToPass = id;
    },
    refreshTable() {
      this.$refs.refSettlementsTableRejected.refresh();
    },
    async providerTable(ctx) {
      const params = {
        field: this.filterPrincipal.model,
        user_id: this.currentUser.user_id,
        advisor_id: this.tabFilters[2].model,
        status: 2,
        result: null,
        process: this.tabFilters[3].model,
        per_page: this.paginate.perPage,
        actual_page: this.paginate.currentPage,
        from: this.tabFilters[0].model,
        to: this.tabFilters[1].model,
        payment_format: this.tabFilters[4].model,
        payment_type: this.tabFilters[5].model,
        creditor_id: this.tabFilters[6].savedValue,
        rejected_from: this.tabFilters[7].model,
        rejected_to: this.tabFilters[8].model,
        sortBy: ctx.sortBy,
        sortOrder: ctx.sortDesc || !ctx.sortBy ? "desc" : "asc",
        rejected_by: this.tabFilters[9].model,
      };
      const res = await settlementsService.searchOfferByStatus(params);
      this.items = res.data;
      this.totalRows = res.total || 0;
      this.startPage = res.from || 0;
      this.toPage = res.to || 0;
      this.paginate.perPage = res.per_page;
      this.paginate.currentPage = res.current_page;

      return this.items;
    },
    async getUsersAdvisor() {
      const { data } = await settlementsService.getAdvisors(this.moduleId);
      this.tabFilters[2].options = data.filter((item) => item.id != 1);
      this.tabFilters[9].options.unshift({
        id: 0,
        value: "System T&I",
      });
      this.tabFilters[9].options = data.filter((item) => item.id != 1);
    },
    openTrackingModal(id) {
      this.trackingOfferId = id;
      this.showSettlementTracking = true;
    },
  },
  watch: {
    paymentFormatFilter() {
      //filter payment type by payment format
      this.tabFilters[5].model = null;
      this.tabFilters[5].options =
        this.paymentFormatFilter === null
          ? this.paymentType
          : this.paymentFormatFilter === 3 || this.paymentFormatFilter === 1
          ? this.paymentType.filter(
              (item) => item.value == 1 || item.value == 5 || item.value == null
            )
          : this.paymentType.filter(
              (item) =>
                (item.value != 1 && item.value != 5) || item.value == null
            );
    },
    async creditorFilter() {
      const data = await settlementsService.getCreditorsByName(
        this.creditorFilter
      );
      this.tabFilters[6].options = [{ data: [...data.data] }];
    },
  },
  async mounted() {
    if (this.currentUser.role_id != 3) {
      this.tabFilters[2].visible = true;
    }
    await this.getUsersAdvisor();
  },
};
</script>

<style>
</style>