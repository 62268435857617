<template>
  <b-modal
    ref="settlement-offer-pending-modal"
    :size="'xl'"
    title="Settlement Offers Pending"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="hidden"
  >
    <SettlementOffersTable :isPending="true" />
  </b-modal>
</template>

<script>
import SettlementOffersTable from "@/views/debt-solution/views/accounts-actions/components/SettlementOffersTable.vue";

export default {
    components: {
        SettlementOffersTable,
    },
  mounted () {
    this.toggleModal("settlement-offer-pending-modal");
  },
  methods: {
    hidden() {
      this.$emit("closing");
    },
  },
};
</script>

<style>
</style>