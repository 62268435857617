<template>
  <b-modal
    ref="modal-tracking"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="PAYMENT ORDER TRACKING"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
  >
    <filter-slot
      :no-visible-principal-filter="true"
      :filter-principal="{}"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
    >
      <template #table>
        <b-table
          primary-key="id"
          ref="refSettlementsTrackingTable"
          striped
          small
          hover
          :items="TrackingProvider"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"

        >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
          <template #cell(client_name)="data">
            <div class="d-flex flex-column">
              <span  :class="[textLink]"  v-if="isAdministration">{{ data.item.client_name }}</span>
              
            <router-link v-else
            :class="[textLink]"
            
              :to="{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.account_id ,
                },
              }"
              target="_blank"
              >
              <span>{{ data.item.client_name }}</span>
              
              </router-link
            >
            <span >{{ data.item.account }} </span>
          </div>
          </template>
          <template #cell(status)="data">
            <tabler-icon
            :icon="`${statusIconProcess(data.item.payment_order_status_id
            )}`"
            size="25"
            :style="`color: ${checkStatusColor(
              data.item.payment_order_status_id, 
              data.item.payment_date
            )};`"
            v-b-tooltip.hover.bottom="
                `${tooltipIconProcess(data.item.payment_order_status_id)}`
              " />
          </template>
          <template #cell(user_name)="data">
            <b-row>
              <b-col>
              {{ data.value }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ data.item.created_at | myGlobalWithHour }}
              </b-col>
            </b-row>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>
<script>

import fields from "@/views/debt-solution/views/settlements-made/data/fieldstracking.settlementsmade.js";
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import { mapGetters } from "vuex";

export default {
  props: {
    pay: {
      type: Number,
    },
  },
  data() {
    return {
      fields,
      items: [],
      totalRows: 0,
      startPage: null,
      toPage: null,
      isBusy: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  computed: {
    isAdministration() {
      return this.$route.matched[0].meta.module == 16;
    },
  },
  methods: {
    async TrackingProvider() {
      try {
        this.addPreloader()
        const params = {
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          payment_order_id: this.pay,
        };
          const data =
          await SettlementsMadeService.getSettlementsMadeMadeTrackingData(
            params
          );
        if (data.status == 200) {
          this.items = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          return this.items || [];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }finally{
        this.removePreloader()
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async refreshTable() {
      this.$refs.refSettlementsTrackingTable.refresh();
    },checkStatusColor(status, payment_date) {
      switch (status) {
        case 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833";
        case 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207";
        case 3:
          return "#ff0000"; // Negotiation not completed
        case 4: // Negotiation completed
          return "#66b70b";
        case 5:
          return "#ffd833"; // PENDING COMPLETION
        case 6:
          return "#54b408"; // COMPLETED
        default:
          break;
      }
    },
    statusIconProcess(status) {
      switch (true) {
        case status >= 1 && status <= 4:
          return "CashIcon";
        case status === 5:
          return "CheckIcon";
        case status === 6:
          return "ChecksIcon";
        default:
          break;
      }
  },tooltipIconProcess(status) {
      switch (status) {
        case 1: // PENDING DEBIT CONFIRMATION
          return "Pending Debit Confirmation";
        case 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "Pending Processing Due To Insufficient Funds";
        case 3:
          return "Negotiation not completed"; // Negotiation not completed
        case 4: // Negotiation completed
          return "Negotiation completed";
        case 5:
          return "Pending Completion"; // PENDING COMPLETION
        case 6:
          return "Completed"; // COMPLETED
        default:
          break;
      }
    },
},
  mounted() {
    this.toggleModal("modal-tracking");
    
  },
  async created() {},
};
</script>
