<template>
  <b-card no-body class="mb-2">
    <filter-slot
      :filter="[]"
      :filter-principal="filterMain"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
    >
      <template #table>
        <b-table
          ref="transferComplete"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          small
          responsive="sm"
          :items="tableProvider"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          sticky-header="50vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <router-link
              :class="[textLink]"
              :to="{
                name: 'debt-solution-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              }"
              target="_blank"
            >
              <span>
                {{ data.item.client_name }}
              </span>
            </router-link>
            <br />
            <span>{{ data.item.account }}</span>
          </template>
          <template #cell(creditor)="data">
            {{ data.item.creditor }} <br />
            <span
              v-if="
                data.item.name_parent != null &&
                data.item.creditor != data.item.name_parent
              "
            >
              ({{ data.item.name_parent }})<br
            /></span>
            #{{ data.item.creditor_account }}
          </template>

          <template #cell(collection)="data">
            <span>
              <strong>{{ data.item.collection }}</strong>
              <br />
              <span v-if="data.item.collection_account"
                >#{{ data.item.collection_account }}</span
              >
            </span>
          </template>

          <template #cell(offer_amount)="data">
            <span>{{ data.item.offer_amount | myMoney }}</span>
          </template>

          <template #cell(check_number)="data">
            <span>
              <strong>#{{ data.item.transfer_number }}</strong>
            </span>
          </template>

          <template #cell(check_date)="data">
            <span>{{ data.item.transfer_date | myGlobal }}</span>
          </template>
          <template #cell(check_amount)="data">
            {{ data.item.transfer_amount | myMoney }}
          </template>

          <template #cell(transfer_updated_by)="data">
            {{ data.item.transfer_updated_by }}<br />
            <span>{{ data.item.transfer_updated_at | myGlobalDay }}</span>
          </template>

          <template #bottom-row>
            <b-th
              colspan="6"
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            ></b-th>
            <b-th colspan="2" style="padding: 0 !important">
              <b-row class="m-0 p-0">
                <b-col
                  cols="6"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                  style="font-size: 1rem !important"
                >
                  SUB-TOTAL
                </b-col>
                <b-col
                  cols="6"
                  class="widthRecovery text-right p-0 bg-gradient-primary"
                  style="
                    padding-right: 5px !important;
                    font-size: 1rem !important;
                  "
                >
                  {{ "$ " }}
                  {{ subTotal | currencyZero }}
                </b-col>
              </b-row>
              <b-row class="m-0 p-0">
                <b-col
                  cols="6"
                  class="widthRecovery text-center p-0 bg-gradient-primary"
                  style="font-size: 1rem !important"
                >
                  TOTAL
                </b-col>
                <b-col
                  cols="6"
                  class="widthRecovery p-0 text-right bg-gradient-primary"
                  style="
                    padding-right: 5px !important;
                    font-size: 1rem !important;
                  "
                >
                  {{ "$ " }}
                  {{ total | currencyZero }}
                </b-col>
              </b-row>
            </b-th>
            <b-th
              colspan="1"
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            ></b-th>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import fields from "@/views/debt-solution/views/accounts-actions/fields/fields-transfer-complete.js";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
export default {
  components: {},
  props: {},
  data() {
    return {
      fields,
      totalRows: 0,
      filterMain: {
        type: "input",
        inputType: "text",
        placeholder: "Search Account...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 1,
      toPage: 2,
      offerPaymentOrderId: null,
      subTotal: 0,
      total: 0,
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async tableProvider() {
      const params = {
        page: this.paginate.currentPage,
        per_page: this.paginate.perPage,
        name_text: this.filterMain.model,
      };
      const { data } = await AccountsActionsService.getTransfersComplete(
        params
      );
      this.totalRows = data.total || 0;
      this.toPage = data.to || 0;
      this.startPage = data.from || 0;
      this.paginate.perPage = data.per_page;
      this.paginate.currentPage = data.current_page;
      if (data.data.length > 0) {
        this.subTotal = data.data[0].sub_total;
        this.total = data.data[0].total;
      }
      return data.data || [];
    },
    refreshTable() {
      this.$refs.transferComplete.refresh();
    },
    async confirmationTransfer(id) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const params = {
            id: id,
          };
          const { data } = await AccountsActionsService.transferAccepted(
            params
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.refreshTable();
        }
      }
    },
  },
};
</script>
<style lang="scss" >
.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
  .bg-dark {
    background-color: #17161a !important;
  }
  .bg-light {
    background-color: #ffffff !important;
  }
}
</style>