<template>
  <b-modal
    ref="modalCheck"
    id="modal-xl"
    size="xmd"
    title="View Check"
    title-tag="h3"
    @hidden="hideModal"
    hide-footer
    scrollable
  >
    <div>
      <div
        class="d-flex justify-content-between align-items-center"
        style="margin-left: 4rem; margin-right: 4rem; margin-top: 2rem"
      >
        <div style="width: 100%">
          <b-button
            variant="primary"
            @click="changeTextCollapse"
            v-b-toggle.collapse-2
            class="m-1"
            >{{ toggleCollapseText }}</b-button
          >
        </div>
        <div>
          <b-row style="flex-wrap: nowrap">
            <b-col xl="6" style="width: 100px">
              <b-form-group
                label="SL File"
                label-size="sm"
                label-class="font-weight-bold"
                class="mb-0 d-flex flex-nowrap text-center"
                style="align-items: center"
              >
                <a
                  v-if="clientCreditorCheck.pdf"
                  :href="clientCreditorCheck.pdf"
                  target="_blank"
                >
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    title="SL File"
                    icon="FileIcon"
                    size="22"
                    class="text-primary cursor-pointer scale"
                    style="margin-left: 10px"
                  />
                </a>
                <feather-icon
                  v-else
                  v-b-tooltip.hover.bottom
                  title="Not SL File"
                  icon="FileIcon"
                  size="22"
                  class="align-middle text-body"
                />
              </b-form-group>
            </b-col>
            <b-col
              xl="6"
              style="
                display: flex;
                justify-content: space-between;
                align-items: self-end;
              "
            >
              <b-form-group
                label="PIF"
                label-size="sm"
                label-class="font-weight-bold pt-0"
                class="mb-0 d-flex justify-content-center text-center"
              >
                <a v-if="checkData.tif" :href="checkData.tif" target="_blank">
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    title="PIF File"
                    icon="FileTextIcon"
                    size="22"
                    class="align-middle text-success cursor-pointer scale"
                  />
                </a>

                <feather-icon
                  v-else-if="
                    enablePifUpload(
                      checkData.tif,
                      clientCreditorCheck.id_status,
                      clientCreditorCheck.user_id
                    )
                  "
                  v-b-tooltip.hover.bottom="`${validateIcon}`"
                  class="cursor-pointer"
                  :style="`color: ${checkStatusColor(
                    clientCreditorCheck.id_status,
                    clientCreditorCheck.payment_is_due_within_three_days
                  )};`"
                  :icon="isTabCompleted ? 'CheckIcon' : 'UploadIcon'"
                  size="22"
                  @click="
                    openPifModal(
                      clientCreditorCheck.offer_payment_order_id,
                      clientCreditorCheck.account,
                      checkData.tif,
                      clientCreditorCheck.creditor_name,
                      clientCreditorCheck.client_name,
                      clientCreditorCheck.advisor_name
                    )
                  "
                />
                <feather-icon
                  v-else
                  v-b-tooltip.hover.bottom
                  title="Not PIF File"
                  icon="FileTextIcon"
                  size="22"
                  class="align-middle text-danger cursor-pointer scale"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-collapse id="collapse-2">
        <more-data :clientCreditorCheck="clientCreditorCheck" />
      </b-collapse>
    </div>

    <PhysicalVirtualCheckPayment
      v-if="isACheck"
      :checkData="checkData"
      :previewMode="false"
    />
    <CashierCheckMoneyOrderPayment
      v-else
      :offerData="clientCreditorCheck"
      :checkData="checkData"
      :previewMode="false"
    />

    <pif-modal
      v-if="pifModal"
      :account_id="account_id"
      :payment_order_id="paymentOrder"
      :tif_data="tifdata"
      :creditor="creditor_name"
      :client="client_name"
      :advisor="advisor_name"
      @close="closePifModal"
      @reload="(data) => refreshData(data)"
    />
  </b-modal>
</template>
<script>
import moment from "moment";
import SettlementsmadeServices from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import { mapGetters } from "vuex";
import PifModal from "@/views/debt-solution/views/settlements-made/components/subcomponents/AddPifModal.vue";
import SettlementsMadeService from "../../services/SettlementsMade.service.js";
import MoreData from "@/views/debt-solution/views/settlements-made/components/subcomponents/MoreData.vue";
import CashierCheckMoneyOrderPayment from "@/views/debt-solution/views/settlements-made/components/subcomponents/CashierCheckMoneyOrderPayment.vue";
import PhysicalVirtualCheckPayment from "@/views/debt-solution/views/settlements-made/components/subcomponents/GenerateChecks.vue";

export default {
  components: {
    PifModal,
    MoreData,
    CashierCheckMoneyOrderPayment,
    PhysicalVirtualCheckPayment,
  },
  props: {
    checkData: {
      type: Object,
      required: true,
    },
    clientCreditorCheck: {
      type: Object,
      required: true,
    },
    isACheck: {
      type: Boolean,
    },
  },
  created() {},
  data() {
    return {
      preOperationNumber: null,
      openModal: false,
      pifModal: false,
      toggleCollapseText: null,
    };
  },
  mounted() {
    this.toggleModal("modalCheck");
    this.toggleCollapseText = "Show Data";
  },
  methods: {
    async updatePreOperationNumber() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      if (!this.preOperationNumber) {
        this.$swal
          .fire({
            icon: "success",
            title: "Success",
            text: "Successful Process",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
            closeOnClickOutside: false,
          })
          .then(() => {
            this.$emit("finishGeneratedCheckProcess");
          });
        return;
      }
      try {
        await SettlementsmadeServices.updatePaymentOrderPreoperationNumber({
          payment_order_id: this.checkData.payment_order_id,
          pre_operation_number: this.preOperationNumber,
        });
        this.$swal
          .fire({
            icon: "success",
            title: "Success",
            text: "Successful Process",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
            closeOnClickOutside: false,
          })
          .then(() => {
            this.$emit("finishGeneratedCheckProcess");
          });
      } catch (err) {
        console.error(er);
      }
    },
    hideModal() {
      // ocultar modal toggle
      this.$emit("closeModal");
    },
    closePifModal() {
      this.pifModal = false;
      this.$emit("reload");
    },
    async openPifModal(
      payment_order_id,
      account_id,
      data_tif,
      creditor_name,
      client_name,
      advisor_name
    ) {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      this.pifModal = true;
      this.creditor_name = creditor_name;
      this.client_name = client_name;
      this.advisor_name = advisor_name;
      this.paymentOrder = payment_order_id;
      this.account_id = account_id;
      this.tifdata = data_tif;
    },
    enablePifUpload(tif, status, user_id) {
      const existsPif = tif != "" && !!tif;
      return (
        (this.isTabMade &&
          status === 4 &&
          !existsPif &&
          (this.isCeo ||
            this.isSupervisor ||
            user_id === this.currentUserId)) ||
        (this.isTabCompleted &&
          status === 5 &&
          existsPif &&
          (this.isCeo || this.isSupervisor))
      );
    },
    checkStatusColor(status, is_past_due_payment_date) {
      switch (true) {
        case status === 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833";
        case status === 2 && is_past_due_payment_date === 1:
          return "#ff0000";
        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207";
        case status === 3: // Negotiation not completed
          return "#ff0000";
        case status === 4: // Negotiation completed
          return "#66b70b";
        case status === 5: // PENDING COMPLETION
          return "#ffd833";
        case status === 6: // COMPLETED
          return "#54b408";
        default:
          break;
      }
    },
    changeTextCollapse() {
      if (this.toggleCollapseText == "Show Data") {
        this.toggleCollapseText = "Hide Data";
      } else {
        this.toggleCollapseText = "Show Data";
      }
    },
    async refreshData(data) {
      this.$emit("reload");
      const params = {
        offer_payment_order_id: data,
      };
      try {
        this.addPreloader();
        const dataCheck = await SettlementsMadeService.getCheck(params);
        this.$emit("refreshModalCheck", dataCheck[0]);
      } catch (error) {
        console.log(error);
      } finally {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
  },
  filters: {
    nroCheck: function (value) {
      return "#" + value;
    },
    longDate: function (value) {
      return moment(value).format("MMMM Do YYYY");
    },
  },
  computed: {
    validateIcon() {
      return this.isTabCompleted ? "Finalize Payment Order" : "Upload PIF";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
    isAdvisor() {
      return this.currentUser.role_id === 3;
    },
    tabFields() {
      return this.fields.filter((item) => item.visible === true);
    },
    isTabPending() {
      return this.$route.meta.tab == 1;
    },
    isTabCompleted() {
      return this.$route.meta.tab == 3;
    },
    isTabMade() {
      return this.$route.meta.tab == 2;
    },
    tabFilters() {
      return this.filters.filter((item) => item.visible === true);
    },
    creditorFilter() {
      return this.filters[11].model;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300;500;900&display=swap");
.ligth-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px #0090e7 !important;
  border-radius: 2px !important;
}
.dark-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px rgba(255, 255, 255, 0.90127801120448177) !important;
  border-radius: 2px !important;
}
</style>