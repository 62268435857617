<template>
  <div>
    <b-card
      class="box-shadow-0 pl-3 pt-2 pb-0 pr-3"
      :style="'background-color:transparent !important;'"
    >
      <div class="mb-3" v-if="previewMode">
        <b-row>
          <b-col
            sm="12"
            md="3"
            xl="3"
            class="pt-1 pb-1 d-flex justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end align-items-center"
          >
            <h5
              for="input-small"
              v-if="offerData.payment_method != 'CHECK (OVERNIGHT)'"
            >
              Pre-operation number:
            </h5>
          </b-col>
          <b-col sm="12" md="6" xl="6" class="pt-1 pb-1">
            <b-form-input
              v-if="offerData.payment_method != 'CHECK (OVERNIGHT)'"
              v-model="preOperationNumber"
              type="text"
              id="nro_operacion"
              placeholder=""
            ></b-form-input>
          </b-col>
          <b-col
            sm="12"
            :md="offerData.payment_method == 'CHECK (OVERNIGHT)' ? '12' : '3'"
            :xl="offerData.payment_method == 'CHECK (OVERNIGHT)' ? '12' : '3'"
            class="pt-1 pb-1 d-flex justify-content-center align-items-center"
            :class="
              offerData.payment_method == 'CHECK (OVERNIGHT)'
                ? 'justify-content-lg-center'
                : 'justify-content-lg-start justify-content-xl-start justify-content-md-start'
            "
          >
            <b-button variant="primary" @click="generateOfferPaymentOrder">
              Generate
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div
        class="p-2 rounded-0"
        :class="isDarkSkin ? 'dark-theme' : 'ligth-theme'"
      >
        <b-row>
          <b-col class="p-1" sm="12" md="6" xl="9">
            <b-row>
              <b-col cols="auto" class="pr-0">
                <amg-icon
                  icon="AmgIcon"
                  size="60"
                  class="ml-2"
                  :class="isDarkSkin ? 'text-light' : 'text-dark'"
                />
              </b-col>
              <b-col class="d-flex flex-column p-0 mt-auto mb-auto ml-1">
                <small class="">ACCOUNT NAME : {{ checkData.bank_account_name }} </small>
                <small>ADDRESS : {{ checkData.bank_account_address }}</small>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="p-1" sm="12" md="6" xl="3">
            <b-row class="pl-1 pr-1">
              <b-col sm="12 text-right">
                <strong :style="'font-size:14px !important;'">{{
                  checkData.check_number | nroCheck
                }}</strong>
              </b-col>
              <b-col
                sm="12 text-right pt-1 pb-1 border-bottom border-right"
                :style="'border-width:2px !important;border-color: #AEB6BF !important;border-radius:0px 0px 20px 0px !important;'"
              >
                <span
                  :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
                  >{{ showDate }}</span
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="pl-1 pr-1">
          <b-col
            sm="12"
            md="7"
            lg="8"
            xl="10"
            class="p-1 border-bottom"
            :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
          >
            <strong
              class="mr-1 text-uppercase"
              :style="'font-size:14px !important;'"
              >Pay to the order of</strong
            >
            <span
              :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
              class="text-uppercase"
              >{{ checkData.bank_account_name }}
            </span>
          </b-col>
          <b-col
            sm="12"
            md="5"
            lg="4"
            xl="2"
            class="d-flex justify-content-center align-items-center p-1 border"
            :style="'border-width:2px !important;border-color: #AEB6BF !important;border-radius:20px !important;'"
          >
            <strong
              :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
              >$ {{ checkData.payment_order_amount }}</strong
            >
          </b-col>
        </b-row>
        <b-row class="pl-1 pr-1">
          <b-col
            sm="12"
            md="7"
            xl="9"
            class="pt-1 pb-1 pl-2 pr-2 border-bottom"
            :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
          >
            <span
              :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
              class="text-uppercase"
              >{{ amountInText }}</span
            >
          </b-col>
          <b-col class="d-flex justify-content-center align-items-end mt-1">
            <strong class="text-uppercase" :style="'font-size:14px !important;'"
              >Dollars</strong
            >
          </b-col>
        </b-row>
        <b-row class="pl-1 pr-1">
          <b-col
            sm="12"
            md="12"
            lg="7"
            xl="9"
            class="p-1 border-bottom"
            :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
          >
            <strong
              class="mr-1 text-uppercase"
              :style="'font-size:14px !important;'"
              >MEMO</strong
            >
            <span
              :style="'font-size:14px !important;font-family: Noto Serif KR, serif;'"
              >{{
                checkData.account_list_credits || "MEMO DESCRIPTION GOES HERE"
              }}</span
            >
          </b-col>
        </b-row>
        <b-row class="pl-1 pr-1 mt-1">
          <b-col sm="12" md="12" lg="4" xl="4">
            <b-row class="text-center pr-2">
              <b-col
                cols="12 border-bottom pb-1"
                :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
              >
                <span
                  :style="'font-size:15px !important;font-family: Noto Serif KR, serif;'"
                  >{{ checkData.bank_routing_number }}</span
                >
              </b-col>
              <b-col cols="12" :style="'padding-top:8px'">
                <strong
                  :style="'font-size:14px !important;'"
                  class="text-uppercase"
                  >Routing Number</strong
                >
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12" md="12" lg="4" xl="4">
            <b-row class="text-center pl-3 pr-3">
              <b-col
                cols="12 border-bottom pb-1"
                :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
              >
                <span
                  :style="'font-size:15px !important;font-family: Noto Serif KR, serif;'"
                  >{{ checkData.bank_account_number }}</span
                >
              </b-col>
              <b-col cols="12" :style="'padding-top:8px'">
                <strong
                  :style="'font-size:14px !important;'"
                  class="text-uppercase"
                  >Account Number</strong
                >
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12" md="12" lg="4" xl="4">
            <b-row class="text-center">
              <b-col
                sm="12 border-bottom pb-1"
                :style="'border-width:2px !important;border-color: #AEB6BF !important;'"
              >
                <span
                  :style="'font-size:15px !important;font-family: Noto Serif KR, serif;'"
                  >{{ checkData.check_number | nroCheck }}</span
                >
              </b-col>
              <b-col sm="12" :style="'padding-top:8px'">
                <strong
                  :style="'font-size:14px !important;'"
                  class="text-uppercase"
                  >Check Number</strong
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import numbersToLetters from "@/views/debt-solution/views/settlements-made/formats/numbersToLetters";
import moment from "moment";
import SettlementsmadeServices from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";

export default {
  props: {
    checkData: {
      type: Object,
      required: true,
    },
    offerData: {
      type: Object,
    },
    previewMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentDate: moment().format("MMMM Do YYYY"),
      amountInText: null,
      preOperationNumber: null,
    };
  },
  created() {
    this.amountInText = numbersToLetters(this.checkData.payment_order_amount);
  },
  methods: {
    async generateOfferPaymentOrder() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      try {
        this.addPreloader();
        const data = await SettlementsmadeServices.generateOfferPaymentOrder({
          ...this.offerData,
          pre_operation_number: this.preOperationNumber,
        });
        this.removePreloader();
        const has_available_balance = data.has_available_balance === 1;
        const checkbook_found = data.checkbook_found === 1;
        const _state_check_generated = data._state_check_generated;
        const is_two_days_or_less_to_payment_date =
          data.is_two_days_or_less_to_payment_date === 1;

        switch (true) {
          case !has_available_balance:
            this.showWarningSwal(
              "Check not generated",
              "The check could not be generated due to insufficient funds."
            );
            this.$emit("paymentOrderGeneratedWithCheck", {
              checkWasCreated: false,
            });
            break;
          case has_available_balance && !checkbook_found:
            this.showWarningSwal(
              "Payment order process failed",
              "Important: A checkbook for this payment type needs to be added, please inform your supervisor."
            );
            this.$emit("paymentOrderGeneratedWithCheck", {
              checkWasCreated: true,
              check_number: data.check_number,
            });
            break;
          case has_available_balance &&
            checkbook_found &&
            _state_check_generated == 0:
            this.showWarningSwal(
              "Out of checks",
              "A payment order has been generated but out of checks"
            );
            break;
          case has_available_balance &&
            checkbook_found &&
            _state_check_generated != 0 &&
            !is_two_days_or_less_to_payment_date:
            this.showSuccessSwal(
              "A payment order and its respective check have been created.",
              "Success"
            );
            this.$emit("paymentOrderGeneratedWithCheck", {
              checkWasCreated: true,
              check_number: data.check_number,
            });
            break;
          case has_available_balance &&
            checkbook_found &&
            _state_check_generated != 0 &&
            is_two_days_or_less_to_payment_date:
            this.showInfoSwal(
              "A payment order and its respective check have been created but it's missing two days or less to the payment date"
            );
            this.$emit("paymentOrderGeneratedWithCheck", {
              checkWasCreated: true,
              check_number: data.check_number,
            });
            break;
          default:
            break;
        }
      } catch (err) {
        this.showErrorSwal(err);
        console.error(err);
      } finally {
        this.removePreloader();
      }
    },
  },
  filters: {
    nroCheck: function (value) {
      if (!!value) return "# " + value;
      return "# 000000";
    },
  },
  computed: {
    showDate() {
      return this.previewMode ? this.currentDate : this.checkData.created_at;
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@300;500;900&display=swap");
.ligth-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px #0090e7 !important;
  border-radius: 2px !important;
}
.dark-theme {
  background: linear-gradient(
    343deg,
    rgba(0, 144, 231, 0.1825105042016807) 8%,
    rgba(255, 255, 255, 0.10127801120448177) 71%
  );
  box-shadow: 0.2px 0.2px 2px 0px rgba(255, 255, 255, 0.90127801120448177) !important;
  border-radius: 2px !important;
}
</style>
