<template>
  <div class="mt-1"> 
    <b-nav card-header pills class="m-0 ml-1">   
      <b-nav-item
        :to="{ name: 'debtsolution-settlements-finished-amg' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        AMG
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'debtsolution-settlements-finished-client' }"
        exact
        exact-active-class="sub-tab-nav"
        :link-classes="[
          currentUser.modul_id !== 5 ? '' : 'sub-tab-nav',
          'px-3',
          bgTabsNavs,
        ]"
      >
        CLIENT
      </b-nav-item>
    </b-nav>
    <SettlementOffersPendingModal
      v-if="showPendingModal"
      @closing="closeSettlemenOffersPendingtModal"
    />

    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view ref="componentSettlements" :key="$route.name" />
    </b-card>
 
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import CreateSettlementGridModal from "@/views/debt-solution/views/settlements/components/CreateSettlementGridModal.vue";
import SettlementOffersPendingModal from "@/views/debt-solution/views/settlements/components/SettlementOffersPendingModal.vue";
import Indicators from "@/views/debt-solution/views/settlements-made/components/subcomponents/Indicators.vue";
export default {
  components: {
    CreateSettlementGridModal,
    SettlementOffersPendingModal, 
    Indicators,
  },
  data() {
    return {
      modalCreateSettlementOn: false,
      showPendingModal: false,
      summary: {}, 
    };
  },
  computed: {
    ...mapState({
      S_COUNTER_SETTLEMENTS: (state) =>
        state.DebtSolutionSettlementOfferStore.S_COUNTER_SETTLEMENTS,
      S_COUNTER_SETTLEMENT_TOTAL: (state) =>
        state.DebtSolutionSettlementOfferStore.S_COUNTER_SETTLEMENT_TOTAL,
      S_COUNTER_OFFERS_PENDING: (state) =>
        state.DebtSolutionSettlementOfferStore.S_COUNTER_OFFERS_PENDING,
      S_COUNTER_PRIORITY_ORDER_ALERT: (state) =>
        state.S_COUNTER_PRIORITY_ORDER_ALERT,
      COUNT_NOT_COMPLETE: (state) =>
        state.DebtSolutionSettlementOfferStore.S_COUNT_NOT_COMPLETE,

      CONFIRMED_PAYMENTS_TOTAL: (state) =>
        state.DebtSolutionSettlementOfferStore.S_CONFIRMED_PAYMENTS_TOTAL,
      PENDING_TO_BE_PAID_TOTAL: (state) =>
        state.DebtSolutionSettlementOfferStore.S_PENDING_TO_BE_PAID_TOTAL,
      PAYMENTS_TO_BE_CONFIRMED_TOTAL: (state) =>
        state.DebtSolutionSettlementOfferStore.S_PAYMENTS_TO_BE_CONFIRMED_TOTAL,
      REJECTED_PAYMENTS_TOTAL: (state) =>
        state.DebtSolutionSettlementOfferStore.S_REJECTED_PAYMENTS_TOTAL,
      S_COUNTER_TRANSFER_PENDING: (state) =>
        state.DebtSolutionSettlementOfferStore.S_COUNTER_TRANSFER_PENDING,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNTER_SETTLEMENTS:
        "DebtSolutionSettlementOfferStore/G_COUNTER_SETTLEMENTS",
      G_COUNTER_OFFERS_PENDING:
        "DebtSolutionSettlementOfferStore/G_COUNTER_OFFERS_PENDING",
      G_COUNTER_UNFINISHED:
        "DebtSolutionSettlementOfferStore/G_COUNTER_UNFINISHED",
      G_COUNTER_TRANSFER_PENDING:
        "DebtSolutionSettlementOfferStore/G_COUNTER_TRANSFER_PENDING",
    }),
  },
  created() {},
  mounted() {
    this.A_COUNTER_SETTLEMENTS({
      role_id: this.currentUser.role_id,
      user_id: this.currentUser.user_id,
    });
    this.A_COUNTER_OFFERS_PENDING({
      user_id: this.currentUser.user_id,
    });
    this.A_COUNTER_UNFINISHED();
    //this.A_COUNTER_TRANSFER_PENDING();

    this.reloadIndicators();
  },
  methods: { 
    showCreateSettlement() {
      this.modalCreateSettlementOn = true;
    },
    ...mapActions({
      A_COUNTER_SETTLEMENTS:
        "DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS",
      A_COUNTER_OFFERS_PENDING:
        "DebtSolutionSettlementOfferStore/A_COUNTER_OFFERS_PENDING",
      GET_PAYMENT_STATUS_TOTAL:
        "DebtSolutionSettlementOfferStore/A_GET_PAYMENT_STATUS_TOTAL",
      A_COUNTER_UNFINISHED:
        "DebtSolutionSettlementOfferStore/A_COUNTER_UNFINISHED",
      A_COUNTER_TRANSFER_PENDING:
        "DebtSolutionSettlementOfferStore/A_COUNTER_TRANSFER_PENDING",
    }),
    closeSettlemenOffersPendingtModal() {
      this.showPendingModal = false;
    },
    async reloadIndicators() {
      await this.GET_PAYMENT_STATUS_TOTAL();
      this.summary = {
        confirmed_payments: this.CONFIRMED_PAYMENTS_TOTAL,
        pending_to_be_paid: this.PENDING_TO_BE_PAID_TOTAL,
        payments_to_be_confirmed: this.PAYMENTS_TO_BE_CONFIRMED_TOTAL,
        rejected_payments: this.REJECTED_PAYMENTS_TOTAL,
      };
    },
    showTransferPending(){
      this.showTransferPendingModal = true;
    },
  },
  watch: {
    CONFIRMED_PAYMENTS_TOTAL: function (val) {
      this.summary.confirmed_payments = val;
    },
    PENDING_TO_BE_PAID_TOTAL: function (val) {
      this.summary.pending_to_be_paid = val;
    },
    PAYMENTS_TO_BE_CONFIRMED_TOTAL: function (val) {
      this.summary.payments_to_be_confirmed = val;
    },
    REJECTED_PAYMENTS_TOTAL: function (val) {
      this.summary.rejected_payments = val;
    },
  },
};
</script>
