var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-tracking",attrs:{"modal-class":"modal-primary","title-class":"h3 text-white","title":"PAYMENT ORDER TRACKING","size":"lg","hide-footer":"","centered":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('filter-slot',{attrs:{"no-visible-principal-filter":true,"filter-principal":{},"filter":[],"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.refreshTable()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refSettlementsTrackingTable",attrs:{"primary-key":"id","striped":"","small":"","hover":"","items":_vm.TrackingProvider,"fields":_vm.fields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [_c('div',{staticClass:"d-flex flex-column"},[(_vm.isAdministration)?_c('span',{class:[_vm.textLink]},[_vm._v(_vm._s(data.item.client_name))]):_c('router-link',{class:[_vm.textLink],attrs:{"to":{
              name: 'debt-solution-dashboard',
              params: {
                idClient: data.item.account_id ,
              },
            },"target":"_blank"}},[_c('span',[_vm._v(_vm._s(data.item.client_name))])]),_c('span',[_vm._v(_vm._s(data.item.account)+" ")])],1)]}},{key:"cell(status)",fn:function(data){return [_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(
              ("" + (_vm.tooltipIconProcess(data.item.payment_order_status_id)))
            ),expression:"\n              `${tooltipIconProcess(data.item.payment_order_status_id)}`\n            ",modifiers:{"hover":true,"bottom":true}}],style:(("color: " + (_vm.checkStatusColor(
            data.item.payment_order_status_id, 
            data.item.payment_date
          )) + ";")),attrs:{"icon":("" + (_vm.statusIconProcess(data.item.payment_order_status_id
          ))),"size":"25"}})]}},{key:"cell(user_name)",fn:function(data){return [_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(data.value)+" ")])],1),_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")])],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }