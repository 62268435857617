export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Offer Created From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Offer Created To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Offer Approved From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Offer Approved To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: " Payment Order Finished From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Payment Order Finished  To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Payment Date From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Payment Date To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Payment Order Status",
    model: null,
    options: [
      {
        value: 1,
        label: 'PENDING DEBIT CONFIRMATION',
        icon: "CashIcon",
        color: "#ffd833"
      },
      {
        value: 2,
        label: "PENDING PROCESSING DUE TO INSUFFICIENT FUNDS",
        icon: "CashIcon",
        color: "#ff6207"
      },
      {
        value: 3,
        label: "NEGOTIATION NOT COMPLETED",
        icon: "CashIcon",
        color: '#ff0000'
      },
      {
        value: 4,
        label: "NEGOTIATION COMPLETED",
        icon: 'CashIcon',
        color: "#66b70b"
      },
      {
        value: 5,
        label: "PENDING COMPLETION",
        icon: 'CheckIcon',
        color: "#ffd833"
      },
      {
        value: 6,
        label: "COMPLETED",
        icon: 'ChecksIcon',
        color: "#54b408"
      },
      {
        value: 7,
        label: "PENDING PROCESSING DUE TO INSUFFICIENT FUNDS (URGENT)",
        icon: "ClockIcon",
        color: '#ff0000'
      },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Negotiator",
    model: null,
    options: [],
    reduce: "id",
    selectText: "value",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      {
        value: 0,
        label: "AMG",
      },
      {
        value: 1,
        label: "CLIENT",
      },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false,
  },
  {
    type: 'suggest',
    margin: true,
    showLabel: true,
    label: 'Creditor',
    model: null,
    placeholder: 'Select',
    savedValue: null,
    showText: 'Select',
    visible: true,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
  

  },
  {
    type: 'select',
    label: 'Payment Type',
    margin: true,
    showLabel: true,
    options: [
      { value: 1, label: 'BY PHONE' },
      { value: 2, label: 'MAIL' },
      { value: 3, label: 'ONLINE' },

    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    label: 'Offer Status',
    margin: true,
    showLabel: true,
    options: [
      { value: 1, label: 'REQUEST' },
      { value: 2, label: 'VERIFICATION' },
      { value: 3, label: 'UNDERVIEW' },

    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 6,
    visible: true,
  },


];
