<template>
  <div class="w-100">
    <!-- <b-modal
      class="p-2"
      ref="PurchaseOrderModal"
      hide-footer
      title="NEW PURCHASE ORDER"
      @hidden="$emit('hidden')"
    > -->
    <b-card no-wrap class="border ml-3 mr-3 mt-3" header-bg-variant="info">
      <template #header>
        <span class="text-white"> PURCHASE ORDER </span></template>
      <ValidationObserver ref="form">
        <!-- OPERATION NUMBER && DATE -->
        <b-row class="mt-2">
          <b-col cols="6">
            <ValidationProvider v-slot="{ errors }" rules="customRequired">
              <b-form-group label="Operation Number">
                <b-form-input
                  :class="errors[0]"
                  class="form-control"
                  v-model="opNumber"
                  maxlength="16"
                  @input="sendParams"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col cols="6">
            <ValidationProvider v-slot="{ errors }" rules="customRequired">
              <b-form-group label="Date" class="m-0 p-0">
                <b-form-datepicker
                  style=""
                  v-model="opDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  @input="sendParams"
                  locale="en"
                />
                <span v-if="errors[0]" class="text-danger">
                  Start date {{ errors[0] }}</span
                >
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- OTHER_PAYMENT NUMBER && AMOUNT -->
        <b-row>
          <b-col cols="6">
            <ValidationProvider v-slot="{ errors }" rules="required">
              <b-form-group label="Account">
                <v-select
                  :class="errors[0]"
                  v-model="selectAccount"
                  :options="dataAccounts"
                  :reduce="dataAccounts.id"
                  @input="sendParams"
                >
                </v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="6">
            <ValidationProvider>
              <b-form-group>
                <template #label>
                  <span> Amount </span>
                  <span class="text-warning"> * </span>
                </template>
                <money
                  v-bind="money"
                  v-model="amount"
                  class="form-control"
                  :disabled="true"
                >
                </money>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VueMask from "v-mask";
import { Money } from "v-money";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import SettlementService from "@/views/debt-solution/views/settlements/services/settlement.service";

import vSelect from "vue-select";
extend("customRequired", {
  ...required,
  message: "is-invalid",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueMask,
    Money,
  },
  directives: {
    money: Money,
  },
  data() {
    return {
      params: {},
      noCorrespondenceAccounts: false,
      selectAccount: "",
      opNumber: "",
      opDate: new Date().toISOString().substr(0, 10),
      money: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        maxlenght: 16,
        prefix: "$ ",
      },
      amount: 0,
      typeCode: 1,
      dataAccounts: [],
    };
  },
  props: {
    itemData: {
      type: Object,
    },
  },
  methods: {
    sendParams() {
      this.params = {
        opNumber: this.opNumber,
        opDate: this.opDate,
        selectAccount: this.selectAccount,
      };
      this.$emit("sendParams", this.params);
    },
    async validateFields() {
      const state = await this.$refs.form.validate();
      if (state) {
        this.$emit("validateOk");
      }
    },
    async getCorrespondenceAccounts() {
      try {
        let { data } = await SettlementService.getCorrespondenceAccounts();
        this.dataAccounts = data;
        if (!this.dataAccounts) {
          this.noCorrespondenceAccounts = true;
        }
      } catch (error) {
        this.showErrorSwal("Correspondence accounts not get...");
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.getCorrespondenceAccounts();
    this.amount = this.itemData.offer_amount;
  },
};
</script>