     <template>
  <b-card
    class="mx-4 mt-2 no-shadow"
    :style="isDarkSkin ? 'background: #222222' : 'background: #f5f5f5'"
  >

    <b-form-group
      label-cols-lg="2"
      label="CLIENT DATA"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
      style="padding: 10px; margin: 10px"
    >
      <b-row>
        <b-col sm="12" md="6" xl="6" class="mb-1">
          <b-form-group
            label="Client Name"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.client_name"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col 
        v-if="clientCreditorCheck.ssn"
        sm="12" md="6" xl="2" class="mb-1">
          <b-form-group
            label="SSN"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.ssn"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col 
        v-if="clientCreditorCheck.itin"
        sm="12" md="6" xl="2" class="mb-1">
          <b-form-group
            label="ITIN"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.itin"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col 
        v-if="clientCreditorCheck.cpn"
        sm="12" md="6" xl="2" class="mb-1">
          <b-form-group
            label="CPN"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.cpn"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="DOB"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-datepicker
              :style="isDarkSkin ? 'background: #222222' : 'background:#f5f5f5'"
              v-model="clientCreditorCheck.dob"
              :disabled="true"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="6" class="mb-1">
          <b-form-group
            label="Address"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.address"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group
      label-cols-lg="2"
      label="CREDITOR ACCOUNT"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
      style="padding: 10px; margin: 10px"
    >
      <b-row>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Name"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.creditor_name"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Account Number"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.creditor_account"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Phone"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.offer_contact_number"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group
      label-cols-lg="2"
      label="GAIN AMG"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
      style="padding: 10px; margin: 10px"
    >
      <b-row>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Profit"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <money
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
                masked: false,
              }"
              disabled="true"
              :style="isDarkSkin ? 'background: #222222' : 'background:#f5f5f5'"
              v-model="clientCreditorCheck.profit"
              class="form-control"
            ></money>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Settlement cost"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <money
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
                masked: false,
              }"
              disabled="true"
              :style="isDarkSkin ? 'background: #222222' : 'background:#f5f5f5'"
              v-model="clientCreditorCheck.settlement_cost"
              class="form-control"
            ></money>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group
      label-cols-lg="2"
      label="PAYMENT DETAILS"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
      style="padding: 10px; margin: 10px"
    >
      <b-row>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Payment Date"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-datepicker
              v-model="clientCreditorCheck.payment_date"
              :disabled="true"
              :style="isDarkSkin ? 'background: #222222' : 'background:#f5f5f5'"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Amount"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <money
              v-bind="{
                decimal: '.',
                thousands: ',',
                prefix: '$ ',
                precision: 2,
                masked: false,
              }"
              disabled="true"
              :style="isDarkSkin ? 'background: #222222' : 'background:#f5f5f5'"
              v-model="clientCreditorCheck.offer_amount"
              class="form-control"
            ></money>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Method of payment"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.payment_method"
              :disabled="true"
              :class="isDarkSkin ? 'text-light' : 'text-dark'"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Type"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.payment_type"
              :disabled="true"
              class="text-dark"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="4" class="mb-1">
          <b-form-group
            label="Shipping Address"
            label-size="sm"
            label-class="font-weight-bold pt-0"
            class="mb-0"
          >
            <b-form-input
              v-model="clientCreditorCheck.mailing_address"
              :disabled="true"
              class="text-dark"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </b-card>
</template>
<script>
export default {
  props: {
    clientCreditorCheck: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style>
.no-shadow {
  box-shadow: none !important;
  background: #f5f5f5;
}
</style>