<template>
  <b-modal
    v-model="isShow"
    ref="modal-view-documents-case"
    :title="onlyShow ? 'Deposit Details' : 'Deposit'"
    title-class="h3 text-white font-weight-bolder"
    size="md"
    modal-class="modal-primary"
    no-close-on-backdrop
    :hide-footer="onlyShow ? true : false"
    @hidden="close"
  >
    <div class="sticky-top">
      <b-row>
        <b-col sm="4">
          <h5>Client</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            {{ client }}
          </p>
        </b-col>
        <b-col sm="4">
          <h5>Payment Account</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            {{ paymentAccount }}
          </p>
        </b-col>
        <b-col sm="4">
          <h5>Amount</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py-1s"
          >
            {{ amount | currencyZero }}
          </p>
        </b-col>
      </b-row>
    </div>
    <validation-observer ref="form">
      <div class="pt-2 mb-2">
        <b-row class="mb-2">
          <b-col cols="4">
            <b-form-group label="Check Number">
              <validation-provider
                v-slot="{ errors }"
                name="check"
                rules="required"
              >
                <b-form-input
                  v-model="transferNum"
                  v-mask="'#########################'"
                  :disabled="onlyShow ? true : false"
                  :class="errors[0] ? 'border-danger' : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Amount">
              <validation-provider
                v-slot="{ errors }"
                name="amount"
                rules="required|greated-than-zero"
              >
                <money
                  v-model="transferAmount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    precision: 2,
                    min: 0.0,
                    maxlength: 11,
                  }"
                  class="form-control"
                  :class="errors[0] ? 'border-danger' : null"
                  :disabled="onlyShow ? true : false"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Date">
              <validation-provider
                v-slot="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="transferDate"
                  placeholder="Enter date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :class="errors[0] ? 'border-danger' : null"
                  :disabled="onlyShow ? true : false"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div>
          <!-- show transfer_updated_by -->
          <b-row v-if="onlyShow">
            <b-col sm="12">
              <h5>Updated By</h5>
              <p
                class="rounded text-primary border-primary font-medium-1 text-center py-1s"
              >
                {{ onlyShow.transfer_updated_by }} |
                {{ onlyShow.transfer_updated_at | myGlobalDay }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="primary" @click="sendRequestDeposit">DONE</b-button>
    </template>
  </b-modal>
</template>
<script>
import SettlementServices from "@/views/debt-solution/views/settlements/services/settlement.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    dsOfferPaymentOrdersId: {
      type: Number,
      default: null,
    },
    client: {
      type: String,
      default: null,
    },
    paymentAccount: {
      type: String,
      default: null,
    },
    amount: {
      type: String,
      default: null,
    },
    onlyShow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isShow: false,
      transferNum: "",
      transferAmount: this.amount,
      transferDate: null,
    };
  },

  async created() {
    this.isShow = true;
  },
  mounted() {
    if (this.onlyShow != null) {
      this.transferNum = this.onlyShow.transfer_number;
      this.transferAmount = this.onlyShow.transfer_amount;
      this.transferDate = this.onlyShow.transfer_date;
    }
  },
  methods: {
    async sendRequestDeposit() {
      const result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      try {
        this.addPreloader();
        const params = {
          id: this.dsOfferPaymentOrdersId,
          transfer_number: this.transferNum,
          transfer_amount: this.transferAmount,
          transfer_date: this.transferDate,
          transfer_update_by: this.currentUser.user_id,
        };
        const data = await SettlementServices.sendRequestDeposit(params);
        if (data.status == 200) {
          this.$emit("refresh");
          this.close();
        }
      } catch (e) {
        if (e.response.status == 400) {
          const data = e.response.data.data;
          this.showWarningSwal(
            e.response.data.message,
            "",
            `
              <div> <b> Client:</b>           ${data[0].client_name} (${data[0].client_account}) </div>
              <div> <b> Actual Creditor:</b>  ${data[0].actual_creditor} </div>
              <div> <b> Amount: </b>           $${data[0].offer_amount} </div>
            `
          );
        } else {
          this.showErrorSwal(e);
        }
      } finally {
        this.removePreloader();
      }
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
};
</script>
<style scoped>
.py-1s {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
